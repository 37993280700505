import axios from '../../../../../redux/apiCaller';
import { successAction, errorAction, attemptAction } from '../../../../../redux/constants';
import StateManager from '../../../../Global/StateManager';
import {
  READUNDERSTOODLIST_ATTEMPT,
  READUNDERSTOODACTION_ATTEMPT,
  READUNDERSTOODCONFIRM_ATTEMPT,
  READUNDERSTOOD_ERROR,
  READUNDERSTOOD_ATTEMPT,
  READUNDERSTOODDOCS_ATTEMPT,
  GETCOMPANYTASKLIST,
  GETCOMPANYTASKLISTTOTALCOUNT,
  GETMYTASKLIST,
  GETMYTASKLISTTOTALCOUNT,
  GETSENTTASKLIST,
  GETSENTTASKLISTTOTALCOUNT,
  GETTASKSBYVIEW,
  GETTASKSBYVIEWTOTALCOUNT,
  SENDREADUNDERSTOOD,
  GETTASKSBYPARAM,
  GETREADUNDERSTOOD,
  GETREADUNDERSTOODDOCS,
  CLOSE_RU_DIALOG,
  READUNDERSTOODACTIONCONFIRMED,
  DELETEREADUNDERSTOOD,
  DELETEREADUNDERSTOODS,
} from '../constants';
import { getDecryptedFile } from './file';

const baseUrl = 'readunderstood';

export const getCompanyTasks = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODLIST_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/list`, { ...data });
      dispatch(successAction(GETCOMPANYTASKLIST, response.data.result));
      dispatch(successAction(GETCOMPANYTASKLISTTOTALCOUNT, response.data.totalCount));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));

      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getMyTasks = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODLIST_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/myTasks`, { ...data });
      dispatch(successAction(GETMYTASKLIST, response.data.result));
      dispatch(successAction(GETMYTASKLISTTOTALCOUNT, response.data.totalCount));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getSentTasks = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODLIST_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/assigned`, { ...data });
      dispatch(successAction(GETSENTTASKLIST, response.data.result));
      dispatch(successAction(GETSENTTASKLISTTOTALCOUNT, response.data.totalCount));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getTasksByView = (viewId, data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODLIST_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/views/${viewId}`, { ...data });
      dispatch(successAction(GETTASKSBYVIEW, response.data.result));
      dispatch(successAction(GETTASKSBYVIEWTOTALCOUNT, response.data.totalCount));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getTasksByParam = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODLIST_ATTEMPT));
    try {
      const response = await axios.get(`${baseUrl}`, { params });
      dispatch(successAction(GETTASKSBYPARAM, response.data.result));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const createReadUnderstood = (users, document, dueDate, groups, onClose) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODACTION_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}`, { users, document, dueDate, groups });
      if (response.data && response.status === 200) {
        StateManager.setSuccessAlert('Read and Understood Tasks Created successfully');
      }
      dispatch(successAction(SENDREADUNDERSTOOD, response.data));
      if (onClose) {
        onClose();
      }
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const createReadUnderstoodByDirectory = (users, area, folder, dueDate, groups, onClose) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODACTION_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/directory`, { users, area, folder, dueDate, groups });
      if (response.status === 200) {
        StateManager.setSuccessAlert(response.data?.message || 'Read and Understood Tasks Created successfully');
      }
      dispatch(successAction(SENDREADUNDERSTOOD, response.data));
      if (onClose) {
        onClose();
      }
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const confirmReadUnderstood = (id, data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODCONFIRM_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/${id}/confirm`, data);
      if (response.data && response.status === 200) {
        StateManager.setSuccessAlert('Read and Understood Tasks Completed successfully');
      }
      dispatch(successAction(READUNDERSTOODACTIONCONFIRMED, response.data.result));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error.response.data));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getReadUnderstood = (id, type = 'task') => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOOD_ATTEMPT));
    try {
      const response =
        type === 'task' ? await axios.get(`${baseUrl}/task/${id}`) : await axios.get(`${baseUrl}/id/${id}`);
      let data = response.data;
      if (data?.result?.document?.category === 'normal') {
        const url = await getDecryptedFile(data?.result?.document);
        data.result = {
          ...data.result,
          documentUrl: url,
        };
      }
      dispatch(successAction(GETREADUNDERSTOOD, data));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getReadUnderstoodDocuments = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODDOCS_ATTEMPT));
    try {
      const response = await axios.get(`${baseUrl}/documents`);
      dispatch(successAction(GETREADUNDERSTOODDOCS, response.data.result));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const updateReadUnderstoodTask = (id, taskData) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODACTION_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/task/${id}`, taskData);
      let data = response.data;
      if (data?.result?.document?.category === 'normal') {
        const url = await getDecryptedFile(data?.result?.document);
        data.result = {
          ...data.result,
          documentUrl: url,
        };
      }
      dispatch(successAction(GETREADUNDERSTOOD, data));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const deleteReadUnderstoodTask = (id, taskData) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODACTION_ATTEMPT));
    try {
      const response = await axios.put(`${baseUrl}/task/${id}`, taskData);
      let data = response.data;
      if (data?.result?.document?.category === 'normal') {
        const url = await getDecryptedFile(data?.result?.document);
        data.result = {
          ...data.result,
          documentUrl: url,
        };
      }
      dispatch(successAction(GETREADUNDERSTOOD, data));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const deleteReadUnderstood = (id) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODACTION_ATTEMPT));
    try {
      const response = await axios.delete(`${baseUrl}/${id}`);
      dispatch(successAction(DELETEREADUNDERSTOOD, response.data.result));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const deleteReadUnderstoods = (ids) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(READUNDERSTOODACTION_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}/bulkDelete`, { ids });
      dispatch(successAction(DELETEREADUNDERSTOODS, response.data.result));
    } catch (error) {
      dispatch(errorAction(READUNDERSTOOD_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const closeRuDialog = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(CLOSE_RU_DIALOG, null));
  };
  return thunk;
};
