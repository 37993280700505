import { useLocation } from 'react-router-dom';
import logo from '../Images/logos/main-logo.png';
import darklogo from '../Images/logos/main-logo-dark.png';
import { lightGreen, orange, blue, teal, deepPurple, red } from '@mui/material/colors';

const QLogo = '/letter-logo.png';

const nodeEnv = process.env.NODE_ENV;
const reactEnv = process.env.REACT_APP_ENV;

const rollBarToken = process.env.REACT_APP_ROLLBAR_TOKEN;

const isDev = nodeEnv === 'development';
const isStaging = nodeEnv === 'production' && reactEnv === 'staging';
const isProduction = nodeEnv === 'production' && reactEnv === 'production';

export const rollBarEnv = process.env.REACT_APP_ENV;

export const FILE_PROCESSING_URL = process.env.REACT_APP_FILEPROCESSING_URL;

export const ENV = nodeEnv === 'production' ? (reactEnv === 'staging' ? 'STAGING' : 'PRODUCTION') : 'DEVELOPMENT';
export const WEB_PUSH_URL = process.env.REACT_APP_WEBPUSH_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

var socket_url = process.env.REACT_APP_BASE_WS_URL || `https://${window.location.host}`;

if (!process.env.REACT_APP_BASE_WS_URL) {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'staging') socket_url = `${BASE_URL}`;
  if (process.env.NODE_ENV === 'development') socket_url = `http://${location.hostname}:5000`;
}

export const WS_BASE_URL = socket_url;

export const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;
export const CLIENTID = process.env.REACT_APP_ADOBE_CLIENT_ID;
export const calendlyUrl = 'https://calendly.com/qhub/meeting';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const paidRoles = ['admin', 'standard'];

export const getGroupLink = (linkId, type, full = true) => {
  if (!full) return `/${type}/${linkId}`;
  return `${isProduction || isStaging ? 'https://' : ''}${window.location.host}/${type}/${linkId}`;
};

const QDOCSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2em" viewBox="0 0 150 150" height="2em" {...props}>
    <path
      d="M74.576 118.526c-14.781 0-27.29-4.988-37.516-14.968-10.23-9.989-15.344-22.614-15.344-37.875 0-15.27 5.114-27.868 15.344-37.797C47.287 17.948 59.814 12.98 74.638 12.98c14.832 0 27.313 4.968 37.438 14.906 10.125 9.93 15.187 22.527 15.187 37.797 0 8.605-1.742 16.507-5.218 23.703-3.47 7.187-8.235 13.183-14.297 17.984l20.984 27H98.357l-12.484-17.015c-3.918.78-7.684 1.171-11.297 1.171ZM54.67 44.105c-4.938 5.375-7.407 12.57-7.407 21.578 0 9 2.47 16.195 7.407 21.578 4.945 5.375 11.554 8.062 19.828 8.062 8.27 0 14.875-2.687 19.812-8.062 4.946-5.383 7.422-12.578 7.422-21.578 0-9.008-2.476-16.203-7.422-21.578-4.937-5.383-11.543-8.079-19.812-8.079-8.274 0-14.883 2.696-19.828 8.079Zm0 0"
      fill="currentColor"
    />
  </svg>
);

const HUB_COLORS = {
  process: orange[600],
  form: lightGreen[600],
  doc: blue[500],
  audit: blue[500],
  asset: blue[500],
  register: teal[600],
  work: deepPurple[600],
  kpi: red[700],
};

export { QLogo, QDOCSvg, isDev, isStaging, isProduction, logo, darklogo, rollBarToken, HUB_COLORS };
