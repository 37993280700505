import React, { useRef } from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { COLORS } from '../../constants';

const SelectBox = ({
  options,
  value,
  handleValue,
  minWidth = 200,
  sendE = false,
  border = false,
  small = false,
  borderRadius = 12,
  noValue = null,
  onExit,
  activeColor = COLORS.SEARCH,
  ...props
}) => {
  const ref = useRef();
  const handleChange = (event) => {
    event.stopPropagation();
    if (sendE) {
      handleValue(event);
    } else {
      handleValue(event.target.value);
    }
  };

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={`${props.className}`}
        sx={{
          color: COLORS.SEARCH,
          right: 12,
          position: 'absolute',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      />
    );
  };

  const menuProps = {
    sx: {
      '& .MuiPaper-root': {
        borderRadius: 2,
        marginTop: 1,
        '& .MuiList-root': {
          py: 0,
          background: (t) => t.palette.background.default,
          maxHeight: 40 * 4.5,
          '& li': {
            fontWeight: 200,
            py: '12px',
          },
          '& li:hover': {
            background: COLORS.SETTINGS,
            color: 'white',
          },
          '& li.Mui-selected': {
            color: 'white',
            background: COLORS.SEARCH,
          },
          '& li.Mui-selected:hover': {
            background: COLORS.SEARCH,
          },
        },
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    TransitionProps: {
      onExited: onExit,
    },
    disableAutoFocus: true,
    disableEnforceFocus: true,
    disableRestoreFocus: true,
    disableAutoFocusItem: true,
    autoFocus: false,
  };

  return (
    <FormControl>
      <Select
        sx={{
          minWidth,
          background: (t) => t.palette.background.default,
          color: (t) => t.palette.getContrastText(t.palette.background.default),
          fontWeight: 200,
          borderRadius: `${borderRadius}px`,
          '& .MuiSelect-select': {
            padding: small ? '7px 0px 7px 12px' : '15px 0px 15px 24px',
          },
          padding: small ? '0px 0px 0px 6px' : '15px 0px 15px 24px',
          '&:hover': {
            borderRadius: `${borderRadius}px`,
            borderColor: COLORS.SEARCH,
            background: (t) => t.palette.background.default,
          },
          '&:focus': {
            borderRadius: `${borderRadius}px`,
            borderColor: COLORS.SEARCH,
            background: (t) => t.palette.background.default,
          },
        }}
        MenuProps={menuProps}
        IconComponent={iconComponent}
        value={value}
        onChange={handleChange}
        ref={ref}
        displayEmpty={noValue}
        {...props}
      >
        {options.map((o, l) => (
          <MenuItem value={o.value} key={l}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
