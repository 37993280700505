import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import ContentEditor from '../ContentEditor';
import { DoneRounded } from '@mui/icons-material';
import { isFunction, isString, isEmpty } from 'lodash';
import { v4 } from 'uuid';
import { grey } from '@mui/material/colors';
import { ContainsHTMLTags } from '../Functions';

export default function RichTextField({
  initial,
  editable,
  onChange,
  placeholder,
  disableAutoSave,
  hideButton,
  editOpen,
  savingTimeout = 1200,
  minHeight = 250,
  onDone,
  fontSize,
  disableImages,
  border = true,
  ...props
}) {
  const timer = useRef(null);
  const [edit, setEdit] = useState(editable ?? false);
  const [value, setValue] = useState('');
  const [changesSent, setChangesSent] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [id] = useState(v4());
  const [prettifiedContent, setPrettifiedContent] = useState('');
  const TINY_HEADER_HEIGHT = 40;

  function handleChange(val) {
    setValue(val);
    if (disableAutoSave) return;

    if (timer.current != null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setChangesSent(true);
      onChange(val);
    }, savingTimeout);
  }

  function handleFieldClick() {
    if (!editable || edit) return;
    setEdit(true);
  }

  function done() {
    setEdit(false);
    if (disableAutoSave) {
      onChange(value);
    }

    isFunction(onDone) && onDone();
  }

  useEffect(() => {
    if (changesSent) return;

    // if initial value is simple string - pransform to html
    // otherwise line break won't render
    if (isString(initial) && !ContainsHTMLTags(initial)) {
      const replaced = stringToHtml(initial);
      setValue(replaced);
    } else {
      setValue(initial);
    }
  }, [initial, changesSent]);

  function stringToHtml(str) {
    if (!isString(str) || isEmpty(str)) return str;
    const splitted = str.split('\n');
    const result = splitted.map((x) => `<p>${x}</p>`).join('\n');
    return result;
  }

  function prettifyImages(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgs = doc.getElementsByTagName('img');

    for (let i = 0; i < imgs.length; i++) {
      imgs[i].removeAttribute('width');
      imgs[i].removeAttribute('height');
      imgs[i].style.maxWidth = '100%';
    }

    return doc.documentElement.outerHTML;
  }

  useEffect(() => {
    if (!value || editOpen || (edit && editable)) return;
    setPrettifiedContent(prettifyImages(value));
  }, [value, editOpen, edit, editable]);

  const toolbarConfig = disableImages
    ? 'fontsizeselect forecolor backcolor bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist'
    : 'image | fontsizeselect forecolor backcolor bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist';

  return (edit && editable) || editOpen ? (
    <Grid item container sx={{ py: 1 }} {...props}>
      <Grid container item>
        <ContentEditor
          config={{
            width: '100%',
            height: minHeight + TINY_HEADER_HEIGHT,
            min_height: minHeight + TINY_HEADER_HEIGHT,
            resize: true,
            toolbar: toolbarConfig,
            statusbar: false,
          }}
          handleEditorChange={(val) => handleChange(val)}
          value={value}
          id={`rich-text-field-${id}`}
        />
      </Grid>

      {!hideButton && (
        <Grid container item sx={{ my: 1 }} justifyContent="flex-end">
          <Button
            startIcon={<DoneRounded />}
            variant="contained"
            style={{ borderRadius: 8 }}
            color="primary"
            onClick={done}
          >
            done
          </Button>
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid container alignItems="baseline" sx={{ my: 1 }} {...props}>
      <Grid
        container
        sx={{
          p: border ? 1.5 : 0,
          borderRadius: 1,
          border: (t) =>
            border ? (t.palette.mode === 'dark' ? `0.5px solid ${grey[300]}` : `1px solid ${grey[300]}`) : 'none',
          cursor: editable && hovered ? 'text' : 'default',
          width: '100%',
        }}
        style={{ minHeight }}
        onClick={handleFieldClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {value && (
          <Grid
            sx={{
              '& > *': {
                height: 'auto !important',
                width: 'auto !important',
                maxWidth: '100% !important',
                fontFamily: 'Roboto, sans-serif',
                fontSize: fontSize ?? '13pt',
                margin: 0,
              },
            }}
            dangerouslySetInnerHTML={{ __html: prettifiedContent }}
          />
        )}
        {!value && placeholder && (
          <Typography variant="h6" color="textSecondary">
            {placeholder}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
