import React, { useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { IconButton, Typography, Divider, Box, Collapse } from '@mui/material';
import RedoIcon from '@mui/icons-material/Redo';
import { TreeView } from '@mui/x-tree-view';

import StyledTreeItem from '../../../StyledTreeItem';

import { TOC_TYPES } from '..';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';

export default function TOC({ blocks, enableNumber = true, onSearch = null }) {
  const [collapseToc, setCollapseToc] = useState(true);

  const createIndexer = (values) => {
    const combinedValues = Array.isArray(values) ? values : [values];
    let filtered = combinedValues.filter((a) => a !== null && a !== undefined && a !== '');
    if (filtered.length > 1) {
      return filtered.join('.');
    } else {
      return filtered;
    }
  };

  const getLastHeading = (lastHeading, level, index) => {
    const lastElement = lastHeading[lastHeading?.length - 1];
    const findSameLevel = lastHeading.findIndex((x) => x.heading === level);
    const findLowest = lastHeading.filter((x) => x.heading < level);
    // check if a bigger is present;
    if (findLowest?.length > 0 && findSameLevel === -1) {
      if (lastElement?.heading > level) {
        lastHeading[lastHeading?.length - 1] = {
          heading: level,
          index: lastHeading[lastHeading?.length - 1]?.index + 1,
        };
        return lastHeading;
      }
      lastHeading = [...findLowest, { heading: level, index: index + 1 }];
      return lastHeading;
    }

    if (findSameLevel !== -1) {
      lastHeading[findSameLevel] = { heading: level, index: lastHeading[findSameLevel]?.index + 1 };
      lastHeading = lastHeading.filter((x) => x.heading <= level);
      return lastHeading;
    }

    lastHeading = [...lastHeading, { heading: level, index: index + 1 }];
    return lastHeading;
  };

  const headings = useMemo(() => {
    const headings = blocks?.filter((x) => TOC_TYPES.includes(x.type) && x.text !== '');
    if (headings?.length === 0) return null;
    let lastHeading = [];
    const newHeadings = headings?.map((b) => {
      if (b.type === 'header-one') {
        let headersOne = headings?.filter((b) => b.type === 'header-one');
        let headingOneIndex = headersOne.findIndex((x) => x.key === b.key);
        lastHeading = [{ heading: 1, index: headingOneIndex + 1 }];
      }
      if (b.type === 'header-two') {
        let headersTwo = headings?.filter((b) => b.type === 'header-two');
        let headingTwoIndex = headersTwo.findIndex((x) => x.key === b.key);
        if (lastHeading?.findIndex((x) => x.heading === 2) === -1) {
          headingTwoIndex = 0;
        }
        if (lastHeading?.length === 0) {
          lastHeading = [{ heading: 2, index: headingTwoIndex + 1 }];
        } else {
          lastHeading = getLastHeading(lastHeading, 2, headingTwoIndex);
        }
      }
      if (b.type === 'header-three') {
        let headersThree = headings?.filter((b) => b.type === 'header-three');
        let headingThreeIndex = headersThree.findIndex((x) => x.key === b.key);
        if (lastHeading?.findIndex((x) => x.heading === 3) === -1) {
          headingThreeIndex = 0;
        }
        if (lastHeading?.length === 0) {
          lastHeading = [{ heading: 3, index: headingThreeIndex + 1 }];
        } else {
          lastHeading = getLastHeading(lastHeading, 3, headingThreeIndex);
        }
      }
      if (b.type === 'header-four') {
        let headersFour = headings?.filter((b) => b.type === 'header-four');
        let headingFourIndex = headersFour.findIndex((x) => x.key === b.key);
        if (lastHeading?.findIndex((x) => x.heading === 4) === -1) {
          headingFourIndex = 0;
        }
        if (lastHeading?.length === 0) {
          lastHeading = [{ heading: 4, index: headingFourIndex + 1 }];
        } else {
          lastHeading = getLastHeading(lastHeading, 4, headingFourIndex);
        }
      }
      if (b.type === 'header-five') {
        let headersFive = headings?.filter((b) => b.type === 'header-five');
        let headingFiveIndex = headersFive.findIndex((x) => x.key === b.key);
        if (lastHeading?.findIndex((x) => x.heading === 5) === -1) {
          headingFiveIndex = 0;
        }
        if (lastHeading?.length === 0) {
          lastHeading = [{ heading: 5, index: headingFiveIndex + 1 }];
        } else {
          lastHeading = getLastHeading(lastHeading, 5, headingFiveIndex);
        }
      }
      if (b.type === 'header-six') {
        let headersSix = headings?.filter((b) => b.type === 'header-six');
        let headingSixIndex = headersSix.findIndex((x) => x.key === b.key);
        if (lastHeading?.findIndex((x) => x.heading === 6) === -1) {
          headingSixIndex = 0;
        }
        if (lastHeading?.length === 0) {
          lastHeading = [{ heading: 6, index: headingSixIndex + 1 }];
        } else {
          lastHeading = getLastHeading(lastHeading, 6, headingSixIndex);
        }
      }
      return { index: createIndexer(lastHeading?.map((x) => x.index)), text: b.text, type: b.type, key: b.key };
    });
    return newHeadings;
  }, [blocks]);

  if (headings?.length === 0) return null;

  return (
    <Grid item sm={12}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton size="small" onClick={() => setCollapseToc(!collapseToc)}>
          {collapseToc ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
        <Typography
          sx={{
            fontSize: 18,
            color: (t) => (t.palette.mode === 'dark' ? 'white' : 'black'),
            alignItems: 'center',
            marginLeft: '5px',
            fontWeight: 500,
          }}
          onClick={() => setCollapseToc(!collapseToc)}
        >
          Table of Contents
        </Typography>
      </Box>
      <Collapse in={collapseToc}>
        {headings?.map((x, i) => (
          <TreeView sx={{ flexGrow: 1, maxWidth: '100%', overflowY: 'scroll' }} key={i}>
            <StyledTreeItem
              key={x.key}
              nodeId={x.key}
              labelText={enableNumber ? `${x.index} ${x.text}` : x.text}
              labelIcon={RedoIcon}
              indent={true}
              indentLevel={x.index?.length}
              labelPosition="right"
              onClick={() => {
                if (onSearch) {
                  onSearch(x.text, x.key);
                }
              }}
            />
          </TreeView>
        ))}
      </Collapse>
      <Divider />
    </Grid>
  );
}
