import React, { useEffect, useState } from 'react';
import { upload_handler, searchDocs } from './functions';

import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/save';
import 'tinymce/plugins/autosave';

import './skins/content/CHUMCHUM/content.min.css';

import { Helmet } from 'react-helmet';

import { Editor } from '@tinymce/tinymce-react';
import { useTheme } from '@mui/material';

const initialConfig = {
  height: 270,
  width: 900,
  min_width: 900,
  max_width: 900,
  min_height: 270,
  max_height: 700,
  toolbar:
    'image undo redo | forecolor backcolor bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
  toolbar_mode: 'sliding',
};

const API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;
const IMAGE_CORS_HOSTS = [
  'app.q-hub.co.uk',
  'dev.q-hub.co.uk',
  's3.eu-west-2.amazonaws.com',
  'd3onu7yzki8jx.amplifyapp.com',
];
export default function ContentEditor({ handleEditorChange, id, config = initialConfig, value, restoreEmpty = true }) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isDark = themeMode === 'dark' ?? localStorage.getItem('themeMode') === 'dark';

  const textColor = isDark ? '#fff' : '#353940';

  const [skin, setSkin] = useState('CHUMCHUM');
  const [contentCss, setContentCss] = useState('CHUMCHUM');

  const [editorHeight, setEditorHeight] = useState(config.height ?? 270);

  useEffect(() => {
    if (themeMode === 'dark') {
      setSkin('dark-skin');
      setContentCss('dark-skin');
    } else {
      setSkin('CHUMCHUM');
      setContentCss('CHUMCHUM');
    }
  }, [themeMode]);

  const stylesheets = [`/css/tinymce/ui/${skin}/content.min.css`, `/css/tinymce/ui/${skin}/skin.min.css`];

  // Effect to handle initial sizing and resize events
  useEffect(() => {
    setEditorHeight(config.height ?? 270);
  }, [config]);

  if (config.height !== editorHeight) {
    return <div style={{ height: editorHeight, width: '100%' }}>Loading Editor...</div>;
  }

  const handleEditorInit = (editor) => {
    if (!editor) return;
    editor.ui.registry.addAutocompleter('docLinkMention', {
      ch: '#',
      minChars: 1,
      columns: 1,
      fetch: async (pattern) => {
        const items = await searchDocs(pattern);
        return items;
      },
      onAction: (autocompleteApi, rng, value) => {
        editor.selection.setRng(rng);
        const result = JSON.parse(value);
        editor.insertContent(`<a href="${result?.url}" target="_blank" class="doc-link">${result?.text}</a>`);
        autocompleteApi.hide();
      },
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        {stylesheets?.map((href, i) => (
          <link key={i} rel="stylesheet" href={href} />
        ))}
      </Helmet>
      <Editor
        apiKey={API_KEY}
        init={{
          selector: `textarea#builder${id}`,
          skin: skin,
          content_css: contentCss,
          images_upload_handler: upload_handler,
          branding: false,
          statusbar: true,
          menubar: false,
          toolbar_sticky: true,
          resize: 'both',
          autoresize: true,
          autoresize_overflow_padding: 1,
          object_resizing: true,
          plugins:
            'autoresize paste importcss searchreplace autolink image link table imagetools lists advlist save autocompleter',
          //autosave_prefix: `tinymce-autosave-${id}`,
          //autosave_restore_when_empty: restoreEmpty,
          importcss_append: true,
          imagetools_cors_hosts: IMAGE_CORS_HOSTS,
          imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
          image_advtab: true,
          image_caption: true,
          resize_img_proportional: true,
          contextmenu: 'link image imagetools table',
          content_style: `body { font-family:Roboto, sans-serif; font-size:10pt; color:${textColor}; } p { font-family:Roboto, sans-serif; font-size: 10pt; color: ${textColor} !important; }`,
          setup: (editor) => {
            editor.on('init', () => handleEditorInit(editor));
          },
          ...config,
        }}
        value={value}
        onEditorChange={handleEditorChange}
        onInit={(e, editor) => {
          setEditorHeight(config.height);
          editor.selection.select(editor.getBody(), true);
          editor.selection.collapse(false);
          editor.focus();
          handleEditorInit(editor);
        }}
      />
    </React.Fragment>
  );
}
