import {
  DataGridPremium,
  useGridApiContext,
  useGridSelector,
  gridFilteredSortedRowIdsSelector,
  GRID_CHECKBOX_SELECTION_FIELD,
} from '@mui/x-data-grid-premium';
import { Grid, IconButton, Box, Button, Typography, DialogTitle, DialogContent } from '@mui/material';
import { CircularProgress, Menu, ListItemIcon, MenuItem, ListItemText } from '@mui/material';
import { red, grey } from '@mui/material/colors';
import { DeleteOutlineRounded, FileDownloadRounded, FileUploadRounded, MoreVert } from '@mui/icons-material';
import { WorkOutline, LinkRounded } from '@mui/icons-material';
import { useState, useEffect, useMemo, useRef } from 'react';
import { isArray, isFunction, isEmpty, intersectionBy, differenceBy, isNumber } from 'lodash';
import StateManager from '../StateManager';
import { formatBytes, MimetypeToType } from '../Functions';
import { StandardDialogActions, RoundedDialog, ImageDialog } from './';
import { DocIcon, FormIcon, AuditIcon, AssetIcon, ProcessIcon, PortalIcon } from '../Icons';
import Uploader from '../Uploader';
import { useDispatch } from 'react-redux';
import { previewFile } from '../../Hubs/dochub/redux/actions/file';

function getLinkIcon(hub) {
  if (hub === 'doc') return <DocIcon />;
  if (hub === 'auditTemplate') return <WorkOutline style={{ color: '#00bfa5' }} />;
  if (hub === 'audit' || hub === 'audits/schedule') return <AuditIcon />;
  if (hub === 'form') return <FormIcon />;
  if (hub === 'asset' || hub === 'asset/profile') return <AssetIcon />;
  if (hub === 'process') return <ProcessIcon />;
  if (hub === 'portal/management/space') return <PortalIcon />;
  if (hub === 'docFolder' || hub === 'DocLibrary/area') return <DataIcon />;
  if (hub === 'register') return <RegistersIcon />;
  if (hub === 'tasks/workspace') return <TaskIcon />;
  return <LinkRounded />;
}

export default function FilesTable({
  files,
  editable,
  onFilesUploaded,
  onFilesDeleted,
  onFilesChange,
  saving,
  noRowsOverlayContent,
  additionalColumns,
  restrictHeight,
}) {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filesDialog, setFilesDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();

  // to use in callbacks
  const rowsRef = useRef();
  rowsRef.current = rows;

  useEffect(() => {
    setRows(isArray(files) ? files : []);
  }, [files]);

  function confirmFileDelete(file) {
    StateManager.setConfirm(`You are about to delete file \n${file.originalname}`, () => deleteFilesByIds([file.id]));
  }

  function deleteFilesByIds(ids) {
    const remaining = rowsRef.current.filter((x) => !ids.includes(x.id));
    setRows(remaining);

    if (!isArray(files)) return;

    if (isFunction(onFilesDeleted)) {
      onFilesDeleted(ids);
    }

    if (isFunction(onFilesChange)) {
      onFilesChange(remaining);
    }
  }

  function deleteSelected() {
    const titles = rows.filter((x) => selectedRows.includes(x.id)).map((x) => x.originalname);
    StateManager.setConfirm(`You are about to delete the below files: \n${titles.join('\n')}`, () =>
      deleteFilesByIds(selectedRows),
    );
  }

  function uploadFiles(toUpload) {
    if (!isArray(toUpload) || isEmpty(toUpload)) return;

    // filter out duplicates
    const filtered = differenceBy(toUpload, rows, 'id');

    if (filtered.length < toUpload.length) {
      const duplicates = intersectionBy(toUpload, rows, 'id');
      const message =
        duplicates.length === 1
          ? `File '${duplicates[0].originalname}' has already been uploaded`
          : `${duplicates.length} files have already been uploaded`;

      StateManager.setWarningAlert(message);
    }

    if (isEmpty(filtered)) return;

    const updatedRows = [...rows, ...filtered];

    if (isFunction(onFilesUploaded)) {
      onFilesUploaded(filtered);
    }

    if (isFunction(onFilesChange)) {
      onFilesChange(updatedRows);
    }
  }

  const columns = useMemo(() => {
    const providedColumns =
      isArray(additionalColumns) && additionalColumns.every((x) => x.field) ? additionalColumns : [];

    const result = [
      {
        width: 250,
        headerName: 'Name',
        field: 'originalname',
        display: 'flex',
        renderCell: ({ row }) => {
          const imagePreview = row.mimetype?.startsWith('image/') ? (
            <Box mr={1}>
              <img
                key={row.id}
                style={{
                  maxWidth: 60,
                  maxHeight: 40,
                  userSelect: 'none',
                  pointerEvents: 'none',
                }}
                src={row.location}
                alt={row.originalname}
              />
            </Box>
          ) : null;

          return (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
              {imagePreview}
              {row.originalname}
            </Box>
          );
        },
      },
      {
        width: 80,
        headerName: 'Size',
        field: 'size',
        type: 'number',
        display: 'flex',
        renderCell: ({ row }) => (!row.link && isNumber(row.size) ? formatBytes(row.size) : '-'),
        filterable: false,
        align: 'left',
        headerAlign: 'left',
      },
      {
        width: 80,
        headerName: 'Type',
        field: 'mimetype',
        display: 'flex',
        valueGetter: (value, row) => (row.hub ? row.hub : MimetypeToType(value)),
        renderCell: ({ row, value }) => (row.hub ? getLinkIcon(row.hub) : value),
      },
      ...providedColumns,
      {
        field: 'actions',
        type: 'actions',
        resizable: false,
        width: 54,
        display: 'flex',
        renderCell: ({ row }) => <ActionsCell file={row} editable={editable} onDelete={confirmFileDelete} />,
      },
    ];

    return result;
  }, [editable, additionalColumns]); // eslint-disable-line

  return (
    <Grid container sx={{ minHeight: 300, maxHeight: restrictHeight ? '100%' : undefined }}>
      <DataGridPremium
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        checkboxSelection={Boolean(editable)}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={setSelectedRows}
        rows={rows}
        columns={columns}
        slots={{
          footer: CustomFooter,
          noRowsOverlay: noRowsOverlayContent
            ? () => (
                <Grid container item justifyContent="center" alignContent={'center'} style={{ height: '100%' }}>
                  {noRowsOverlayContent}
                </Grid>
              )
            : undefined,
        }}
        slotProps={{
          footer: {
            editable,
            selectedRows,
            onSelectedDelete: deleteSelected,
            onUpload: () => setFilesDialog(true),
            saving,
          },
        }}
        pinnedColumns={{ right: ['actions'], left: [GRID_CHECKBOX_SELECTION_FIELD] }}
        onRowClick={({ row }) => {
          if (row.link) {
            window.open(`${window.location.origin}${row.link}`, '_blank');
          } else if (String(row.mimetype).startsWith('image')) {
            setSelectedFile(row);
            setImageDialog(true);
          } else {
            dispatch(previewFile({ category: 'normal', file: row, title: row.originalname }));
          }
        }}
      />

      {filesDialog && <FilesDialog open={filesDialog} onClose={() => setFilesDialog(false)} onResult={uploadFiles} />}

      <ImageDialog open={imageDialog} onClose={() => setImageDialog(false)} src={selectedFile?.location} />
    </Grid>
  );
}

function CustomFooter({ editable, selectedRows, onSelectedDelete, onUpload, saving }) {
  const apiRef = useGridApiContext();

  // need to pass them on download as well
  const filteredRowIds = useGridSelector(apiRef, gridFilteredSortedRowIdsSelector);

  return (
    <Grid
      container
      alignItems={'center'}
      sx={{ borderTop: (theme) => (theme.palette.mode === 'light' ? `1px solid ${grey[300]}` : '') }}
    >
      {saving ? (
        <Box sx={{ p: 1, ml: 'auto' }}>
          <CircularProgress size={36} />
        </Box>
      ) : (
        <>
          {editable && (
            <Box sx={{ p: 1 }}>
              <Button variant="outlined" onClick={onUpload} startIcon={<FileUploadRounded />}>
                upload files
              </Button>
            </Box>
          )}

          {isEmpty(selectedRows) ? (
            <Box sx={{ p: 1, ml: 'auto' }}>
              <Typography sx={{}}>Total files: {filteredRowIds.length}</Typography>
            </Box>
          ) : (
            <Box sx={{ p: 1, ml: 'auto' }}>
              <Button sx={{ color: red[500] }} startIcon={<DeleteOutlineRounded />} onClick={onSelectedDelete}>
                Delete {selectedRows.length} file{selectedRows.length === 1 ? '' : 's'}
              </Button>
            </Box>
          )}
        </>
      )}
    </Grid>
  );
}

function FilesDialog({ open, onClose, onResult }) {
  const [files, setFiles] = useState([]);
  const [filesInProgress, setFilesInProgress] = useState([]);
  const filesInProgressRef = useRef();
  filesInProgressRef.current = filesInProgress;

  useEffect(() => {
    setFiles([]);
  }, [open]);

  function done() {
    if (filesInProgress.length > 0) {
      StateManager.setConfirm(
        `${filesInProgress.length} file${filesInProgress.length === 1 ? ' has' : 's have'} not finished uploading`,
        () => {
          onResult(files);
          onClose();
        },
        `Are you sure you want to cancel their upload?`,
      );
      return;
    }
    onResult(files);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Upload files {filesInProgress.length > 0 ? `(${filesInProgress.length} uploading)` : ''}
      </DialogTitle>

      <DialogContent>
        <Grid container item>
          <Uploader
            uploaded={files}
            onChange={(res) => {
              setFiles(res);
              const resIds = res.map((x) => x.id);
              setFilesInProgress(filesInProgress.filter((id) => !resIds.includes(id)));
            }}
            withLinkedFeatures
            onStart={({ id }) => {
              filesInProgressRef.current.push(id);
              setFilesInProgress([...filesInProgressRef.current]);
            }}
          />
        </Grid>
      </DialogContent>

      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}

function ActionsCell({ file, onDelete, editable }) {
  const [menu, setMenu] = useState(null);

  if (!editable && file.link) return null;

  return (
    <Grid container wrap="nowrap">
      <IconButton onClick={(event) => setMenu(event.currentTarget)}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={menu} keepMounted open={Boolean(menu)} onClose={() => setMenu(null)}>
        {editable && (
          <MenuItem
            onClick={() => {
              onDelete(file);
              setMenu(null);
            }}
          >
            <ListItemIcon>
              <DeleteOutlineRounded sx={{ color: red[500] }} />
            </ListItemIcon>
            <ListItemText primary={'Delete'} />
          </MenuItem>
        )}

        {!file.link && (
          <MenuItem
            onClick={() => {
              window.open(file.location, '_blank');
              setMenu(null);
            }}
          >
            <ListItemIcon>
              <FileDownloadRounded sx={{ color: grey[500] }} />
            </ListItemIcon>
            <ListItemText primary={'Download'} />
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
}
