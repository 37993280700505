import React, { useMemo } from 'react';
import { Typography, Divider, Box, Drawer, List, ListItemButton, ListItemText } from '@mui/material';

import { formatTOCTitles } from './function';

export function TOC({ sections, enableNumber = true, onScroll }) {
  const headings = useMemo(() => formatTOCTitles(sections, enableNumber), [sections, enableNumber]);

  if (headings?.length === 0) return null;

  return (
    <Box sx={{ width: '100%', py: 2 }}>
      <Typography variant="h6" gutterBottom>
        Table of Contents
      </Typography>
      <Divider />
      <List>
        {headings?.map((item) => (
          <ListItemButton
            key={item.key}
            onClick={() => onScroll && onScroll(item.id)}
            sx={{
              pl: `${item.index.split('.').length * 10}px`, // Indentation based on level
            }}
          >
            <ListItemText primary={item.displayText} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
export default function TOCDrawer({ sections, enableNumber = true, onScroll, drawerOpen, setDrawerOpen }) {
  const headings = useMemo(() => formatTOCTitles(sections, enableNumber), [sections, enableNumber]);

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box sx={{ width: 500, padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Table of Contents
        </Typography>
        <Divider />
        <List>
          {headings?.map((item) => (
            <ListItemButton
              key={item.key}
              onClick={() => onScroll && onScroll(item.id)}
              sx={{
                pl: `${item.index.split('.').length * 10}px`, // Indentation based on level
              }}
            >
              <ListItemText primary={item.displayText} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
