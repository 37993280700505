import { useEffect, useRef, useState } from 'react';
import { WS_BASE_URL } from '../../constants';
import { v4 } from 'uuid';
import axios from 'axios';
import UserMap from './UserMap';

const NEW_CHAT_MESSAGE_EVENT = 'newChatMessage';
const RECEIPT_EVENT = 'receipt';
const UPDATE_EVENT = 'chatUpdate';
const PARTIAL_MESSAGES_LIFESPAN = 10 * 1000;
const PAGE_SIZE = 20;

const token = localStorage.getItem('token');
const userId = localStorage.getItem('_id');

// ============================================================================================
//  ==== Chat manager. Manages sending, reiceving, loading and deleting chats and messages ====
// ============================================================================================
const useChat = (chatId, open) => {
  // for rendering
  const [messages, setMessages] = useState([]); // current chat
  const [chats, setChats] = useState([]); // chat headers
  const [partial, setPartial] = useState({}); // last partial message
  const [loading, setLoading] = useState(true); // loading all chats
  const [loadingChat, setLoadingChat] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const loadChats = !initialised && open;

  // To store data between rerenders
  const socket = useRef(); // WS connection
  const currChatId = useRef(chatId); // current chat
  const currentChats = useRef([]); // copy of 'chats' from state
  const currentMessages = useRef([]); // copy of 'messages' from state
  const currPage = useRef(0);
  const lastPage = useRef(false);

  // "history" to optimize loading : if we've loaded the chat before, don't load again, just fetch from history
  const history = useRef({});

  const isOnline = useRef(true); // if current user is online (window is focused)
  const queuedReceipts = useRef({}); // if current user is offline, receipts to send when he goes

  // self-destruct timer for partial messsages
  const timer = useRef();

  // update header on new message
  const updateChatHeaders = (message, increaseUnread, setUnread) => {
    const index = currentChats.current.findIndex((x) => x._id === message.chatId);
    if (index > -1) {
      // for an existing chat
      if (increaseUnread) {
        currentChats.current[index].unreads[userId]++;
      }

      currentChats.current[index].lastMessage.text = message.text;
      currentChats.current[index].lastMessage.from = message.sender;
      currentChats.current[index].lastMessage.time = message.time;
      currentChats.current[index].lastMessage.attachments = message.attachments.map((x) => x.id);

      if (setUnread) {
        let interlocutor = currentChats.current[index].participants.find((x) => x !== userId);
        currentChats.current[index].unreads[interlocutor] = 1;
      }

      currentChats.current.sort((a, b) => {
        return new Date(b.lastMessage.time) - new Date(a.lastMessage.time);
      });

      // To rerender
      setChats([...currentChats.current]);
    } else {
      // for new chat

      // get chat info
      axios
        .get('/chat/getChatInfo', { params: { chatId: message.chatId } })
        .then((res) => {
          currentChats.current = [res.data].concat(currentChats.current);
          setChats([...currentChats.current]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // send 'read' receipts when window is focused
  const sendQueuedReceipts = () => {
    if (Object.keys(queuedReceipts.current).length > 0 && open && isOnline.current) {
      // drop unread for curr chat
      let index = currentChats.current.findIndex((x) => x._id === currChatId.current);
      if (index > -1) currentChats.current[index].unreads[userId] = 0;
      setChats(currentChats.current);
      Object.keys(queuedReceipts.current).forEach((x) => {
        axios.post('/chat/readMessages', { chatId: x, messages: queuedReceipts.current[x] });
      });

      queuedReceipts.current = {};
    }
  };

  // update "New messages" label
  const updateLabel = () => {
    // drop "New message" label
    currentMessages.current.forEach((x) => (x.firstUnread = false));

    // find in headers
    const index = currentChats.current.findIndex((x) => x._id === currChatId.current);

    if (index > -1) {
      const unread = currentChats.current[index].unreads[userId];
      const length = currentMessages.current.length;

      if (unread > 0 && length > 0 && length - unread >= 0) {
        currentMessages.current[length - unread].firstUnread = true;
      }
    }
  };

  const scrolledUp = () => {
    const element = document.getElementById('chat-container');
    return Boolean(element) && element.scrollTop < -20;
  };

  const processNewMessage = (message) => {
    if (message.type === 'full' && message.sender === userId) {
      updateChatHeaders(message, false, false);
      if (message.chatId === currChatId.current) {
        // remove last partial message
        setPartial({});

        currentMessages.current.push(message);
      } else {
        if (history.current[message.chatId])
          // add to history
          history.current[message.chatId].push(message);
      }
    } else if (message.type === 'full') {
      // for full messages

      // if for the current chat, process
      if (message.chatId === currChatId.current) {
        var increaseUnread = false;
        if (!scrolledUp()) {
          // if user is online suggest he has read the message
          if (isOnline.current && open) {
            axios.post('/chat/readMessages', { chatId: currChatId.current, messages: [message._id] });
          } else {
            // otherwise wait till he goes online
            if (queuedReceipts.current[currChatId.current])
              queuedReceipts.current[currChatId.current].push(message._id);
            else queuedReceipts.current[currChatId.current] = [message._id];

            increaseUnread = true;
          }
        } else {
          increaseUnread = true;
        }

        // put new message to header
        updateChatHeaders(message, increaseUnread, false);

        // remove last partial message
        setPartial({});

        currentMessages.current.push(message);

        // display 'New messages' separator
        if (!isOnline.current) {
          updateLabel();
        }

        setMessages([...currentMessages.current]); // rerender messages
      } else {
        // otherwise, increase unread counter and update headers
        updateChatHeaders(message, true, false);

        if (history.current[message.chatId])
          // add to history
          history.current[message.chatId].push(message);
      }
    } else if (message.type === 'partial') {
      // for partial messages
      if (message.chatId === currChatId.current) {
        // if message is empty, remove
        if (!message.text || !message.text.trim()) {
          setPartial({});
        } else {
          setPartial(message);
        }

        // self-destruct timer
        if (timer.current) window.clearTimeout(timer.current);

        timer.current = window.setTimeout(() => setPartial({}), PARTIAL_MESSAGES_LIFESPAN);
      }
    } else if (message.type === 'notification') {
      if (message.chatId === currChatId.current) {
        currentMessages.current.push(message);
        setMessages([...currentMessages.current]);
      } else {
        if (history.current[message.chatId])
          // add to history
          history.current[message.chatId].push(message);
      }

      updateChatHeaders(message, false, false);
    }
  };

  const getInterlocutorId = (chat) => {
    const index = currentChats.current.findIndex((x) => x._id === chat);
    if (index > -1) return currentChats.current[index].participants.find((x) => x !== userId);
    else return '';
  };

  // update header
  const setReadForChat = (chatId) => {
    const index = currentChats.current.findIndex((x) => x._id === chatId);
    if (index > -1) {
      let interlocutor = getInterlocutorId(chatId);
      if (interlocutor && currentChats.current[index].unreads[interlocutor] > 0) {
        currentChats.current[index].unreads[interlocutor] = 0;
        setChats([...currentChats.current]);
      }
    }
  };

  const processReceipt = (receipt) => {
    if (receipt.type === 'read') {
      setReadForChat(receipt.chatId); // update header
      if (receipt.chatId === currChatId.current) {
        // update message if for the current chat
        receipt.messages.forEach((message) => {
          let index = currentMessages.current.findIndex((x) => x._id === message);
          if (index > -1) {
            currentMessages.current[index].receipts.push({ readBy: receipt.readBy, readAt: receipt.readAt });
          }
        });
        setMessages([...currentMessages.current]);
      } else if (history.current[receipt.chatId]) {
        // update message if for the other chat
        receipt.messages.forEach((message) => {
          let index = history.current[receipt.chatId].findIndex((x) => x._id === message);
          if (index > -1) {
            history.current[receipt.chatId][index].receipts.push({ readBy: receipt.readBy, readAt: receipt.readAt });
          }
        });
      }
    }
  };

  // delete or edit chat or message
  const processUpdate = (update) => {
    if (update.event === 'participantLeft' || update.event === 'participantRemoved') {
      const index = currentChats.current.findIndex((x) => x._id === update.chatId);
      if (index > -1) {
        currentChats.current[index].participants = currentChats.current[index].participants.filter(
          (x) => x !== update.value,
        );
        setChats([...currentChats.current]);
      }
    }
    if (update.event === 'participantAdded') {
      const index = currentChats.current.findIndex((x) => x._id === update.chatId);
      if (index > -1) {
        currentChats.current[index].participants.push(update.value);
        setChats([...currentChats.current]);
      }
    }
    if (update.event === 'chatDeleted') {
      currentChats.current = currentChats.current.filter((x) => x._id !== update.chatId);
      setChats([...currentChats.current]);

      if (history.current[update.chatId]) delete history.current[update.chatId];

      if (update.chatId === currChatId.current) setMessages([...[]]);
    }
    if (update.event === 'messageDeleted') {
      if (update.chatId === currChatId.current) {
        currentMessages.current = currentMessages.current.filter((x) => x._id !== update.messageId);
        setMessages([...currentMessages.current]);
        history.current[update.chatId] = currentMessages.current;
      } else {
        if (history.current[update.chatId]) {
          history.current[update.chatId] = history.current[update.chatId].filter((x) => x._id !== update.messageId);
        }
      }
      if (update.chat) {
        let index = currentChats.current.findIndex((x) => x._id === update.chatId);
        if (index > -1) {
          if (!update.chat.lastMessage) {
            update.chat.lastMessage = currentChats.current[index].lastMessage;
          }
          currentChats.current[index] = update.chat;
        }
        setChats([...currentChats.current]);
      }
    }
  };

  useEffect(() => {
    if (open) {
      sendQueuedReceipts();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  // Only first time, no need to establish WS connection and load chat headers mutiple times
  useEffect(() => {
    if (!loadChats) return;

    setInitialised(true);

    import('socket.io-client').then(({ io }) => {
      if (socket.current) return; // otherwise connects twice in strict mode

      socket.current = io(`${WS_BASE_URL}/chats`, {
        query: {
          token,
        },
        transports: ['websocket'],
        secure: true,
      });

      // Listen for incoming messages
      socket.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
        processNewMessage(message);
      });

      // Processing receipts
      socket.current.on(RECEIPT_EVENT, (receipt) => {
        processReceipt(receipt);
      });

      // Deleting or editing chat or message
      socket.current.on(UPDATE_EVENT, (update) => {
        processUpdate(update);
      });
    });

    Promise.all([UserMap.init(), axios.get('/chat/getUserChats')])
      .then((res) => {
        setChats(res[1].data);
        currentChats.current = res[1].data;
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // set user online/offline
    window.addEventListener('focus', () => {
      isOnline.current = true;
      sendQueuedReceipts();
    });

    window.addEventListener('blur', () => {
      isOnline.current = false;
    });
  }, [loadChats]); // eslint-disable-line react-hooks/exhaustive-deps

  // triggers when new chat is selected
  useEffect(() => {
    if (!chatId) return;

    setMessages([...[]]); // to hide messages from previous chat until new messages are loaded
    setPartial({}); // hide partial message

    // mark last message as read
    if (
      currentMessages.current.length > 0 &&
      currentMessages.current[currentMessages.current.length - 1].sender !== userId &&
      currentMessages.current[currentMessages.current.length - 1].read === false
    ) {
      currentMessages.current[currentMessages.current.length - 1].read = true;
    }

    history.current[currChatId.current] = currentMessages.current; // save messages from old chat

    currChatId.current = chatId;

    // load messages, if we don't have them
    if (!history.current[currChatId.current]) {
      currPage.current = 0;
      lastPage.current = false;
      setLoadingChat(true);
      // Load messages
      axios
        .get('/chat/loadChatMessages', { params: { chatId, page: currPage.current } })
        .then((res) => {
          currentMessages.current = res.data;
          history.current[chatId] = res.data;
          if (res.data.length > 0) {
            updateLabel();
            dropUnreadAndSendRecepts();
          }

          // select in header
          currentChats.current.forEach((x) => (x.selected = false));
          let index = currentChats.current.findIndex((x) => x._id === chatId);
          if (index > -1) {
            currentChats.current[index].selected = true;
            setChats([...currentChats.current]);
          }

          // render messages
          setMessages([...res.data]);
          setLoadingChat(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // if we have, fetch from history
      currentMessages.current = history.current[currChatId.current];
      currPage.current = Math.ceil(history.current[currChatId.current].length / PAGE_SIZE) - 1;
      lastPage.current = false;
      if (currentMessages.current.length > 0) {
        updateLabel();
        dropUnreadAndSendRecepts();
      }
      // select in header
      currentChats.current.forEach((x) => (x.selected = false));
      let index = currentChats.current.findIndex((x) => x._id === chatId);
      if (index > -1) {
        currentChats.current[index].selected = true;
      }
      setMessages([...currentMessages.current]);
    }
  }, [chatId]); // eslint-disable-line react-hooks/exhaustive-deps

  // when chat is selected consider messages as read
  const dropUnreadAndSendRecepts = () => {
    // find in headers
    const index = currentChats.current.findIndex((x) => x._id === currChatId.current);
    if (index > -1) {
      const wasUnread = currentChats.current[index].unreads[userId];

      if (wasUnread > 0) {
        // drop unred for current chat
        currentChats.current[index].unreads[userId] = 0;
        // rerender
        setChats([...currentChats.current]);
      }

      const length = currentMessages.current.length;

      let receipts = [];

      // get receipts
      if (wasUnread > 0 && length > 0) {
        for (var i = length - 1; i >= length - wasUnread && i >= 0; --i) {
          if (currentMessages.current[i].sender !== userId) receipts.push(currentMessages.current[i]._id);
        }
        axios.post('/chat/readMessages', { chatId, messages: receipts });
      }
    }
    // important: clear push notifications from this chat
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations[0] && typeof registrations[0].getNotifications === 'function') {
          registrations[0].getNotifications().then((all) => {
            all.filter((x) => x.tag === `chat:${currChatId.current}`).forEach((n) => n.close());
          });
        }
      });
    }
  };

  // start a new private chat
  const newPrivateChat = (contact) => {
    // first, find out if it is an existing chat
    let found = currentChats.current.find((x) => {
      let f = x.participants.find((p) => p === contact._id);
      return f && x.type === 'private' ? true : false;
    });

    if (found) {
      found.title = contact.fullName;
      return found;
    }

    const newHeader = {
      _id: v4(),
      type: 'private',
      participants: [contact._id, userId],
      unreads: {},
      selected: true,
      lastMessage: {
        from: '',
        text: '',
        time: '',
      },
    };
    currentChats.current.forEach((x) => (x.selected = false));
    currentChats.current = [newHeader, ...currentChats.current];
    setChats(currentChats.current);

    return newHeader;
  };

  // Sends a message to the server
  const sendMessage = (message) => {
    let msg = { ...message };
    if (currentMessages.current.length === 0) {
      let index = currentChats.current.findIndex((x) => x._id === msg.chatId);
      if (index > -1) {
        let interlocutor = currentChats.current[index].participants.find((x) => x !== userId);
        if (interlocutor) msg.interlocutor = interlocutor;
      }
    }

    msg.sent = false;

    if (msg.type === 'full') {
      // add to own messages, rerender
      setMessages((messages) => [...messages, msg]);
      currentMessages.current.push(msg);
      updateLabel();

      // and send
      axios
        .post('/chat/sendMessage', { message: msg })
        .then(({ data }) => {
          updateChatHeaders(data, false, true);
          let index = currentMessages.current.findIndex((x) => x._id === data._id);
          if (index > -1) {
            currentMessages.current[index].sent = true;
            currentMessages.current[index].createdAt = data.createdAt;
            setMessages([...currentMessages.current]);
          }
        })
        .catch((err) => {
          let index = currentMessages.current.findIndex((x) => x._id === msg._id);
          if (index > -1) {
            currentMessages.current[index].failed = true;
            setMessages([...currentMessages.current]);
          }
          console.error(err);
        });
    } else {
      socket.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
    }
  };

  // start a new group chat
  const newGroupChat = (args) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/chat/createNewGroupChat', args)
        .then((res) => {
          currentChats.current.forEach((x) => (x.selected = false));
          currentChats.current = [res.data].concat(currentChats.current);
          setChats(currentChats.current);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

  const updateChat = (id) => {
    axios
      .get('/chat/getChatInfo', { params: { chatId: id } })
      .then((res) => {
        let index = currentChats.current.findIndex((x) => x._id === res.data._id);
        if (index > -1) {
          currentChats.current[index] = res.data;
        } else {
          // user left chat
          currentChats.current = currentChats.current.filter((x) => x._id !== id);
        }
        setChats([...currentChats.current]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadMore = () => {
    if (loadingChat || lastPage.current) return;
    setLoadingChat(true);
    ++currPage.current;
    axios
      .get('/chat/loadChatMessages', { params: { chatId, page: currPage.current } })
      .then((res) => {
        if (res.data.length > 0) {
          currentMessages.current = res.data.concat(currentMessages.current);
          history.current[chatId] = res.data.concat(history.current[chatId] || []);
          setMessages([...currentMessages.current]);
        } else {
          lastPage.current = true;
        }
        setLoadingChat(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const readChat = () => {
    dropUnreadAndSendRecepts();
  };

  const deleteMessage = (chatId, messageId) => {
    if (chatId === currChatId.current) {
      currentMessages.current = currentMessages.current.filter((x) => x._id !== messageId);
      setMessages([...currentMessages.current]);
      history.current[chatId] = currentMessages.current;
      axios.post('/chat/deleteMessage', { chatId, messageId });
    }
  };

  return {
    loading,
    loadingChat,
    messages,
    partial,
    chats,
    newPrivateChat,
    newGroupChat,
    sendMessage,
    updateChat,
    loadMore,
    readChat,
    deleteMessage,
  };
};

export default useChat;
