import React from 'react';
import { Typography, DialogContent, DialogTitle } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function ConfirmDialog({
  open,
  title,
  content = 'Are you sure you want to continue?',
  onConfirm,
  onClose,
  closeButtonText = 'CANCEL',
  closeButtonIcon,
  doneButtonText = 'CONFIRM',
  doneButtonIcon,
}) {
  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle sx={{ whiteSpace: 'break-spaces' }}>{title}</DialogTitle>

      <DialogContent style={{ padding: '3em 2em' }}>
        <Typography>{content}</Typography>
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        closeButtonText={closeButtonText}
        closeButtonIcon={closeButtonIcon}
        doneButtonText={doneButtonText}
        doneButtonIcon={doneButtonIcon}
        onDone={() => {
          onConfirm();
          onClose();
        }}
      />
    </RoundedDialog>
  );
}
