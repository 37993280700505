import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Chip, Divider } from '@mui/material';
import { DialogTitle, DialogContent, Avatar, List, ListItem, Radio, CircularProgress } from '@mui/material';
import { ListItemIcon, TextField, RadioGroup, FormControlLabel, FormLabel, FormControl } from '@mui/material';
import { ListItemText, Grid, Typography, Switch, Collapse } from '@mui/material';
import {
  MailOutline,
  Phone,
  WorkOutline,
  EditRounded,
  VisibilityRounded,
  PersonRounded,
  SearchOutlined,
} from '@mui/icons-material';
import { CloseRounded, RotateLeftRounded, DeleteOutlineOutlined, VpnKeyRounded } from '@mui/icons-material';
import { GroupWork, PersonOutlineRounded, RestorePageRounded, HighlightOffRounded } from '@mui/icons-material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { GeneralButton, RoundedDialog, StandardDialogActions, TextDialog } from '../../../Global/Components';
import { AlertTitle, Alert } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import axios from 'axios';
import moment from 'moment';
import StateManager from '../../../Global/StateManager';
import { validateEmail, FormatDate } from '../../../Global/Functions';
import OutlinedButton from '../../../Global/OutlinedButton';

const options = [
  { message: 'View Only', value: 'view', icon: <VisibilityRounded /> },
  { message: 'Standard', value: 'standard', icon: <PersonRounded /> },
  { message: 'Admin', value: 'admin', icon: <VpnKeyRounded /> },
];

export function UserDialog({ companyId, user, open, onClose, onResult, onDelete, qhubAccess }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suspended, setSuspended] = useState(false);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [access, setAccess] = useState('standard');
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [superGroupAdmin, setSuperGroupAdmin] = useState(false);
  const [loadingShadowLink, setLoadingShadowLink] = useState(false);
  const [shadowLink, setShadowLink] = useState(false);
  const [shadowLinkExpires, setShadowLinkExpires] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [qhubAccessRequestedAt, setQhubAccessRequestedAt] = useState(null);
  const [reasonDialog, setReasonDialog] = useState(false);
  const [supportUser, setSupportUser] = useState(false);
  const role = user ? options.find((x) => x.value === user.access) : null;

  useEffect(() => {
    if (!open) return;
    setEdit(false);
    setShadowLink('');
  }, [open]);

  useEffect(() => {
    if (!user) return;
    setSuspended(!!user.suspendedAt);
    setSupportUser(user.isSupportUser || false);
    setQhubAccessRequestedAt(user.qhubAccessRequestedAt);
  }, [user]);

  function editUser() {
    setEdit(true);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setMobile(user.mobile);
    setJobRole(user.jobRole);
    setAccess(user.access);
    setSuperAdmin(user.isSuperAdmin);
    setSuperGroupAdmin(user.isSuperGroupAdmin);
  }

  function requestShadowLoginAccess(reason) {
    let userId = user._id;
    setLoadingShadowLink(true);
    axios
      .post('/superadmin/companies/requestShadowLoginAccess', { userId, reason })
      .then(({ data }) => {
        setLoadingShadowLink(false);
        setQhubAccessRequestedAt(data.requestedAt);
        StateManager.setSuccessAlert('Access requested');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingShadowLink(false);
      });
  }

  function getShadowLoginLink() {
    let userId = user._id;
    setLoadingShadowLink(true);
    axios
      .post('/superadmin/companies/getShadowLoginLink', { userId })
      .then((res) => {
        setLoadingShadowLink(false);
        setShadowLink(res.data.link);
        setShadowLinkExpires(res.data.expires);
        if (!navigator.clipboard) {
          return;
        }
        navigator.clipboard.writeText(res.data.link).then(
          () => {
            StateManager.setSuccessAlert('Link copied to clipboard');
          },
          (err) => {
            console.error('Async: Could not copy text: ', err);
          },
        );
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingShadowLink(false);
      });
  }

  function confirmDelete() {
    StateManager.setConfirm(`You are about to delete user ${user.fullName}`, deleteUser);
  }

  function deleteUser() {
    let userId = user._id;
    setSaving(true);
    axios
      .post('/superadmin/companies/deleteUser', { userId, companyId })
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('User has been deleted');
        onDelete(userId);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function confirmReset() {
    if (validateEmail(user.email)) {
      StateManager.setConfirm(`Verification link will be emailed to ${user.email}`, sendResetPasswordLink);
    } else {
      setPasswordDialog(true);
    }
  }

  function sendResetPasswordLink() {
    setSaving(true);
    axios
      .post('/superadmin/companies/sendResetPasswordLink', { userId: user._id, companyId })
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('Link has been sent');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function done() {
    const body = {
      companyId,
      userId: user._id,
      firstName,
      lastName,
      email,
      mobile,
      jobRole,
      access,
      isSuperAdmin: superAdmin,
      isSuperGroupAdmin: superGroupAdmin,
    };
    setSaving(true);
    axios
      .post('/superadmin/companies/saveUser', body)
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('User has been saved');
        onResult(res.data);
        onClose();
        setEdit(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function confirmSuspend() {
    StateManager.setConfirm('You are about to suspend this user', suspendUser);
  }

  function suspendUser() {
    let userId = user._id;
    setSaving(true);
    axios
      .post('/superadmin/companies/suspendUser', { userId, companyId })
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('User has been suspended');
        setSuspended(true);
        onResult({ ...user, suspendedAt: new Date() });
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function confirmRestore() {
    StateManager.setConfirm('You are about to restore this user', restoreUser);
  }

  function restoreUser() {
    let userId = user._id;
    setSaving(true);
    axios
      .post('/superadmin/companies/restoreUser', { userId })
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('User has been suspended');
        setSuspended(false);
        onResult({ ...user, suspendedAt: null });
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {edit && <DialogTitle>Edit user</DialogTitle>}

      <DialogContent>
        {edit ? (
          <Grid container>
            <Grid container item sx={{ py: 1 }}>
              <Grid container item xs={6} style={{ paddingRight: 4 }}>
                <TextField
                  fullWidth
                  label="First name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  inputProps={{ maxLength: 64 }}
                />
              </Grid>
              <Grid container item xs={6} style={{ paddingLeft: 4 }}>
                <TextField
                  fullWidth
                  label="Last name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  inputProps={{ maxLength: 64 }}
                />
              </Grid>
            </Grid>
            <Grid container item sx={{ py: 1 }}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid container item sx={{ py: 1 }}>
              <TextField
                fullWidth
                label="Mobile"
                variant="outlined"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                inputProps={{ maxLength: 24 }}
              />
            </Grid>
            <Grid container item sx={{ py: 1 }}>
              <TextField
                fullWidth
                label="Job role"
                variant="outlined"
                value={jobRole}
                onChange={(e) => setJobRole(e.target.value)}
                inputProps={{ maxLength: 64 }}
              />
            </Grid>

            <Grid container item sx={{ py: 1 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Access</FormLabel>
                <RadioGroup
                  aria-label="access"
                  name="access"
                  value={access}
                  onChange={(e) => setAccess(e.target.value)}
                  row
                >
                  {options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      label={option.message}
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Divider />

            <Typography variant="h6" sx={{ mt: 2 }}>
              Special roles
            </Typography>
            <Grid container item sx={{ py: 0.5 }}>
              <FormControlLabel
                label="Q-Admin"
                onChange={(e) => setSuperAdmin(e.target.checked)}
                control={<Checkbox checked={superAdmin} color="primary" />}
              />
            </Grid>
            {access === 'admin' && (
              <Grid container item sx={{ py: 0.5 }} gap={1}>
                <FormControlLabel
                  label="Super Group Admin"
                  onChange={(e) => setSuperGroupAdmin(e.target.checked)}
                  control={<Checkbox checked={superGroupAdmin} color="primary" />}
                />
                {superGroupAdmin && <SuperGroupAccess userId={user?._id} />}
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container>
            <Grid container item alignItems="center">
              <Grid item xs={4}>
                <Avatar
                  src={suspended ? undefined : user?.avatar}
                  style={{
                    background: suspended ? undefined : user?.avatarColor,
                    width: 140,
                    height: 140,
                    fontSize: 55,
                  }}
                >
                  {user?.avatarLetters}
                </Avatar>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5" color={suspended ? 'textSecondary' : undefined}>
                  {user?.fullName}
                </Typography>
              </Grid>
            </Grid>
            {suspended && (
              <Grid container item style={{ marginTop: '1rem' }}>
                <Typography variant="h6" color="textSecondary">
                  This user has been suspended
                </Typography>
              </Grid>
            )}
            {!user?.isVerified && !suspended && (
              <Grid container item style={{ marginTop: '1rem' }}>
                <Typography variant="h6" style={{ color: red[600] }}>
                  This user has not been verified yet
                </Typography>
              </Grid>
            )}
            <Grid container item style={{ marginTop: '2rem' }}>
              <List>
                <ListItem disabled={suspended}>
                  <ListItemIcon>
                    <MailOutline />
                  </ListItemIcon>
                  <ListItemText primary={user?.email} />
                </ListItem>
                {user?.mobile && (
                  <ListItem disabled={suspended}>
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                    <ListItemText primary={user?.mobile} />
                  </ListItem>
                )}
                {user?.jobRole && (
                  <ListItem disabled={suspended}>
                    <ListItemIcon>
                      <WorkOutline />
                    </ListItemIcon>
                    <ListItemText primary={user?.jobRole} />
                  </ListItem>
                )}
                {role && (
                  <ListItem disabled={suspended}>
                    <ListItemIcon>{role.icon}</ListItemIcon>
                    <ListItemText primary={role.message} />
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid container item style={{ marginTop: '1rem', paddingTop: '1rem', borderTop: `1px solid ${grey[300]}` }}>
              {!suspended && !supportUser && (
                <GeneralButton onClick={editUser} startIcon={<EditRounded style={{ color: grey[600] }} />}>
                  Edit
                </GeneralButton>
              )}

              {suspended ? (
                <>
                  <GeneralButton
                    style={{ marginLeft: 8 }}
                    startIcon={<RestorePageRounded style={{ color: grey[600] }} />}
                    onClick={confirmRestore}
                  >
                    Restore
                  </GeneralButton>
                  <GeneralButton
                    style={{ marginLeft: 8 }}
                    startIcon={<DeleteOutlineOutlined style={{ color: red[500] }} />}
                    onClick={confirmDelete}
                  >
                    Delete user
                  </GeneralButton>
                </>
              ) : (
                <GeneralButton
                  style={{ marginLeft: 8 }}
                  startIcon={<HighlightOffRounded style={{ color: grey[600] }} />}
                  onClick={confirmSuspend}
                >
                  Suspend
                </GeneralButton>
              )}
              {!suspended && !supportUser && (
                <>
                  <GeneralButton
                    onClick={confirmReset}
                    style={{ marginLeft: 8 }}
                    startIcon={<RotateLeftRounded style={{ color: grey[600] }} />}
                  >
                    Reset password
                  </GeneralButton>
                  <ResetPasswordDialog
                    open={passwordDialog}
                    onClose={() => setPasswordDialog(false)}
                    userId={user?._id}
                  />
                  {qhubAccess === 'always-on' && (
                    <GeneralButton
                      onClick={getShadowLoginLink}
                      style={{ marginLeft: 8 }}
                      startIcon={<PersonOutlineRounded style={{ color: grey[600] }} />}
                    >
                      Shadow login
                    </GeneralButton>
                  )}
                  {qhubAccess === 'request' && (
                    <GeneralButton
                      onClick={() => setReasonDialog(true)}
                      style={{ marginLeft: 8 }}
                      startIcon={<PersonOutlineRounded style={{ color: grey[600] }} />}
                    >
                      Request shadow login
                    </GeneralButton>
                  )}

                  <TextDialog
                    open={reasonDialog}
                    title="Reason (optional)"
                    onClose={() => setReasonDialog(false)}
                    onResult={requestShadowLoginAccess}
                    editable
                    onlyText
                  />
                </>
              )}
            </Grid>
            {qhubAccessRequestedAt && (
              <Grid container item sx={{ mt: 2 }}>
                <Typography>Shadow login requested {FormatDate(qhubAccessRequestedAt)}</Typography>
              </Grid>
            )}
            {loadingShadowLink && (
              <Grid container item sx={{ mt: 2 }}>
                <CircularProgress size={20} color="primary" style={{ margin: 5 }} />
              </Grid>
            )}
            {shadowLink && (
              <Grid container item sx={{ mt: 2 }}>
                <Grid container item style={{ marginBottom: 16 }}>
                  <Typography>Link expires at {moment(shadowLinkExpires).format('h:mm:ss a')}</Typography>
                </Grid>
                <Grid container item>
                  <TextField fullWidth label="link" variant="outlined" value={shadowLink} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>

      <StandardDialogActions
        additionalActions={
          edit ? (
            <Button
              style={{ color: red[500], marginRight: 'auto' }}
              onClick={() => setEdit(false)}
              startIcon={<CloseRounded />}
            >
              Cancel edit
            </Button>
          ) : (
            <Typography sx={{ marginRight: 'auto', fontSize: 12, ml: 1 }} color="textSecondary">
              id: {user?._id}
            </Typography>
          )
        }
        onClose={onClose}
        saving={saving}
        onDone={done}
        hideDone={!edit}
      />
    </RoundedDialog>
  );
}

function ResetPasswordDialog({ open, onClose, userId }) {
  const [saving, setSaving] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setNewPassword('');
    setConfirmPassword('');
  }, [open]);

  function done() {
    setSaving(true);
    axios
      .post('/superadmin/companies/resetPassword', { newPassword, userId })
      .then((res) => {
        StateManager.setSuccessAlert('Password has been changed');
        setSaving(false);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Change Password</DialogTitle>

      <DialogContent>
        <Grid container item>
          <Alert severity="info" style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}>
            <AlertTitle>To ensure the security of the account your password must:</AlertTitle>
            <Typography style={{ fontSize: 14 }}>Be at least 8 characters long</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 lowercase letter</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 uppercase letter</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 special character</Typography>
          </Alert>
        </Grid>
        <TextField
          required
          fullWidth
          label="New password"
          type="password"
          variant="outlined"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          style={{ marginBottom: 16 }}
          inputProps={{
            autocomplete: 'new-password',
          }}
        />
        <TextField
          required
          fullWidth
          error={newPassword && newPassword !== confirmPassword}
          helperText={newPassword && newPassword !== confirmPassword ? 'Passwords do not match' : ''}
          label="Confirm new password"
          type="password"
          variant="outlined"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          inputProps={{
            autocomplete: 'new-password',
          }}
        />
      </DialogContent>
      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        closeButtonText="CANCEL"
        hideDone={!newPassword || !confirmPassword || newPassword !== confirmPassword}
        onDone={done}
      />
    </RoundedDialog>
  );
}

export function NewUserDialog({ companyId, open, onClose, onResult }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [access, setAccess] = useState('standard');
  const [saving, setSaving] = useState(false);
  const [includePassword, setIncludePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [type, setType] = useState('email');
  const [username, setUsername] = useState('');

  function done() {
    if (includePassword && !password) {
      StateManager.setErrorAlert('Provide password');
      return;
    }
    if (includePassword && password.length < 3) {
      StateManager.setErrorAlert('Password must be at least 3 characters long');
      return;
    }
    if (includePassword && confirmPassword !== password) {
      StateManager.setErrorAlert('Passwords do not match');
      return;
    }
    const body = {
      type,
      username,
      companyId,
      firstName,
      lastName,
      email,
      mobile,
      jobRole,
      access,
      presetPassword: includePassword || type === 'username' ? password : null,
    };
    setSaving(true);
    axios
      .post('/superadmin/companies/addUser', body)
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('User has been created');
        onResult(res.data);
        onClose();
        setFirstName('');
        setLastName('');
        setEmail('');
        setMobile('');
        setJobRole('');
        setPassword('');
        setConfirmPassword('');
        setAccess('standard');
        setIncludePassword(false);
        setUsername('');
        setType('email');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  const hideDone =
    !firstName ||
    !lastName ||
    (type === 'email' && !email) ||
    (type === 'username' && (String(password).length < 3 || confirmPassword !== password));

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New user</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid container item sx={{ py: 1 }}>
            <Grid container item xs={6} style={{ paddingRight: 4 }}>
              <TextField
                fullWidth
                label="First name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid container item xs={6} style={{ paddingLeft: 4 }}>
              <TextField
                fullWidth
                label="Last name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
          </Grid>

          <Grid container item sx={{ py: 1 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">User name type</FormLabel>
              <RadioGroup aria-label="type" name="type" value={type} onChange={(e) => setType(e.target.value)} row>
                <FormControlLabel value={'email'} label={'Email'} control={<Radio color="primary" />} />
                <FormControlLabel value={'username'} label={'Username only'} control={<Radio color="primary" />} />
              </RadioGroup>
            </FormControl>
          </Grid>

          {type === 'username' && (
            <Grid container item sx={{ py: 1 }}>
              <TextField
                fullWidth
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          )}

          {type === 'email' && (
            <>
              <Grid container item sx={{ py: 1 }}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid container item sx={{ py: 1 }}>
                <FormControlLabel
                  label="Set password"
                  onChange={(e) => setIncludePassword(e.target.checked)}
                  control={<Switch checked={includePassword} color="primary" />}
                />
              </Grid>
              {!includePassword && (
                <Grid container item sx={{ py: 1 }}>
                  <Typography gutterBottom>If you DON'T set password an email will be sent to user</Typography>
                </Grid>
              )}
            </>
          )}

          {(includePassword || type === 'username') && (
            <>
              <Grid container item sx={{ py: 1 }}>
                {type === 'email' && (
                  <Typography gutterBottom>If you set password NO email will be sent to user</Typography>
                )}
                <TextField
                  type="password"
                  variant="outlined"
                  fullWidth
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Grid>
              <Grid container item sx={{ py: 1 }}>
                <TextField
                  type="password"
                  variant="outlined"
                  error={password && confirmPassword && confirmPassword !== password}
                  helperText={
                    password && confirmPassword && confirmPassword !== password ? 'Passwords do not match' : ''
                  }
                  fullWidth
                  label="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Grid>
            </>
          )}

          <Grid container item sx={{ py: 1 }}>
            <TextField
              fullWidth
              label="Mobile"
              variant="outlined"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              inputProps={{ maxLength: 24 }}
            />
          </Grid>
          <Grid container item sx={{ py: 1 }}>
            <TextField
              fullWidth
              label="Job role"
              variant="outlined"
              value={jobRole}
              onChange={(e) => setJobRole(e.target.value)}
              inputProps={{ maxLength: 64 }}
            />
          </Grid>

          <Grid container item sx={{ py: 1 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Access</FormLabel>
              <RadioGroup
                aria-label="access"
                name="access"
                value={access}
                onChange={(e) => setAccess(e.target.value)}
                row
              >
                {options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    label={option.message}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <StandardDialogActions
        onClose={onClose}
        closeButtonText="CANCEL"
        saving={saving}
        onDone={done}
        hideDone={hideDone}
      />
    </RoundedDialog>
  );
}

export function DeleteCompanyDialog({ open, onClose, company, onResult }) {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');
  const [result, setResult] = useState(null);
  const companyName = String(company?.name).toLowerCase();

  useEffect(() => {
    setResult(null);
    setName('');
  }, [open]);

  function done() {
    setSaving(true);
    axios
      .post('/superadmin/companies/deleteCompany', { companyId: company._id })
      .then((res) => {
        setSaving(false);
        setResult(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function close() {
    onClose();
    if (result && onResult) onResult();
  }

  return (
    <RoundedDialog open={open} onClose={close} maxWidth="xs" fullWidth>
      {result ? (
        <DialogTitle>Success</DialogTitle>
      ) : (
        <DialogTitle style={{ color: red[700] }}>Serious warning</DialogTitle>
      )}
      <DialogContent>
        {result ? (
          <>
            <Typography gutterBottom>Deleted users: {result.deletedUsers}</Typography>
            <Typography gutterBottom>Deleted tasks: {result.deletedTasks}</Typography>
            <Typography gutterBottom>Deleted forms: {result.deletedForms}</Typography>
            <Typography gutterBottom>Deleted form entries: {result.deletedEntries}</Typography>
            <Typography gutterBottom>Deleted processes: {result.deletedProcesses}</Typography>
            <Typography gutterBottom>Deleted process entries: {result.deletedOngoingProcesses}</Typography>
          </>
        ) : (
          <>
            <Grid container direction="column">
              <Typography variant="h6" gutterBottom>
                This action is irreversible and will result in PERMANENT delete of the following data for company "
                {company?.name}":
              </Typography>
              <Typography gutterBottom>- users</Typography>
              <Typography gutterBottom>- tasks</Typography>
              <Typography gutterBottom>- forms and form entries</Typography>
              <Typography gutterBottom>- processes and process entries</Typography>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Typography gutterBottom>
                If you are absolutelly sure you want to delete all the above data type "{companyName}" in the bellow box
              </Typography>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={close}
        closeButtonText="CANCEL"
        saving={saving}
        onDone={done}
        hideDone={result || name !== companyName}
      />
    </RoundedDialog>
  );
}

export function CreateCompanyDialog({ companyId, open, onClose, onResult }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [includePassword, setIncludePassword] = useState(false);

  function done() {
    if (includePassword && !password) {
      StateManager.setErrorAlert('Provide password');
      return;
    }
    if (includePassword && password.length < 3) {
      StateManager.setErrorAlert('Password must be at least 3 characters long');
      return;
    }
    if (includePassword && confirmPassword !== password) {
      StateManager.setErrorAlert('Passwords do not match');
      return;
    }
    const body = {
      companyId,
      firstName,
      lastName,
      email,
      mobile,
      name,
      password: includePassword ? password : null,
    };
    setSaving(true);
    axios
      .post('/superadmin/companies/createCompany', body)
      .then((res) => {
        setSaving(false);
        StateManager.setSuccessAlert('Company has been created');
        onResult(res.data);
        onClose();
        setFirstName('');
        setLastName('');
        setEmail('');
        setMobile('');
        setName('');
        setPassword('');
        setConfirmPassword('');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New company</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item sx={{ py: 1 }}>
            <Grid container item sx={{ py: 1 }}>
              <TextField
                fullWidth
                label="Company name"
                variant="outlined"
                id="company-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid container item xs={6} style={{ paddingRight: 4 }}>
              <TextField
                fullWidth
                label="First name"
                variant="outlined"
                id="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid container item xs={6} style={{ paddingLeft: 4 }}>
              <TextField
                fullWidth
                label="Last name"
                variant="outlined"
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
          </Grid>
          <Grid container item sx={{ py: 1 }}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              id="new-company-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item sx={{ py: 1 }}>
            <TextField
              fullWidth
              label="Mobile"
              variant="outlined"
              id="new-company-mobile"
              value={mobile}
              onChange={(e) => {
                const phone = String(e.target.value).replace(/\D/g, '');
                if (phone.length > 11) return;
                setMobile(phone);
              }}
              inputProps={{ maxLength: 24 }}
            />
          </Grid>
          <Grid container item sx={{ py: 1 }}>
            <FormControlLabel
              label="Set password"
              onChange={(e) => setIncludePassword(e.target.checked)}
              control={<Switch checked={includePassword} color="primary" />}
            />
          </Grid>
          {!includePassword && (
            <Grid container item sx={{ py: 1 }}>
              <Typography gutterBottom>If you DON'T set password an email will be sent to user</Typography>
            </Grid>
          )}

          <Collapse in={includePassword} style={{ width: '100%' }}>
            <Grid container item sx={{ py: 1 }}>
              <Typography gutterBottom>If you set password NO email will be sent to user</Typography>
              <TextField
                type="password"
                variant="outlined"
                id="new-company-password"
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid container item sx={{ py: 1 }}>
              <TextField
                type="password"
                variant="outlined"
                id="new-company-password-confirm"
                error={password && confirmPassword && confirmPassword !== password}
                helperText={password && confirmPassword && confirmPassword !== password ? 'Passwords do not match' : ''}
                fullWidth
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </Grid>
          </Collapse>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} closeButtonText="CANCEL" saving={saving} onDone={done} />
    </RoundedDialog>
  );
}

function SuperGroupAccess({ userId }) {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupDialog, setGroupDialog] = useState(false);

  useEffect(() => {
    if (!userId) return;
    setLoading(true);
    axios
      .get(`/superadmin/super-groups/getGroupsByUser/${userId}`)
      .then((res) => {
        setLoading(false);
        setGroups(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [userId]);

  return (
    <Grid container item sx={{ py: 1 }}>
      {loading && <CircularProgress size={20} color="primary" style={{ margin: 5 }} />}
      <Typography variant="h6">Super Groups</Typography>
      <Grid container item sx={{ py: 1 }} />
      {groups.map((group) => (
        <Chip key={group._id} label={group.name} sx={{ margin: 0.5 }} />
      ))}
      <OutlinedButton style={{ marginLeft: 8 }} onClick={() => setGroupDialog(true)} startIcon={<GroupWork />}>
        Assign as a manager
      </OutlinedButton>
      <SuperGroupDialog
        open={groupDialog}
        onClose={() => setGroupDialog(false)}
        userId={userId}
        onDone={(res) => {
          setGroups([...groups, ...res]);
          setGroupDialog(false);
        }}
      />
    </Grid>
  );
}

function SuperGroupDialog({ userId, open, onClose, onDone }) {
  const [saving, setSaving] = useState(false);
  const [groupIds, setGroupIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [pattern, setPattern] = useState('');

  const handleToggle = (value) => () => {
    const currentIndex = groupIds.indexOf(value);
    const newChecked = [...groupIds];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setGroupIds(newChecked);
  };

  function done() {
    setSaving(true);
    axios
      .post('/superadmin/super-groups/bulkAssignAsManager', { groupIds, userId })
      .then((res) => {
        setSaving(false);
        onDone(res.data);
        StateManager.setSuccessAlert('Super groups have been assigned');
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/superadmin/super-groups`)
      .then((res) => {
        setLoading(false);
        setGroups(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, []);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Super Groups</DialogTitle>
      <DialogContent>
        <Grid container>
          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            startIcon={<SearchOutlined />}
            value={pattern}
            onChange={(e) => setPattern(e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid container>
          {loading && <CircularProgress size={20} color="primary" style={{ margin: 5 }} />}
          <List dense sx={{ width: '100%', maxWidth: '100%', maxHeight: 400 }}>
            {!loading &&
              groups
                ?.filter((group) => !pattern || group.name.toLowerCase().includes(pattern.toLowerCase()))
                ?.map((group) => {
                  const labelId = `checkbox-list-secondary-label-${group._id}`;
                  return (
                    <ListItem
                      key={group._id}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(group._id)}
                          checked={groupIds.includes(group._id)}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton disableRipple>
                        <ListItemAvatar>
                          <Avatar alt={'Logo 1'} src={group.logo}>
                            {group.name[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={group?.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        closeButtonText="CANCEL"
        saving={saving}
        onDone={done}
        disableDone={groupIds?.length === 0}
      />
    </RoundedDialog>
  );
}
