import React, { useMemo, useState, useEffect } from 'react';
import { DataGridPremium, GridActionsCellItem } from '@mui/x-data-grid-premium';
import { Box, Typography } from '@mui/material';
import StateManager from '../../../../../../Global/StateManager';
import { User } from '../../../../../../Global/Components';
import axios from 'axios';
import { DateTimeCellRenderer } from '../../../AdvanceTable/cell';
import RevisionInfoDialog from '../../../RevisionInfoDialog';
import { formatHistoryRevision } from '../../../../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ChangeHistoryTable = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState(null);

  const columns = useMemo(
    () => [
      {
        field: 'baseVersion',
        headerName: 'Version',
        width: 90,
        display: 'flex',
        renderCell: (params) => {
          const value = formatHistoryRevision(
            params?.value,
            params?.row?.nextVersion,
            params?.row?.revisionFormat,
            params?.row?.manualIssueControl,
            params?.row?.baseManualIssue,
            params?.row?.nextmanualIssue,
          );
          return value;
        },
      },
      {
        field: 'issuedBy',
        headerName: 'Issued By',
        width: 150,
        display: 'flex',
        renderCell: (params) => <User id={params?.value} avatarSize={16} />,
      },
      { field: 'revisionReason', headerName: 'Reason for Change', width: 150 },
      {
        field: 'upissuedAt',
        headerName: 'Release Date',
        width: 150,
        display: 'flex',
        valueFormatter: (value) => {
          if (!value) return null;
          return new Date(value);
        },
        type: 'date',
        renderCell: (params) => {
          let value = params?.formattedValue ?? params?.value;
          return <DateTimeCellRenderer params={value} format={'DD/MM/YYYY'} />;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 30,
        display: 'flex',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={() => {
              setSelectedRevision(params.row);
              setHistoryDialog(true);
            }}
          />,
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axios
      .get(`/dochub/documents/${id}/history`)
      .then((response) => {
        setRows(response.data?.filter((x) => x.status === 'published'));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [id]);

  return (
    <Box style={{ width: '100%' }}>
      <DataGridPremium
        rows={rows}
        columns={columns}
        autoHeight
        initialState={{ density: 'compact' }}
        onRowClick={(params) => {
          setSelectedRevision(params.row);
          setHistoryDialog(true);
        }}
        disableAggregation
        disableRowGrouping
        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                No change history available
              </Typography>
            </Box>
          ),
          footerRowCount: () => null,
          footer: () => null,
        }}
        loading={loading}
        sx={{ mt: 1 }}
      />
      {selectedRevision && (
        <RevisionInfoDialog
          open={historyDialog}
          handleClose={() => {
            setHistoryDialog(false);
            setSelectedRevision(null);
          }}
          document={selectedRevision}
        />
      )}
    </Box>
  );
};

export default ChangeHistoryTable;
