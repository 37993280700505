import React, { useState, useEffect } from 'react';
import { Typography, DialogTitle, DialogContent, useMediaQuery, Grid, useTheme, Autocomplete } from '@mui/material';
import { Collapse, Chip, Menu, MenuItem, FormControlLabel, Checkbox, Button, Switch, RadioGroup } from '@mui/material';
import { ListItemIcon, ListItemText, TextField, Radio } from '@mui/material';
import { RoundedDialog, CrossButton, GeneralButton } from '../../Global/Components';
import { StandardDialogActions, UserChip, RichTextField } from '../../Global/Components';
import { SubTasks, PriorityButton, Fields, LinkedTasks, TaskTemplatesDialog } from './Components';
import { DueDate } from './workflowComponents';
import { FiberManualRecord, LocalOfferRounded, PersonRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { WorkOutlineRounded, CheckCircleOutline } from '@mui/icons-material';
import ConfirmDialog from '../../Global/ConfirmDialog';
import StateManager from '../../Global/StateManager';
import TaskManager from '../../Global/TaskManager';
import { ProjectIcon, TaskIcon } from '../../Global/Icons';
import Tours from '../../Global/Assistant/Tours';
import { grey } from '@mui/material/colors';
import Uploader from '../../Global/Uploader';
import Field from '../../Global/Fields/Field';
import { useSelector } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import axios from 'axios';

export default function NewTask({
  open,
  onClose,
  onResult,
  riskId,
  userId,
  dueAt,
  taskTour,
  ongoingStepId,
  template,
  rowId,
  saveAsDraft,
  initial,
  initialNcr,
  forNcr,
  saveAsNcr,
  projectId,
  actionReference,
  actionReferenceInfo,
  preSetAction,
  requirementId,
  auditId,
  referenceData,
}) {
  const { user } = useSelector(({ profile }) => profile);
  const isPortalUser = user?.access === 'portal';
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [saving, setSaving] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [tagMenu, setTagMenu] = useState();

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [sendToProject, setSendToProject] = useState(false);

  // task data
  const [dueDate, setDueDate] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [priority, setPriority] = useState('Low');
  const [linkedTasks, setLinkedTasks] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [fields, setFields] = useState([]);
  const [sendIndividually, setSendIndividually] = useState(false);

  const [templateFields, setTemplateFields] = useState([]);
  const [taskTemplateDialog, setTaskTemplateDialog] = useState(false);
  const [templateId, setTemplateId] = useState();

  const [runTour, setRunTour] = useState(false);
  const [disableTourScrolling, setDisableTourScrolling] = useState(true);

  const [setPlannedTime, setSetPlannedTime] = useState(false);
  const [plannedDays, setPlannedDays] = useState(1);
  const [ncrType, setNcrType] = useState('none');

  const loadTags = isEmpty(allTags) && open;

  useEffect(() => {
    if (!loadTags) return;
    axios
      .get('/tags/getUserTags')
      .then((res) => {
        setAllTags(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadTags]);

  function loadAvailableProjects(preSelected) {
    axios
      .get('/project/getUserProjects')
      .then(({ data }) => {
        setProjects(data);
        if (preSelected) {
          const selected = data.find((x) => x._id === preSelected);
          if (!selected) return;
          setSendToProject(true);
          setSelectedProject(selected);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  useEffect(() => {
    if (!projectId) return;
    loadAvailableProjects(projectId);
  }, [projectId]);

  useEffect(() => {
    if (!open) return;
    setTitle('');
    setDueDate(dueAt ? dueAt : null);
    setTags([]);
    setDescription(template?.description || '');
    setAttachments(template?.attachments || []);
    setSubTasks(template?.subTasks || []);
    setTemplateFields(template?.fields || []);
    setFields([]);
    setPriority('Low');
    setLinkedTasks([]);
    setAssignedTo(user?.access === 'portal' ? [user._id] : userId ? [userId] : []);
    setSendIndividually(false);
    setTemplateId(template?._id);
  }, [open, template, user, dueAt, userId]);

  useEffect(() => {
    if (!open || !initial) return;

    const copy = cloneDeep(initial);
    console.log('copy', copy);
    setTitle(copy.title);
    setDueDate(copy.dueDate);
    setDescription(copy.description || '');
    setAttachments(copy.attachments || []);
    setSubTasks(copy.subTasks || []);
    setFields(copy.fields || []);
    setPriority(copy.priority);
    setLinkedTasks(copy.linkedTasks || []);
    setAssignedTo(copy.assignedUsers || []);
    setSendIndividually(copy.sendIndividually);
    setTemplateId(copy.templateId);
  }, [open, initial]);

  useEffect(() => {
    setNcrType(initialNcr?.ncrType || 'none');
  }, [initialNcr]);

  useEffect(() => {
    setTimeout(() => {
      if (taskTour) {
        setRunTour(true);
      }
    }, 1500);
  }, [taskTour]);

  function saveField(fieldId, value) {
    const index = templateFields.findIndex((x) => x.id === fieldId);
    if (index === -1) return;
    templateFields[index].filledValue = value;
  }

  function done(createNow) {
    if (!title) {
      StateManager.setErrorAlert('Please provide a title');
      return;
    }
    setSaving(true);

    const taskData = {
      title,
      assignedUsers: assignedTo,
      description,
      sendIndividually,
      attachments,
      subTasks,
      templateId,
      fields: [...templateFields, ...fields],
      dueDate,
      priority,
      linkedTasks,
      riskId,
      tags: tags.map((t) => t._id),
      ongoingStepId,
      rowId,
      projectId: sendToProject ? selectedProject?._id : null,
      actionReference,
      actionReferenceInfo,
      preSetActionId: preSetAction?.id || preSetAction?._id,
      plannedTime: setPlannedTime && plannedDays ? { days: plannedDays } : undefined,
      ...referenceData,
    };

    if (fields.some((x) => x.type === 'ru') && !dueDate) {
      StateManager.setErrorAlert('Due Date is compulsory for Read and Understood field');
      setSaving(false);
      return;
    }

    if (saveAsNcr) {
      const body = {
        ncrId: initialNcr?._id,
        title,
        type: 'task',
        taskTemplateId: templateId,
        requirementId,
        auditId,
        taskData: taskData,
        createNow,
        ncrType,
      };
      axios
        .post('audits/ncrs/saveNcr', body)
        .then(({ data }) => {
          onResult(data);
          setSaving(false);
          onClose();
        })
        .catch((err) => {
          setSaving(false);
          StateManager.setAxiosErrorAlert(err);
        });
    } else {
      if (saveAsDraft) {
        setSaving(false);

        onResult(taskData);
        onClose();
      } else {
        axios
          .post('/tasks/createTask', taskData)
          .then((res) => {
            setSaving(false);
            StateManager.setSuccessAlert('Task has been created');
            TaskManager.reportTaskUpdateEvent(res.data);
            if (onResult) {
              onResult(res.data);
            }
            onClose();
          })
          .catch((err) => {
            setSaving(false);
            console.error(err);
            StateManager.setErrorAlert(err?.response?.data?.message || 'Failed to create task');
          });
      }
    }
  }

  function checkChanges() {
    if (title || description || attachments[0] || subTasks[0] || assignedTo[0] || tags[0]) {
      setConfirm(true);
    } else {
      setDisableTourScrolling(true);
      onClose(null);
    }
  }

  function addUsers() {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setAssignedTo(res.users);
        }
      },
      { initiallySelected: assignedTo },
    );
  }

  function fillFromTemplate(template) {
    if (!template) return;
    setTemplateId(template._id);
    setSubTasks(template.subTasks || []);
    setFields(template.fields || []);
    setAttachments(template.attachments || []);
    setDescription(template.description || '');
  }

  return (
    <RoundedDialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={checkChanges}
      className="scroll-bar"
      fullScreen={!largeDevices}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <TaskIcon />
          <Typography style={{ fontSize: 20 }}>
            {forNcr || saveAsNcr ? 'Non-conformance report' : 'New Task'}
          </Typography>
          <CrossButton onClick={checkChanges} />
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container direction="column">
          {/* when tours are mounted dialog doesn't scroll  */}
          {false && (
            <Tours
              id={'createTask'}
              disableScrolling={disableTourScrolling}
              continuous={runTour}
              run={runTour}
              sendIndex={(i) => {
                if (i > 4) {
                  setDisableTourScrolling(false);
                }
              }}
              onTourEnd={checkChanges}
            />
          )}
          <Grid container item alignItems={'center'} sx={{ py: 1 }}>
            <TextField
              value={title}
              variant="outlined"
              label="Task title"
              style={{ flexGrow: 1 }}
              onChange={(e) => setTitle(e.target.value)}
              className={'taskTitle'}
              id={'new-task-id'}
            />
          </Grid>

          <Grid container item alignItems="flex-end" className={'dueDate'}>
            <DueDate
              editable
              value={dueDate}
              setValue={setDueDate}
              included={Boolean(fields.some((x) => x.type === 'ru'))}
            />
          </Grid>

          {(forNcr || saveAsNcr) && (
            <Grid container alignItems={'center'} sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 500, mr: 1 }}>NCR type:</Typography>
              <RadioGroup value={ncrType} onChange={(e) => setNcrType(e.target.value)} row>
                {['major', 'minor', 'none'].map((x) => (
                  <FormControlLabel key={x} value={x} label={x} control={<Radio />} />
                ))}
              </RadioGroup>
            </Grid>
          )}

          {false && (
            <Grid container item component={Collapse} in={Boolean(dueDate)}>
              <Grid container item alignItems="center">
                <FormControlLabel
                  label="Set planned time"
                  control={<Checkbox checked={setPlannedTime} />}
                  onChange={(e) => setSetPlannedTime(e.target.checked)}
                />

                <TextField
                  value={plannedDays}
                  disabled={!setPlannedTime}
                  type="number"
                  variant="standard"
                  size="small"
                  sx={{ width: 50, ml: 4 }}
                  onChange={(e) => {
                    const value = e.target.value === '' ? '' : Number(e.target.value);
                    if (value !== '' && value < 0) return;
                    setPlannedDays(value);
                  }}
                />

                <Typography color={setPlannedTime ? 'textPrimary' : 'textSecondary'} sx={{ ml: 1 }}>
                  days are planned for the task
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container item alignItems="center" sx={{ py: 1 }} className={'priority'}>
            <Typography style={{ fontWeight: 600, marginRight: '1em' }}>Set a priority:</Typography>
            <PriorityButton priority={priority} setPriority={setPriority} />
          </Grid>

          {allTags[0] && (
            <Grid container item alignItems="center" spacing={1} sx={{ py: 1 }}>
              <Grid container item alignItems="center" lg={3} md={3} sm={12} justifyContent="space-between">
                <Typography style={{ fontWeight: 600, marginRight: '1em' }}>Tags:</Typography>
                <GeneralButton
                  onClick={(e) => setTagMenu(e.currentTarget)}
                  style={{ margin: theme.spacing(1), width: 120 }}
                  startIcon={<LocalOfferRounded style={{ color: grey[500] }} />}
                >
                  Add tag
                </GeneralButton>
              </Grid>

              <Grid container item alignItems="center" lg={9} md={9} sm={12}>
                {tags.length === 0 && (
                  <Typography color="textSecondary" noWrap>
                    No tags selected
                  </Typography>
                )}
                {tags.map((tag) => (
                  <Chip
                    key={tag._id}
                    style={{
                      background: tag.color,
                      color: theme.palette.getContrastText(tag.color),
                      margin: theme.spacing(1),
                    }}
                    label={tag.text}
                    onDelete={() => setTags([...tags.filter((x) => x._id !== tag._id)])}
                  />
                ))}
              </Grid>

              <Menu open={Boolean(tagMenu)} onClose={() => setTagMenu(null)} anchorEl={tagMenu}>
                {allTags.map((tag) => (
                  <MenuItem
                    key={tag._id}
                    onClick={() => {
                      setTags([...tags, tag]);
                      setTagMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      <FiberManualRecord style={{ color: tag.color }} />
                    </ListItemIcon>
                    <ListItemText primary={tag.text} />
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}

          {!isPortalUser && (
            <Grid container item alignItems="center" spacing={1} sx={{ py: 1 }}>
              <Grid
                container
                item
                alignItems="center"
                lg={3}
                md={3}
                sm={12}
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Typography style={{ fontWeight: 600, marginRight: '1rem' }} noWrap>
                  Send to:
                </Typography>

                <GeneralButton
                  onClick={addUsers}
                  className={'selectUser'}
                  startIcon={<PersonRounded style={{ color: grey[700] }} />}
                >
                  Add users
                </GeneralButton>
              </Grid>

              <Grid container item alignItems="center" lg={9} md={9} sm={12}>
                {assignedTo.length === 0 && (
                  <Typography color="textSecondary" noWrap>
                    The task will be assigned to you if blank
                  </Typography>
                )}
                {assignedTo?.map((user) => (
                  <UserChip key={user} id={user} onDelete={() => setAssignedTo(assignedTo.filter((x) => x !== user))} />
                ))}
              </Grid>
            </Grid>
          )}

          <Collapse style={{ width: '100%' }} in={assignedTo.length > 1}>
            <Grid container item>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={sendIndividually}
                    onChange={(event) => setSendIndividually(event.target.checked)}
                    disabled={fields.length > 0 && fields.some((x) => x.type === 'ru')}
                  />
                }
                label="Send as individual tasks to all assignees"
              />
            </Grid>
          </Collapse>

          <Grid container item sx={{ mt: 1 }}>
            <FormControlLabel
              label="Send this task to a project"
              control={<Switch checked={sendToProject} />}
              onChange={(e) => {
                setSendToProject(e.target.checked);
                if (e.target.checked && isEmpty(projects)) {
                  loadAvailableProjects();
                }
              }}
            />
          </Grid>
          <Grid container item component={Collapse} in={sendToProject} sx={{ mt: 1 }}>
            <Autocomplete
              options={projects}
              value={selectedProject}
              getOptionLabel={(option) => option?.title || ''}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              openOnFocus
              fullWidth
              renderOption={(props, option) => (
                <li {...props}>
                  <Grid container alignItems="center">
                    <ProjectIcon />
                    <Typography style={{ marginLeft: '1rem' }} noWrap>
                      {option.title}
                    </Typography>
                  </Grid>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Select a project" />}
              onChange={(e, value) => setSelectedProject(value)}
            />
          </Grid>

          <Grid container item sx={{ py: 1 }} className={'description'}>
            <Typography variant="h6" gutterBottom>
              Description:
            </Typography>
            <RichTextField
              initial={description}
              onChange={setDescription}
              placeholder="Provide description for the task"
              editable
            />
          </Grid>

          <Grid container item sx={{ py: 1 }} className={'attach'}>
            <Typography variant="h6">Attachments:</Typography>
            <Uploader uploaded={attachments} onChange={setAttachments} withLinkedFeatures={true} />
          </Grid>

          {!isEmpty(templateFields) && (
            <Grid container item sx={{ py: 1 }}>
              {templateFields.map((item) => (
                <Field key={item.id} editable item={item} onSave={saveField} forTaskTemplate />
              ))}
            </Grid>
          )}

          <Grid container item sx={{ py: 1 }}>
            <Typography variant="h6">Task Fields:</Typography>
            <Fields
              initial={fields}
              onItemsChange={(d) => {
                setFields(d);
                if (d.some((x) => x.type === 'ru')) {
                  setSendIndividually(true);
                }
              }}
              hideTasks
              forPortal={isPortalUser}
            />
          </Grid>

          <Grid container item sx={{ py: 1 }} className={'subTasks'}>
            <SubTasks subTasks={subTasks} setSubTasks={setSubTasks} isNewTask />
          </Grid>

          {!isPortalUser && (
            <Grid container item className={'linkedTasks'}>
              <LinkedTasks linkedTasks={linkedTasks} setLinkedTasks={setLinkedTasks} />
            </Grid>
          )}

          <TaskTemplatesDialog
            open={taskTemplateDialog}
            onClose={() => setTaskTemplateDialog(false)}
            onResult={fillFromTemplate}
          />
        </Grid>
      </DialogContent>

      <ConfirmDialog
        open={confirm}
        title="Task has unsaved changes"
        content="Are you sure you want to discard them?"
        onConfirm={() => onClose(null)}
        onClose={() => setConfirm(false)}
        closeButtonText={'CANCEL'}
        doneButtonIcon={<DeleteOutlineRounded />}
        doneButtonText="DISCARD"
      />

      <StandardDialogActions
        saving={saving}
        onClose={checkChanges}
        onDone={() => done(false)}
        additionalActions={
          <>
            {isPortalUser || saveAsDraft ? null : (
              <>
                <Button
                  onClick={() => setTaskTemplateDialog(true)}
                  startIcon={<WorkOutlineRounded />}
                  sx={{ color: (theme) => theme.palette.text.secondary, mr: 1 }}
                >
                  use task template
                </Button>
              </>
            )}

            {saveAsNcr && title && (
              <Button startIcon={<CheckCircleOutline />} variant="outlined" onClick={() => done(true)}>
                send ncr now
              </Button>
            )}

            <Grid sx={{ mr: 'auto' }} />
          </>
        }
      />
    </RoundedDialog>
  );
}
