import React, { useState, useEffect } from 'react';
import { Paper, DialogContent, Grid, Typography, ListItemButton, DialogTitle, TextField, Alert } from '@mui/material';
import { CircularProgress, Autocomplete, Button } from '@mui/material';
import { CheckCircleRounded, CheckCircleOutlineRounded, KeyboardArrowLeftRounded } from '@mui/icons-material';
import { green, grey } from '@mui/material/colors';
import FilePreviewer from '../FilePreviewer';
import { isArray, isEmpty, isObject, difference } from 'lodash';
import { RoundedDialog, StandardDialogActions, Signature } from '../Components';
import axios from 'axios';
import StateManager from '../StateManager';
import { FILE_PROCESSING_URL } from '../../../constants';

export default function ReadAndSign({ config, initial, onResult, item, editable }) {
  const [readDocuments, setReadDocuments] = useState([]);
  const [selectedPortal, setSelectedPortal] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [signature, setSignature] = useState(null);

  const useSelectedDocuments = item?.fieldData?.useSpaces || item?.fieldData?.useSubFolders;
  const needToSelectDocuments = useSelectedDocuments && isEmpty(selectedDocuments) && isArray(item.fieldData.spaces);

  const filesToRead = useSelectedDocuments
    ? selectedDocuments
    : config?.type === 'docs' && isArray(config.files)
    ? config.files
    : config?.type === 'folder' && isArray(item?.fieldData?.files)
    ? item.fieldData.files
    : [];

  useEffect(() => {
    setReadDocuments(isArray(initial?.readDocuments) ? initial.readDocuments : []);
    setSelectedDocuments(isArray(initial?.selectedDocuments) ? initial.selectedDocuments : []);
    setSignature(isObject(initial?.signature) ? initial.signature : null);
  }, [initial]);

  const allRead = isEmpty(
    difference(
      filesToRead.map((x) => x.id),
      readDocuments,
    ),
  );

  function save(params) {
    if (!editable) return;
    const result = { readDocuments, signature, selectedDocuments, ...params };
    onResult(result);
  }

  return (
    <Grid container>
      {needToSelectDocuments ? (
        <>
          {item.fieldData.spaces.length === 1 && item?.fieldData?.useSubFolders && (
            <Grid container>
              <Autocomplete
                options={item.fieldData.spaces[0].folders}
                fullWidth
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                getOptionLabel={(option) => (option ? option.title : '')}
                openOnFocus
                value={selectedFolder}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <Typography noWrap>{option.title}</Typography>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select a sub folder" />}
                onChange={(e, value) => {
                  setSelectedFolder(value);
                  setSelectedDocuments(value.files);
                  save({ selectedDocuments: value.files });
                }}
              />
            </Grid>
          )}

          {item.fieldData.spaces.length > 1 && (
            <>
              <Grid container>
                <Autocomplete
                  options={item.fieldData.spaces}
                  fullWidth
                  isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  getOptionLabel={(option) => (option ? option.title : '')}
                  openOnFocus
                  value={selectedPortal}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Typography noWrap>{option.title}</Typography>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Select a portal" />}
                  onChange={(e, value) => {
                    setSelectedFolder(null);
                    if (item?.fieldData?.useSubFolders) {
                      setSelectedPortal(value);
                    } else {
                      setSelectedDocuments(value.files);
                      save({ selectedDocuments: value.files });
                    }
                  }}
                />
              </Grid>
              {selectedPortal && item?.fieldData?.useSubFolders && (
                <Grid container sx={{ mt: 2 }}>
                  <Autocomplete
                    options={selectedPortal.folders}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    getOptionLabel={(option) => (option ? option.title : '')}
                    openOnFocus
                    value={selectedFolder}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <Typography noWrap>{option.title}</Typography>
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select a sub folder" />}
                    onChange={(e, value) => {
                      setSelectedFolder(value);
                      setSelectedDocuments(value.files);
                      save({ selectedDocuments: value.files });
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {useSelectedDocuments && (
            <Grid container sx={{ mb: 1 }}>
              <Button
                startIcon={<KeyboardArrowLeftRounded />}
                onClick={() => {
                  setSelectedPortal(null);
                  setSelectedFolder(null);
                  setSelectedDocuments([]);
                  save({ selectedDocuments: [] });
                }}
              >
                select other documents
              </Button>
            </Grid>
          )}
          {filesToRead.map((file) => (
            <Grid
              container
              item
              key={file.id}
              component={Paper}
              variant="outlined"
              sx={{
                my: 1,
                background: (theme) =>
                  readDocuments.includes(file.id)
                    ? theme.palette.mode === 'dark'
                      ? green[800]
                      : green[50]
                    : theme.palette.mode === 'dark'
                    ? grey[800]
                    : grey[100],
                border: readDocuments.includes(file.id) ? `3px solid ${green[600]}` : `3px solid ${grey[400]}`,
              }}
            >
              <Grid
                container
                component={ListItemButton}
                sx={{
                  py: 1.5,
                  px: 2,
                  borderRadius: 1,
                }}
                alignItems={'center'}
                onClick={() => {
                  setDialog(true);
                  setSelectedFile(file);
                }}
              >
                <Typography variant="h6">{file.originalname || file.title}</Typography>
                {readDocuments.includes(file.id) ? (
                  <CheckCircleRounded fontSize="large" sx={{ ml: 'auto' }} />
                ) : (
                  <CheckCircleOutlineRounded fontSize="large" sx={{ color: grey[300], ml: 'auto' }} />
                )}
              </Grid>
            </Grid>
          ))}

          {config?.signatureRequired && !isEmpty(filesToRead) && (
            <>
              {allRead && (
                <Grid container sx={{ my: 2 }}>
                  <Typography color="text.secondary">
                    I confirm that I have read and understood {config.files.length === 1 ? 'this' : 'these'} document
                    {config.files.length === 1 ? '' : 's'} and that my signature is as valid and legally binding as a
                    handwritten signature.
                  </Typography>
                </Grid>
              )}
              <Grid container sx={{ my: 1 }}>
                <Signature
                  initial={signature}
                  onChange={(res) => save({ signature: res })}
                  disabled={!allRead}
                  editable={editable}
                  additionalComponent={
                    <Grid container sx={{ mb: 2 }}>
                      {editable ? (
                        allRead ? (
                          <Alert severity="success" style={{ width: '100%', borderRadius: 8 }}>
                            Well done - you’ve read {config.files.length === 1 ? '' : 'all'} the document
                            {config.files.length === 1 ? '' : 's'}. You can now sign.
                          </Alert>
                        ) : (
                          <Alert severity="warning" style={{ width: '100%', borderRadius: 8 }}>
                            Please read {config.files.length === 1 ? '' : 'all'} the above document
                            {config.files.length === 1 ? '' : 's'} before signing
                          </Alert>
                        )
                      ) : null}
                    </Grid>
                  }
                />
              </Grid>
            </>
          )}
        </>
      )}

      <FilePreviewDialog
        open={dialog}
        onClose={() => {
          setDialog(false);
          setSelectedFile(null);
        }}
        file={selectedFile}
        onDone={() => {
          if (!selectedFile?.id) return;
          const res = [...readDocuments, selectedFile.id];
          setReadDocuments(res);
          save({ readDocuments: res });
        }}
      />
    </Grid>
  );
}

function FilePreviewDialog({ file, open, onClose, onDone }) {
  const [document, setDocument] = useState(null);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const isQDoc = document && document?.category !== 'normal';
  const isDLDoc = file?.type === 'qhub';

  useEffect(() => {
    setScrolledToBottom(false);
    setDocument(null);

    if (file?.type === 'qhub') {
      setLoadingDoc(true);
      axios
        .post('/dochub/documents/getDocument', { id: file.id })
        .then(({ data }) => {
          const access = data.access;
          if (!access) {
            setLoadingDoc(false);
            StateManager.setErrorAlert(data.message);
            return;
          }
          const doc = data.document;
          if (doc.encrypted && doc.category === 'normal') {
            const token = localStorage.getItem('token');
            const config = { customBase: 'file-processing' };
            const body = { file: doc.file };
            axios
              .post('uploader/getObject', body, config)
              .then(({ data }) => {
                if (data?.url) {
                  doc.url = `${FILE_PROCESSING_URL}${data.url}?user_token=${token}`;
                  setLoadingDoc(false);
                  setDocument(doc);
                }
              })
              .catch((err) => {
                StateManager.setAxiosErrorAlert(err);
              });
          } else {
            if (doc.category === 'normal') {
              doc.url = doc.file.location;
            }
            setDocument(doc);

            setLoadingDoc(false);
          }
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setLoadingDoc(false);
        });
    } else {
      setLoadingDoc(false);
    }
  }, [file]);

  if (!file) return null;

  function handleScroll(e) {
    if (scrolledToBottom) return;
    const eps = 5;
    const bottomReached = e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < eps;
    setScrolledToBottom(bottomReached);
  }

  function done() {
    onDone();
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{file.originalname || file.title}</DialogTitle>
      <DialogContent sx={{ '& .full-window-div': { height: '75vh', border: 'none' } }}>
        {isDLDoc ? (
          loadingDoc ? (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
              <CircularProgress />
            </Grid>
          ) : document ? (
            <Grid container sx={{ maxHeight: '75vh', p: 1 }}>
              <FilePreviewer
                title={document.title}
                src={document.category === 'normal' ? document.url : document.sections}
                document={document}
                extension={document.category === 'normal' ? document.file.mimetype : 'qhub'}
                hasToc={document.hasToc}
                handleScroll={handleScroll}
              />
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
              <Typography>Cannot display the document</Typography>
            </Grid>
          )
        ) : (
          <Grid container sx={{ maxHeight: '75vh', p: 1 }}>
            <FilePreviewer title={file.originalname} src={file.location} extension={file.mimetype} />
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={(isQDoc && !scrolledToBottom) || (isDLDoc && !document)}
      />
    </RoundedDialog>
  );
}
