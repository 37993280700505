import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import configureStore from './redux';
import AlertBox from './Components/Global/Alert';
import PrimaryButton from './Components/Global/PrimaryButton';
import CustomError from './Components/Global/ErrorBoundary';
import { SnackbarProvider } from 'notistack';
import { rollBarEnv, rollBarToken } from './constants';
import { msalConfig } from './constants/msal';
import ActionSnack from './Components/Global/Snacks/ActionSnack';

const store = configureStore();

const appUpdated = localStorage.getItem('appUpdated');

const rollbarConfig = {
  accessToken: rollBarToken,
  environment: rollBarEnv,
  captureUncaught: true,
  captureUnhandledRejections: true,
  code_version: process.env.REACT_APP_VERSION,
  enabled: process.env.REACT_APP_ENV !== 'development',
  payload: {
    person: {
      id: localStorage.getItem('_id') || '',
      username: localStorage.getItem('name') || '',
    },
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={CustomError}>
        <Provider store={store}>
          <Router
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
          >
            <SnackbarProvider
              maxSnack={10}
              Components={{
                actionSnack: ActionSnack,
              }}
            >
              <App instance={msalInstance} />
              {appUpdated === 'true' && (
                <AlertBox
                  open={true}
                  message={'App Updated, refresh for new version'}
                  action={
                    <PrimaryButton
                      style={{
                        marginLeft: 5,
                      }}
                      title="Reload"
                      size="small"
                      action={() => {
                        localStorage.removeItem('appUpdated');
                        window.location.reload();
                      }}
                    />
                  }
                />
              )}
            </SnackbarProvider>
          </Router>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  </>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/*serviceWorker.register({
  skipWaiting: true,
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    registration.unregister().then(() => {
      localStorage.setItem('appUpdated', 'true');
    });
  },
});*/
