import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { grey, red, green, blue } from '@mui/material/colors';
import { Image } from '@mui/icons-material';
import { IconButton, Box } from '@mui/material';
import FileUploadDialog from './file';
import SmallUser from '../../../../Global/Components/SmallUser';
import UserGroup from '../../../../Global/Components/UserGroup';

export const CollapsibleButton = ({
  classes,
  label,
  activeBorderColor = red[600],
  borderColor = grey[200],
  activeLabelColor = red[600],
  labelColor = '#3c4043',
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Button
      disableRipple
      {...props}
      sx={{
        minWidth: '20px',
        minHeight: 40,
        backgroundColor: ({ palette }) => (palette.mode === 'dark' ? palette.common.black : palette.common.white),
        padding: '4px 5px',
        borderColor,
        borderWidth: '2px',
        borderRadius: '8px',
        borderStyle: 'solid',
        marginRight: '5px',
        '&:hover': {
          boxShadow: '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
          backgroundColor: '#fafafb',
          borderColor: activeBorderColor,
          color: activeLabelColor,
        },
        '&:active': {
          backgroundColor: '#f1f3f4',
          borderColor: activeBorderColor,
          color: activeLabelColor,
        },
        fontFamily: "'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif",
        color: labelColor,
        textTransform: 'none',
        fontWeight: 500,
        ...(collapsed && {
          minWidth: 'inherit',
          // center icon
          '& > .MuiButton-endIcon': {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '0px',
          },
        }),
      }}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
    >
      {!collapsed && label}
    </Button>
  );
};

export const NormalButton = ({
  classes,
  label,
  activeBorderColor = blue[600],
  borderColor = grey[200],
  activeLabelColor = blue[600],
  labelColor = '#3c4043',
  backgroundColor,
  size = 'medium',
  spacing = true,
  borderStyle = 'solid',
  ...props
}) => {
  return (
    <Button
      disableRipple
      {...props}
      size={size}
      sx={{
        minWidth: 'inherit',
        minHeight: 40,
        backgroundColor: ({ palette }) =>
          backgroundColor ? backgroundColor : palette.mode === 'dark' ? palette.common.black : palette.common.white,
        padding: '4px 9px',
        borderColor,
        borderWidth: '2px',
        borderRadius: '8px',
        borderStyle,
        marginRight: spacing ? '5px' : '',
        '&:hover': {
          boxShadow: '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
          backgroundColor: backgroundColor ? backgroundColor : '#fafafb',
          borderColor: activeBorderColor,
          color: activeLabelColor,
        },
        '&:active': {
          backgroundColor: backgroundColor ? backgroundColor : '#f1f3f4',
          borderColor: activeBorderColor,
          color: activeLabelColor,
          borderStyle,
        },
        '&:disabled': {
          border: 'none',
          backgroundColor: (t) => (t.palette.mode === 'dark' ? grey[700] : grey[300]),
        },
        fontFamily: "'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif",
        color: (t) =>
          t.palette.mode === 'dark' ? t.palette.getContrastText(backgroundColor || t.palette.common.black) : labelColor,
        textTransform: 'none',
        fontWeight: 500,
      }}
    >
      {label}
    </Button>
  );
};

export const UploadButton = ({ label, onClick, id = 'new-file' }) => {
  return (
    <>
      <Box component="input" sx={{ display: 'none' }} id={id} type="file" onChange={onClick} />
      <label htmlFor={id}>
        <CollapsibleButton
          label={label}
          endIcon={<CloudUploadIcon color="primary" />}
          aria-label={`upload`}
          component="span"
          activeBorderColor={blue[500]}
          activeLabelColor={blue[500]}
        />
      </label>
    </>
  );
};

export const CommentUploadButton = ({ onClick, id = 'new-comment-file' }) => {
  return (
    <>
      <Box component="input" sx={{ display: 'none' }} id={id} type="file" onChange={onClick} />
      <label htmlFor={id}>
        <IconButton component="span" airea-label="upload" size="small">
          <Image color="primary" />
        </IconButton>
      </label>
    </>
  );
};

export const FileUploadButton = ({
  label,
  onResult,
  history,
  baseVersion,
  revisionFormat,
  encrypted,
  manualIssueControl,
  manualIssue,
  size = 'small',
}) => {
  const [fileDialog, setFileDialog] = useState(false);
  return (
    <>
      <Button
        startIcon={<CloudUploadIcon />}
        onClick={() => setFileDialog(true)}
        variant="contained"
        disableElevation
        size={size}
      >
        {label}
      </Button>
      <FileUploadDialog
        open={fileDialog}
        onClose={() => setFileDialog(false)}
        fileHistory={history}
        onDone={(data) => {
          onResult(data);
          setFileDialog(false);
        }}
        baseVersion={baseVersion}
        revisionFormat={revisionFormat}
        encrypted={encrypted}
        manualIssueControl={manualIssueControl}
        manualIssue={manualIssue}
      />
    </>
  );
};

export const VersionCell = ({ rev }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          color: rev.state === 'rejected' || rev.state === 'cancelled' ? red[500] : green[500],
          fontWeight: 'bold',
          textTransform: 'capitalize',
          marginRight: '5px',
        }}
      >
        {rev.state}
      </Box>
      {rev.finalStateUsers && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box
            component="span"
            sx={{
              margin: '0px 1px',
            }}
          >
            by
          </Box>
          <Box
            component="span"
            sx={{
              margin: '0px 1px',
            }}
          >
            {rev.finalStateUsers ? (
              <UserGroup ids={rev.finalStateUsers} avatarSize={24} max={4} />
            ) : (
              <SmallUser id={rev.finalStateUser} fontSize={12} />
            )}
          </Box>
          <Box
            component="span"
            sx={{
              margin: '0px 3px',
            }}
          >
            at
          </Box>
        </Box>
      )}
      {rev.stage} stage
    </Box>
  );
};

export const FileCell = ({ user }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box component="span" sx={{ margin: '0px 1px' }}>
        <SmallUser id={user} fontSize={12} />
      </Box>
      <Box
        component="span"
        sx={{
          color: blue[500],
          fontWeight: 'bold',
          marginLeft: '5px',
        }}
      >
        uploaded a new file
      </Box>
    </Box>
  );
};
