import React, { useEffect, useState } from 'react';
import { Grid, Typography, DialogContent, DialogTitle } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { ArrowForward, Schedule } from '@mui/icons-material';
import { RoundedDialog, GeneralButton, StandardDialogActions } from '../../../Global/Components';
import { UserGroup, User } from '../../../Global/Components';
import { grey, red, green } from '@mui/material/colors';
import { FormatDate, FormatDuration } from '../../../Global/Functions';
import StateManager from '../../../Global/StateManager';
import StepDueDate from './StepDueDate';
import { isEmpty, isArray } from 'lodash';
import moment from 'moment';
import axios from 'axios';

function calculateSpentTime(startedAt, endedAt) {
  const start = moment(startedAt);
  const end = moment(endedAt);
  const duration = moment.duration(end.diff(start));

  return FormatDuration(duration);
}

function calculateTimeDelta(completedAt, dueAt) {
  return moment.duration(moment(dueAt).diff(moment(completedAt)));
}

function getStateColor(state) {
  if (state === 'rejected') return red[500];
  if (state === 'approved') return green[500];
  return grey[500];
}

export default function StepInfo({ open, onClose, stepId, onDueChange, onApprovalDueChange }) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [info, setInfo] = useState();
  const [dueDialog, setDueDialog] = useState(false);
  const [approvalDueDialog, setApprovalDueDialog] = useState(false);

  const saveDueDate = (dueDate) => {
    setSaving(true);
    axios
      .post('/process/saveDueDate', { dueDate, stepId })
      .then((res) => {
        setSaving(false);
        onDueChange(res.data.dueAt);
        onClose();
        StateManager.setSuccessAlert('Due date has been saved');
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  };

  const saveApprovalDueDate = (dueAt) => {
    setSaving(true);
    axios
      .post('/standardApproval/saveDueDate', { dueAt, approvalId: info.approval._id })
      .then((res) => {
        setSaving(false);
        onClose();
        onApprovalDueChange(dueAt);
        StateManager.setSuccessAlert('Due date has been saved');
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  };

  useEffect(() => {
    if (!open || !stepId) return;

    axios
      .get('/process/getOngoingStepInfo', { params: { id: stepId } })
      .then((res) => {
        setInfo(res.data);
        setLoading(false);
      })
      .catch(console.error);
  }, [open, stepId]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Step info</DialogTitle>
      <DialogContent>
        {loading && (
          <Grid container style={{ height: '25vh' }} justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!loading && info && (
          <Grid container direction="column">
            {isArray(info.owners) && !isEmpty(info.owners) && (
              <Grid container item alignItems="center" sx={{ mb: 1 }}>
                <Typography gutterBottom variant="subtitle1" style={{ marginRight: 16 }}>
                  Assigned user{info.owners.length === 1 ? '' : 's'}
                </Typography>
                <UserGroup ids={info.owners} />
              </Grid>
            )}

            {!info.completedAt && (
              <Grid container item style={{ padding: '0.75rem 0' }}>
                {!info.dueAt && (
                  <Grid container item>
                    <Typography gutterBottom variant="subtitle1">
                      This step has no due date
                    </Typography>
                  </Grid>
                )}
                <Grid container item>
                  <GeneralButton
                    disabled={saving}
                    startIcon={<Schedule style={{ color: grey[500] }} />}
                    onClick={() => setDueDialog(true)}
                  >
                    {info.dueAt ? 'Change' : 'Add'} due date
                  </GeneralButton>
                  <StepDueDate
                    initial={info.dueAt}
                    open={dueDialog}
                    onClose={() => setDueDialog(false)}
                    onResult={saveDueDate}
                    title="Select due date for the step"
                  />
                </Grid>
              </Grid>
            )}
            {(info.plannedTime || info.dueAt || info.completedAt) && (
              <Grid container item sx={{ py: 1, borderBottom: `1px solid ${grey[200]}`, mb: 1 }}>
                <Grid container item alignItems="center" justifyContent="space-between" style={{ marginBottom: 16 }}>
                  <Typography variant="subtitle1">Timing:</Typography>
                </Grid>
                {info.plannedTime && (
                  <Grid container item>
                    <Typography gutterBottom>
                      {`Planned time for this step: ${info.plannedTime.days}d ${info.plannedTime.hours || 0}h`}
                    </Typography>
                  </Grid>
                )}

                {info.dueAt && (
                  <Grid container item>
                    <Typography gutterBottom>
                      {`This step should ${info.completedAt ? 'have been' : 'be'} finished ${FormatDate(info.dueAt)}`}
                    </Typography>
                  </Grid>
                )}
                {info.completedAt && (
                  <Grid container item>
                    <Typography gutterBottom>
                      {`Time spent: ${calculateSpentTime(info.createdAt, info.completedAt)}`}
                    </Typography>
                  </Grid>
                )}
                {info.completedAt && info.dueAt && (
                  <Grid container item>
                    {(() => {
                      const delta = calculateTimeDelta(info.completedAt, info.dueAt);
                      return (
                        <Typography>
                          <span style={{ fontWeight: 500 }}>Time delta:</span>
                          {'   '}
                          <span style={{ color: delta > 0 ? green[600] : delta < 0 ? red[600] : grey[600] }}>
                            {FormatDuration(delta)}
                          </span>
                        </Typography>
                      );
                    })()}
                  </Grid>
                )}
              </Grid>
            )}
            {info.approval && (
              <Grid container item sx={{ py: 1, borderBottom: `1px solid ${grey[200]}`, mb: 1 }}>
                <Grid container item alignItems="center" justifyContent="space-between" style={{ marginBottom: 16 }}>
                  <Typography variant="subtitle1">Approval:</Typography>
                  <Typography style={{ fontWeight: 500, color: getStateColor(info.approval.state), fontSize: 18 }}>
                    {info.approval.state}
                  </Typography>
                </Grid>
                <Grid container item alignItems="center" style={{ marginBottom: 12 }}>
                  <Typography>
                    <span style={{ fontWeight: 500 }}>Created:</span> {FormatDate(info.approval.createdAt)}
                  </Typography>
                </Grid>
                {info.approval.dueAt && (
                  <Grid container item alignItems="center" style={{ marginBottom: 12 }}>
                    <Typography>
                      <span style={{ fontWeight: 500 }}>Due:</span>{' '}
                      <span
                        style={{
                          fontWeight: !info.approval.decidedAt && moment(info.approval.dueAt) < moment() ? 500 : 400,
                          color:
                            !info.approval.decidedAt && moment(info.approval.dueAt) < moment() ? red[600] : undefined,
                        }}
                      >
                        {FormatDate(info.approval.dueAt)}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {!info.approval.decidedAt && (
                  <Grid container item alignItems="center" style={{ marginBottom: 12 }}>
                    <GeneralButton
                      disabled={saving}
                      startIcon={<Schedule style={{ color: grey[500] }} />}
                      onClick={() => setApprovalDueDialog(true)}
                    >
                      {info.approval.dueAt ? 'Change' : 'Add'} due date
                    </GeneralButton>
                    <StepDueDate
                      initial={info.approval.dueAt}
                      open={approvalDueDialog}
                      onClose={() => setApprovalDueDialog(false)}
                      onResult={saveApprovalDueDate}
                      title="Select due date for the approval"
                    />
                  </Grid>
                )}

                {info.plannedApprovalTime && (
                  <Grid container item style={{ marginBottom: 12 }}>
                    <Typography>
                      <span style={{ fontWeight: 500 }}>Planned time:</span>
                      {'   '}
                      {`${info.plannedApprovalTime.days}d ${info.plannedApprovalTime.hours || 0}h`}
                    </Typography>
                  </Grid>
                )}
                {info.approval.decidedAt && (
                  <Grid container item alignItems="center" style={{ marginBottom: 12 }}>
                    <Typography>
                      {' '}
                      <span style={{ fontWeight: 500 }}>Completed:</span>
                      {'   '} {FormatDate(info.approval.decidedAt)}
                    </Typography>
                  </Grid>
                )}
                {info.approval.dueAt && info.approval.decidedAt && (
                  <Grid container item alignItems="center" style={{ marginBottom: 12 }}>
                    {(() => {
                      const delta = calculateTimeDelta(info.approval.decidedAt, info.approval.dueAt);
                      return (
                        <Typography>
                          <span style={{ fontWeight: 500 }}>Time delta:</span>
                          {'   '}
                          <span style={{ color: delta > 0 ? green[600] : delta < 0 ? red[600] : grey[600] }}>
                            {FormatDuration(delta)}
                          </span>
                        </Typography>
                      );
                    })()}
                  </Grid>
                )}
              </Grid>
            )}

            <Grid container item sx={{ py: 1, borderBottom: `1px solid ${grey[200]}`, mb: 1 }}>
              <Grid container item>
                <Typography color="textSecondary" gutterBottom>
                  {`${FormatDate(info.createdAt)}:`}
                </Typography>
              </Grid>
              <Grid container item alignItems="center">
                <Typography gutterBottom variant="subtitle1" style={{ marginRight: 16 }}>
                  Sent by
                </Typography>
                <User id={info.startedBy} />
              </Grid>
            </Grid>

            {info.history &&
              info.history.map((record) => (
                <Grid container item sx={{ py: 1, borderBottom: `1px solid ${grey[200]}`, mb: 1 }}>
                  <Grid container item>
                    <Typography color="textSecondary" gutterBottom>
                      {`${FormatDate(record.createdAt)}:`}
                    </Typography>
                  </Grid>
                  {record.type === 'ownerChange' && (
                    <Grid container item>
                      <Grid container item>
                        <Typography gutterBottom variant="subtitle1">
                          Step owner changed:
                        </Typography>
                      </Grid>
                      <Grid container item alignItems="center" wrap="nowrap">
                        <UserGroup
                          ids={Array.isArray(record.value.from) ? record.value.from : [record.value.from]}
                          max={6}
                        />
                        <ArrowForward style={{ color: grey[500], margin: '0 0.5rem' }} />
                        <UserGroup ids={Array.isArray(record.value.to) ? record.value.to : [record.value.to]} max={6} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ))}

            {info.completedAt && (
              <Grid container item sx={{ py: 1, borderBottom: `1px solid ${grey[200]}`, mb: 1 }}>
                <Grid container item>
                  <Typography color="textSecondary" gutterBottom>
                    {`${FormatDate(info.completedAt)}:`}
                  </Typography>
                </Grid>
                <Grid container item alignItems="center">
                  <Typography variant="subtitle1" gutterBottom style={{ marginRight: 16 }}>
                    Completed by
                  </Typography>
                  <User id={info.completedBy} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} saving={saving} />
    </RoundedDialog>
  );
}
