import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, TextField, Typography, Collapse } from '@mui/material';
import { InputAdornment, CircularProgress, Checkbox, Button, Switch } from '@mui/material';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, ListItem } from '@mui/material';
import { SearchRounded, CheckCircleRounded, NotificationsActiveRounded } from '@mui/icons-material';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, TaskParams, HtmlContent, RichTextField } from '../Components';
import { checkFields } from '../Functions';
import { grey, green, red, blue } from '@mui/material/colors';
import { FormIcon, ProcessIcon } from '../Icons';
import axios from 'axios';
import StateManager from '../StateManager';

export default function ActionDialog({ initial, open, onClose, onResult }) {
  const [pattern, setPattern] = useState('');
  const [action, setAction] = useState(null);
  const [actionOptional, setActionOptional] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [task, setTask] = useState(null);

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const filteredForms = forms.filter((form) => !pattern || form.title.search(new RegExp(pattern, 'i')) > -1);
  const loadingForms = open && action === 'form' && forms.length === 0;

  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const filteredProcesses = processes.filter((proc) => !pattern || proc.title.search(new RegExp(pattern, 'i')) > -1);
  const loadingProcesses = open && action === 'process' && processes.length === 0;

  useEffect(() => {
    if (!loadingForms) return;
    axios
      .get('/forms/search/getSearchForms')
      .then((res) => {
        setForms(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadingForms]);

  useEffect(() => {
    if (!loadingProcesses) return;
    axios
      .get('/process/search/getSearchProcesses')
      .then((res) => {
        setProcesses(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadingProcesses]);

  useEffect(() => {
    if (!open) return;
    setPattern('');
    setAction(initial?.action || '');
    setTask(initial?.task || null);
    setSelectedForm(initial?.form || null);
    setSelectedProcess(initial?.process || null);
    setActionOptional(initial?.actionOptional || false);
    setReminder(initial?.reminder || null);
  }, [open, initial]);

  const optionChosen =
    (action === 'task' && task?.title) ||
    (action === 'form' && selectedForm?._id) ||
    (action === 'process' && selectedProcess?._id);

  function done() {
    if (typeof onResult !== 'function') return;

    if (!optionChosen) return;

    let result = null;

    if (action === 'task') {
      const check = checkFields(task.fields);
      if (check.error) {
        StateManager.setErrorAlert(check.error);
        return;
      }
      result = { action, task, actionOptional };
    }
    if (action === 'form')
      result = { action, form: { _id: selectedForm._id, title: selectedForm.title }, actionOptional, reminder };
    if (action === 'process')
      result = { action, process: { _id: selectedProcess._id, title: selectedProcess.title }, actionOptional };

    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      maxWidth={action === 'form' || action === 'process' ? 'sm' : 'md'}
      className="scroll-bar"
      fullWidth
    >
      <DialogTitle>Action</DialogTitle>
      <DialogContent>
        <Grid container item>
          <Grid container item direction="column" style={{ paddingBottom: 8 }}>
            <Grid container item>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select action</FormLabel>
                <RadioGroup value={action} onChange={(e) => setAction(e.target.value)} row>
                  <FormControlLabel value={'task'} label={'Task'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'form'} label={'Form'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'process'} label={'Process'} control={<Radio color="primary" />} />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid container item justifyContent="space-between" style={{ marginTop: 8 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={actionOptional}
                    onClick={(e) => setActionOptional(e.target.checked)}
                  />
                }
                label="Make action optional"
              />

              {action === 'form' && (
                <Button
                  onClick={() => setReminderDialog(true)}
                  style={{ color: reminder ? blue[900] : grey[600], borderRadius: 8 }}
                  startIcon={<NotificationsActiveRounded />}
                >
                  {reminder ? 'Change reminder' : 'Set reminder'}
                </Button>
              )}

              <ReminderDialog
                initial={reminder}
                onClose={() => setReminderDialog(false)}
                open={reminderDialog}
                onResult={setReminder}
              />
            </Grid>
          </Grid>

          {action === 'task' && (
            <Grid container item style={{ padding: '8px 0' }}>
              <TaskParams initial={task} onResult={setTask} />
            </Grid>
          )}
          {action === 'form' && (
            <Grid container>
              <Grid container>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                  }}
                  value={pattern}
                  fullWidth
                  variant="outlined"
                  label="Search for a form"
                  onChange={(e) => setPattern(e.target.value)}
                />
              </Grid>

              {loadingForms && (
                <Grid container style={{ marginTop: '1rem', height: '30vh' }} alignContent="center" alignItems="center">
                  <CircularProgress color="primary" size={25} />
                </Grid>
              )}

              {!forms[0] && !loadingForms && (
                <Grid container style={{ marginTop: '1rem', height: '3rem' }}>
                  <Typography variant="h6" color="textSecondary">
                    You don't have any forms yet :)
                  </Typography>
                </Grid>
              )}

              <Grid container style={{ padding: 8 }}>
                You selected: {selectedForm ? selectedForm.title : '-'}
              </Grid>

              {forms[0] && (
                <Grid container style={{ height: '40vh', overflow: 'auto' }} alignContent="flex-start">
                  {filteredForms.map((form, index) => (
                    <ListItem
                      key={index}
                      button
                      style={{
                        width: '100%',
                        borderRadius: 5,
                      }}
                      onClick={() => setSelectedForm(form)}
                    >
                      <Grid container alignItems="center" wrap="nowrap">
                        <FormIcon />
                        <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                          {form.title}
                        </Typography>
                        {form._id === selectedForm?._id && (
                          <CheckCircleRounded style={{ color: green[500], marginLeft: 'auto' }} />
                        )}
                      </Grid>
                    </ListItem>
                  ))}
                  {forms.length > 0 && filteredForms.length === 0 && (
                    <Grid container style={{ marginTop: '1rem', height: '3rem' }}>
                      <Typography variant="h6" color="textSecondary">
                        No forms match your search
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
          {action === 'process' && (
            <Grid container>
              <Grid container>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                  }}
                  value={pattern}
                  fullWidth
                  variant="outlined"
                  label="Search for a process"
                  onChange={(e) => setPattern(e.target.value)}
                />
              </Grid>

              {loadingProcesses && (
                <Grid container style={{ marginTop: '1rem', height: '30vh' }} alignContent="center" alignItems="center">
                  <CircularProgress color="primary" size={25} />
                </Grid>
              )}

              {!processes[0] && !loadingProcesses && (
                <Grid container style={{ marginTop: '1rem', height: '3rem' }}>
                  <Typography variant="h6" color="textSecondary">
                    You don't have any processes yet :)
                  </Typography>
                </Grid>
              )}
              <Grid container style={{ padding: 8 }}>
                You selected: {selectedProcess ? selectedProcess.title : '-'}
              </Grid>

              {processes[0] && (
                <Grid container style={{ height: '40vh', overflow: 'auto' }} alignContent="flex-start">
                  {filteredProcesses.map((process, index) => (
                    <ListItem
                      key={index}
                      button
                      style={{ width: '100%', borderRadius: 5 }}
                      onClick={() => setSelectedProcess(process)}
                    >
                      <Grid container alignItems="center" wrap="nowrap">
                        <ProcessIcon />
                        <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                          {process.title}
                        </Typography>
                        {process._id === selectedProcess?._id && (
                          <CheckCircleRounded style={{ color: green[500], marginLeft: 'auto' }} />
                        )}
                      </Grid>
                    </ListItem>
                  ))}
                  {processes.length > 0 && filteredProcesses.length === 0 && (
                    <Grid container style={{ marginTop: '1rem', height: '3rem' }}>
                      <Typography variant="h6" color="textSecondary">
                        No processes match your search
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions closeButtonText={'Cancel'} onClose={onClose} onDone={done} hideDone={!optionChosen} />
    </RoundedDialog>
  );
}

function ReminderDialog({ initial, open, onClose, onResult }) {
  const [days, setDays] = useState('');
  const [customEmail, setCustomEmail] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');

  useEffect(() => {
    setDays(initial?.interval != null ? initial.interval : '');
    setCustomEmail(initial?.customEmail != null ? initial.customEmail : '');
    setEmailSubject(initial?.email?.subject || '');
    setEmailBody(initial?.email?.body || '');
  }, [initial]);

  function deleteReminder() {
    onResult(null);
    onClose();
  }

  function done() {
    if (customEmail && (!emailSubject || !emailBody)) {
      StateManager.setErrorAlert('Provide email subject and body');
      return;
    }
    if (days === '' || days <= 0) {
      StateManager.setErrorAlert('Provide reminder inteval');
      return;
    }
    const result = {
      interval: days,
      customEmail,
      email: {
        subject: emailSubject,
        body: emailBody,
      },
    };
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set up reminder</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid container item alignItems="baseline">
            <Typography>Send reminder every</Typography>
            <TextField
              label="Days"
              type="number"
              value={days}
              onChange={(e) => {
                const value = e.target.value === '' ? '' : Number(e.target.value);
                if (value !== '' && value < 0) return;
                setDays(value);
              }}
              onWheel={(e) => e.target.blur()}
              style={{ margin: '0 0.75rem' }}
            />
            <Typography>until action is complete</Typography>
          </Grid>
          <Grid container item alignItems="baseline">
            <FormControlLabel
              control={
                <Switch color="primary" checked={customEmail} onClick={(e) => setCustomEmail(e.target.checked)} />
              }
              label="Use custom email"
            />
          </Grid>
          <Collapse in={customEmail} style={{ width: '100%' }}>
            <Grid container style={{ marginTop: 16 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </Grid>
            <Grid container>
              <RichTextField initial={emailBody} editable onChange={setEmailBody} placeholder="Email text" />
            </Grid>
          </Collapse>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          initial ? (
            <Button
              startIcon={<DeleteOutlineRounded />}
              style={{ marginRight: 'auto', color: red[500] }}
              onClick={deleteReminder}
            >
              delete reminder
            </Button>
          ) : null
        }
        onClose={onClose}
        onDone={done}
        hideDone={days === ''}
      />
    </RoundedDialog>
  );
}
