import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { zeroPad } from '../../utils';

const separatorMap = {
  '-': '-',
  _: '_',
  '/': '/',
  '.': '.',
  Space: ' ',
  None: '',
};

const DocumentName = ({ setId, sequence, initial, title }) => {
  const { sets } = useSelector(({ sets }) => sets);
  const { conventionSets } = useSelector(({ settings }) => settings);

  const [fields, setFields] = useState([]);
  const [params, setParams] = useState([]);
  const [separator, setSeparator] = useState('_');

  useEffect(() => {
    if (!initial) return;
    setParams(initial || []);
  }, [initial]);

  useEffect(() => {
    if (!sets) return;
    const set = sets.find((set) => set._id === setId);
    setFields(set?.fields || []);
    setSeparator(set?.separator || '_');
  }, [setId, sets]);

  if (!conventionSets) return title;

  if (!setId) return title;

  if (!fields || fields.length === 0) return title;

  const getField = (field) => {
    const value = params?.find((x) => x.id === field.id)?.value;
    const fieldColor = `#${field?.color || '000'}`;
    if (field.fieldType === 'autonumber') {
      if (!sequence) {
        return '0';
      }
      return (
        <Box
          component="span"
          sx={{
            color: (t) => (t.palette.mode === 'dark' ? (fieldColor === '#000' ? '#fff' : fieldColor) : fieldColor),
          }}
        >
          {zeroPad(sequence, field?.prefix?.length + 1)}
        </Box>
      );
    }
    if (field.fieldType === 'text') {
      return (
        <Box
          component="span"
          sx={{
            color: (t) => (t.palette.mode === 'dark' ? (fieldColor === '#000' ? '#fff' : fieldColor) : fieldColor),
          }}
        >
          {field.title}
        </Box>
      );
    }
    if (field.fieldType === 'number') {
      return (
        <Box
          component="span"
          sx={{
            color: (t) => (t.palette.mode === 'dark' ? (fieldColor === '#000' ? '#fff' : fieldColor) : fieldColor),
          }}
        >
          {value ? zeroPad(value, field?.prefix?.length + 1) : '~'}
        </Box>
      );
    }
    return (
      <Box
        component="span"
        sx={{ color: (t) => (t.palette.mode === 'dark' ? (fieldColor === '#000' ? '#fff' : fieldColor) : fieldColor) }}
      >
        {value ? value?.toString() : '~'}
      </Box>
    );
  };
  return fields?.map((field, index) => {
    return (
      <React.Fragment key={field.id}>
        {getField(field)}
        {index !== fields?.length - 1 && separatorMap[separator]}
      </React.Fragment>
    );
  });
};

export default DocumentName;
