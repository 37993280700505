import React, { useMemo, useState, useEffect } from 'react';
import { DataGridPremium, GridActionsCellItem } from '@mui/x-data-grid-premium';
import { Box, Typography } from '@mui/material';
import StateManager from '../../../../../../Global/StateManager';
import { User } from '../../../../../../Global/Components';
import axios from 'axios';
import { DateTimeCellRenderer } from '../../../AdvanceTable/cell';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ReviewHistoryTable = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = useMemo(
    () => [
      {
        field: 'completedAt',
        headerName: 'Review Date',
        width: 150,
        display: 'flex',
        valueFormatter: (value) => {
          if (!value) return null;
          return new Date(value);
        },
        type: 'date',
        renderCell: (params) => {
          let value = params?.formattedValue ?? params?.value;
          return <DateTimeCellRenderer params={value} format={'DD/MM/YYYY'} />;
        },
      },
      {
        field: 'created_by',
        headerName: 'Reviewer',
        width: 150,
        display: 'flex',
        renderCell: (params) => <User id={params?.value} avatarSize={16} />,
      },
      { field: 'outcome', headerName: 'Outcome', display: 'flex', width: 150 },
      {
        field: 'actions',
        type: 'actions',
        width: 30,
        display: 'flex',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={() => {
              StateManager.openReviewDialog(params.row.id);
            }}
          />,
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axios
      .post(`/dochub/reviews/getCompletedReviews`, { id })
      .then((response) => {
        setRows(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [id]);

  return (
    <Box style={{ width: '100%' }}>
      <DataGridPremium
        rows={rows}
        columns={columns}
        density="compact"
        autoHeight
        initialState={{ density: 'compact' }}
        onRowClick={(params) => StateManager.openReviewDialog(params.row.id)}
        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                No review history available
              </Typography>
            </Box>
          ),
          footerRowCount: () => null,
          footer: () => null,
        }}
        disableAggregation
        disableRowGrouping
        loading={loading}
        sx={{ mt: 1 }}
      />
    </Box>
  );
};

export default ReviewHistoryTable;
