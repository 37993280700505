import React from 'react';

import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

const SectionPreview = React.forwardRef(({ title, headingStyle, isPageBreak, children }, ref) => {
  const fontSize = `var(--${headingStyle}-size)`;

  if (isPageBreak) {
    return (
      <Box
        ref={ref}
        sx={{
          my: 2,
          width: '100%',
        }}
      >
        <hr />
      </Box>
    );
  }

  return (
    <Box
      ref={ref}
      sx={{
        my: 2,
        width: '100%',
      }}
    >
      {!isEmpty(title) && (
        <Typography
          gutterBottom
          sx={{
            mb: 0.5,
            fontSize,
            fontWeight: headingStyle !== 'h6' ? 'bold' : 'normal',
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
});

export default SectionPreview;
