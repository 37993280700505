import React, { useState, useEffect, useMemo } from 'react';
import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';
import { isArray, difference, isEmpty, isEqual, toNumber, cloneDeep } from 'lodash';
import GridCell from './GridCell';
import axios from 'axios';
import { Grid, Fab, IconButton, Typography, Button, Paper } from '@mui/material';
import { AddRounded, DeleteOutlineRounded, CurrencyPoundRounded } from '@mui/icons-material';
import { AttachMoneyRounded, EuroRounded, FunctionsRounded, CloseRounded } from '@mui/icons-material';
import { v4 } from 'uuid';
import { red } from '@mui/material/colors';
import { arrayMoveImmutable } from 'array-move';
import Header from '../../Hubs/registers/components/RegisterDataGrid/Header';
import moment from 'moment';
import StateManager from '../StateManager';

const DEFAULT_WIDTH = 250;

const sortableTypes = [
  'number',
  'stock',
  'calculation',
  'text',
  'date',
  'time',
  'datetime',
  'textArea',
  'phone',
  'email',
  'status',
  'weightedList',
  'dropbox',
  'conditional',
  'tickbox',
  'file',
  'image',
  'dataSet',
  'people',
];

function getMuiColumnType(column) {
  if (['number', 'stock'].includes(column.fieldType)) {
    return 'number';
  }

  if (column.fieldType === 'calculation') {
    return column.expressionParams?.format === 'date' ? 'date' : 'number';
  }

  if (['date', 'datetime'].includes(column.fieldType)) {
    return 'date';
  }

  return 'text';
}

function getObjectType(obj) {
  if (String(obj).match(/^-?\d+$/) && !isNaN(obj)) return 'number';
  const date = moment(obj);
  if (date.isValid()) {
    return 'date';
  }

  return 'text';
}

function getUtcDate(value) {
  if (value == null) return null;

  return moment(value).toDate();
}

function getTextValue(row, column) {
  if (row.id === 'summary') return null;
  const value = row[column.id]?.value;
  const type = column.fieldType;
  if (!value) return '';

  if (['text', 'textArea', 'phone', 'email'].includes(type)) {
    return String(value);
  }

  if (['number', 'stock'].includes(type)) {
    return toNumber(value);
  }

  if (type === 'calculation') {
    const realType = getObjectType(value);
    return realType === 'date' ? getUtcDate(value) : toNumber(value);
  }

  if (type === 'date') {
    return getUtcDate(value);
  }

  if (type === 'datetime') {
    const date = moment(value, true);
    return date.isValid() ? getUtcDate(value) : null;
  }

  if (['status', 'weightedList', 'dropbox'].includes(type)) {
    return column.options.find((o) => o.id === value)?.text;
  }

  if (type === 'tickbox' && isArray(value)) {
    return value.map((x) => column.options.find((o) => o.id === x)?.text).join('\r\n');
  }

  if (['file', 'image'].includes(type)) {
    const names = isArray(value) ? value.map((x) => x.originalname).join('\r\n') : '';
    return names;
  }

  if (type === 'dataSet') {
    if (
      !isArray(column.dataSetParams?.columns) ||
      isEmpty(column.dataSetParams.columns) ||
      !isArray(row[column.id]?.rows) ||
      isEmpty(row[column.id].rows)
    ) {
      return '';
    }

    const str = row[column.id]?.rows
      .map((row) => column.dataSetParams.columns.map((col) => row[col.id]).join(', '))
      .join('\r\n');

    return str;
  }

  return '';
}

export default function FieldDataGrid({
  columns,
  initial,
  onChange,
  editable,
  activityInfo,
  allCreatedActions,
  onCreatedAction,
  onDeletedAction,
  forceUpdate,
  hideCalculations,
  gridHeight = '30vh',
  maxHeight = '75vh',
  autoRowHeight,
  textWrapping,
}) {
  const [tableColumns, setTableColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const summary = useMemo(() => {
    if (!isArray(columns)) return null;
    if (isEmpty(rows)) return null;
    if (isEmpty(columns)) return null;

    const result = { id: 'summary' };

    let hasAnyTotal = false;
    for (let i = 0; i < columns.length; ++i) {
      let total = 0;
      let hasTotal = false;
      if (
        ['number', 'calculation'].includes(columns[i].fieldType) &&
        !['date', 'time'].includes(columns[i].expressionParams?.format)
      ) {
        for (let j = 0; j < rows.length; ++j) {
          const value = rows[j][columns[i].id]?.value;
          if (!isNumeric(value)) continue;
          hasTotal = true;
          hasAnyTotal = true;
          total += toNumber(value);
        }
      }

      result[columns[i].id] = { total, hasTotal };
    }

    if (!hasAnyTotal) return null;

    return result;
  }, [rows, columns]); // eslint-disable-line

  const dataGridColumns = useMemo(
    () => {
      if (!isArray(tableColumns)) return [];

      const currentRefCount = tableColumns.filter((x) => x.isReference).length;
      const MAX_REF = 3;
      const refLimitReached = currentRefCount >= MAX_REF;

      const muiColumns = tableColumns.map((column) => ({
        headerName: column.title,
        renderHeader: () => <Header column={column} />,
        field: column.id,
        width: column.width || DEFAULT_WIDTH,
        fieldType: column.fieldType,
        refLimitReached,
        isReference: column.isReference,
        sortable: sortableTypes.includes(column.fieldType),
        filterable: sortableTypes.includes(column.fieldType),
        type: getMuiColumnType(column),
        pinnable: false,
        display: 'flex',
        renderCell: ({ row }) => {
          const rowEditable = !!editable && !row.initial;
          const cellData = row[column.id];
          const cellEditable =
            !!editable && (column.fieldType === 'status' || !cellData?.value || cellData?.filled === true);
          return row.id === 'summary' ? (
            <SummaryCell column={column} row={row} />
          ) : (
            <GridCell
              column={column}
              initial={column.fieldType === 'calculation' && hideCalculations ? null : cellData}
              editable={(rowEditable || cellEditable) && !column.setAutomatically}
              onChange={(value) => handleCellchange({ ...value, filled: true }, row.id, column.id)}
              withTimeout
              row={row}
              textAlign={column.textAlign}
              onDataChange={() => {}}
              activityInfo={activityInfo}
              allCreatedActions={allCreatedActions}
              onCreatedAction={onCreatedAction}
              onDeletedAction={onDeletedAction}
              textWrapping={textWrapping}
            />
          );
        },

        valueGetter: (value) => {
          if (value) {
            return getTextValue(value, column);
          }
        },
      }));

      const actionColumn = {
        field: 'actions',
        type: 'actions',
        resizable: false,
        width: 64,
        renderCell: ({ row }) =>
          row.initial || row.id === 'summary' ? null : (
            <IconButton onClick={() => deleteRows([row.id])}>
              <DeleteOutlineRounded sx={{ color: red[500] }} />
            </IconButton>
          ),
      };

      if (editable) {
        muiColumns.push({ ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 50 }, actionColumn);
      }

      return muiColumns;
    },
    // eslint-disable-next-line
    [
      tableColumns,
      rows,
      editable,
      activityInfo,
      allCreatedActions,
      onCreatedAction,
      onDeletedAction,
      hideCalculations,
      summary,
    ],
  );

  useEffect(() => {
    setTableColumns(isArray(columns) ? columns : []);
  }, [columns]);

  function isNumeric(str) {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  useEffect(() => {
    if (!isArray(initial) || !isArray(columns) || isEqual(initial, rows)) return;
    if (isEmpty(rows) || forceUpdate) {
      setRows(cloneDeep(initial));
    } else {
      // if we have rows already that means some calculation or status columns changed, so we want to update only them
      const columnsToUpdate = columns.filter(({ fieldType }) => ['status', 'calculation'].includes(fieldType));
      if (isEmpty(columnsToUpdate)) return;
      let updated = false;
      for (let i = 0; i < initial.length; ++i) {
        const rowIndex = rows.findIndex(({ id }) => id === initial[i].id);
        if (rowIndex === -1) continue;
        for (let j = 0; j < columnsToUpdate.length; ++j) {
          if (rows[rowIndex][columnsToUpdate[j].id] === initial[i][columnsToUpdate[j].id]) continue;
          updated = true;
          rows[rowIndex][columnsToUpdate[j].id] = initial[i][columnsToUpdate[j].id];
        }
      }
      if (updated) {
        setRows([...rows]);
      }
    }
  }, [initial, columns, forceUpdate]); // eslint-disable-line

  function deleteRows(ids) {
    const filtered = rows.filter((x) => !ids.includes(x.id));
    setRows(filtered);
    onChange(filtered);
  }

  function defaultRow() {
    const id = v4();
    const row = { id, index: rows.length + 1, data: { id } };
    tableColumns.forEach((x) => (row[x.dataKey] = { value: null }));
    return row;
  }

  function addRow() {
    const row = defaultRow();

    rows.push(row);
    setRows([...rows]);
    onChange(rows);
  }

  function handleCellchange(value, rowId, columnId) {
    const index = rows.findIndex((x) => x.id === rowId);
    if (index === -1) return;
    rows[index][columnId] = value;
    setRows([...rows]);
    onChange(rows);
  }

  function reorderRows(event) {
    const { targetIndex, oldIndex } = event;
    const newRows = arrayMoveImmutable(rows, oldIndex, targetIndex).map((row, index) => ({ ...row, index }));
    setRows(newRows);
    onChange(newRows);
  }

  function confirmDeleteSelected() {
    if (!editable) return;

    StateManager.setConfirm(
      `You are about to delete ${selectedRows.length} row${selectedRows.length === 1 ? '' : 's'}`,
      () => deleteRows(selectedRows),
    );
  }

  const mainRows = useMemo(() => rows.map((row) => ({ ...row, __reorder__: 'Drag to reorder' })), [rows]);

  return (
    <Grid container sx={{ position: 'relative' }}>
      <Grid container items sx={{ minHeight: gridHeight, maxHeight }}>
        <DataGridPremium
          disableAggregation
          disableRowGrouping
          getRowHeight={() => (autoRowHeight ? 'auto' : null)}
          disableRowSelectionOnClick
          rows={mainRows}
          columns={dataGridColumns}
          pinnedColumns={{
            right: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'],
          }}
          rowReordering={editable}
          onRowOrderChange={reorderRows}
          hideFooter
          checkboxSelection={editable}
          isRowSelectable={({ row }) => !row.initial}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={setSelectedRows}
          pinnedRows={{ bottom: summary ? [summary] : [] }}
          disableColumnResize
        />
      </Grid>

      {editable && isEmpty(selectedRows) && (
        <Grid
          container
          item
          style={{ position: 'absolute', bottom: 20, width: 'fit-content', right: 80 }}
          justifyContent="flex-end"
        >
          <Fab color="primary" onClick={addRow}>
            <AddRounded fontSize="large" />
          </Fab>
        </Grid>
      )}

      {!isEmpty(selectedRows) && editable && (
        <Grid
          container
          item
          style={{ position: 'absolute', bottom: 20, width: 'fit-content', right: 80, zIndex: 100 }}
          justifyContent="flex-end"
        >
          <Paper style={{ padding: 16, borderRadius: 16 }} elevation={4}>
            <Grid container item alignItems="center">
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                {selectedRows.length} row{selectedRows.length === 1 ? '' : 's'} selected
              </Typography>
              <Button
                startIcon={<DeleteOutlineRounded />}
                style={{ marginLeft: 15, color: red[500] }}
                onClick={confirmDeleteSelected}
                size="small"
              >
                delete
              </Button>
              <Button
                startIcon={<CloseRounded />}
                style={{ marginLeft: 15, color: red[500] }}
                onClick={() => setSelectedRows([])}
                size="small"
              >
                cancel
              </Button>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

function SummaryCell({ row, column }) {
  if (!row[column.id].hasTotal) return null;

  return (
    <Grid container>
      {column.expressionParams?.format === 'pound' ? (
        <CurrencyPoundRounded color="textSecondary" />
      ) : column.expressionParams?.format === 'dollar' ? (
        <AttachMoneyRounded color="textSecondary" />
      ) : column.expressionParams?.format === 'euro' ? (
        <EuroRounded color="textSecondary" />
      ) : (
        <FunctionsRounded color="textSecondary" />
      )}
      <Typography>{row[column.id].total}</Typography>
    </Grid>
  );
}
