import React, { useState, useEffect, useMemo, useRef } from 'react';

import { yellow } from '@mui/material/colors';
import { Box, useTheme } from '@mui/material';

import EditorInstance from '../Editor/preview';
import ImagePreview from '../ImageBlock/preview';
import { FieldDataGrid } from '../../../../../../Global/Fields';
import { RichTextField } from '../../../../../../Global/Components';
import { formatTOCTitles } from '../TOC/function';
import { TOC } from '../TOC/drawer';
import LegacyTOC from '../TOC';
import SectionPreview from '../Section/preview';
import ChangeHistoryTable from '../Tables/change-history';
import ReviewHistoryTable from '../Tables/review-history';
import SignoffHistoryTable from '../Tables/signoff-history';
import PlaceholderTable from '../Tables/placeholder';

export default function SectionsPreview({
  initial,
  documentId,
  isDocument,
  hasToc,
  search,
  setSearch,
  sectionNumbering = true,
}) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const highlightColor = themeMode === 'light' ? yellow[100] : yellow[800];
  const sectionRefs = useRef({});
  const [sections, setSections] = useState([]);
  const headings = useMemo(() => formatTOCTitles(sections, sectionNumbering), [sections, sectionNumbering]);

  const isOldQDoc = sections?.filter((x) => x.type === 'editor')?.length > 0;
  const isNewQDoc = sections?.filter((x) => x.type === 'editor')?.length === 0;

  const blocks = sections
    ?.filter((x) => x.type === 'editor')
    ?.map((x) => x?.value?.blocks)
    ?.flat();

  useEffect(() => {
    setSections(initial || []);
  }, [initial]);

  const scrollToSection = (id) => {
    const ref = sectionRefs.current[id];
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // Highlight the section
      hightlightSection(id);
    }
  };

  const hightlightSection = (id) => {
    const ref = sectionRefs.current[id];
    if (ref) {
      // add a class name
      ref.style.backgroundColor = highlightColor;
      setTimeout(() => {
        ref.style.backgroundColor = 'transparent';
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      {hasToc && isNewQDoc && <TOC sections={sections} onScroll={scrollToSection} enableNumber={sectionNumbering} />}
      {hasToc && isOldQDoc && <LegacyTOC blocks={blocks} enableNumber={sectionNumbering} />}
      {sections?.map((section, index) => {
        let title = headings?.find((x) => x.id === section.id)?.displayText;
        if (section?.title === '') {
          title = '';
        }
        if (title == undefined) {
          title = null;
        }
        const headingStyle = section?.headingStyle;
        const generalProps = {
          id: section.id,
          title,
          headingStyle,
          ref: (el) => (sectionRefs.current[section.id] = el), // Attach refs dynamically,
        };
        if (section.type === 'heading') {
          return <SectionPreview {...generalProps} key={section.id} />;
        }
        if (section.type === 'page-break') {
          return <SectionPreview {...generalProps} key={section.id} isPageBreak />;
        }
        if (section.type === 'editor') {
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <EditorInstance
                initial={section.value}
                isFirst={index === 0}
                isLast={index === sections.length - 1}
                totalSections={sections.length}
                hasToc={hasToc}
                toc={sections
                  ?.filter((x) => x.type === 'editor')
                  ?.map((x) => x?.value?.blocks)
                  ?.flat()}
                index={index}
                search={search}
                setSearch={setSearch}
              />
            </SectionPreview>
          );
        }
        if (section?.type === 'table') {
          const columns = section.value.columns;
          const rows = section.value.rows;
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <Box>
                <FieldDataGrid columns={columns} initial={rows} textWrapping={true} autoRowHeight={true} />
              </Box>
            </SectionPreview>
          );
        }
        if (section?.type === 'change-history') {
          if (isDocument) {
            return (
              <SectionPreview {...generalProps} key={section.id}>
                <ChangeHistoryTable id={documentId} />
              </SectionPreview>
            );
          }
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <PlaceholderTable type="change-history" />
            </SectionPreview>
          );
        }
        if (section?.type === 'sign-off') {
          if (isDocument) {
            return (
              <SectionPreview {...generalProps} key={section.id}>
                <SignoffHistoryTable id={documentId} />
              </SectionPreview>
            );
          }
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <PlaceholderTable type="sign-off" />
            </SectionPreview>
          );
        }
        if (section?.type === 'review-history') {
          if (isDocument) {
            return (
              <SectionPreview {...generalProps} key={section.id}>
                <ReviewHistoryTable id={documentId} />
              </SectionPreview>
            );
          }
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <PlaceholderTable type="review-history" />
            </SectionPreview>
          );
        }
        if (section?.type === 'editor-v2') {
          const value = section.value;
          if (value === null) return null;
          if (value === '') return null;
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <RichTextField initial={value} editable={false} border={false} minHeight={100} />
            </SectionPreview>
          );
        }
        if (section?.type === 'image-section') {
          const value = section.value;
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ImagePreview images={value?.data} mode={value?.layout} isCaption={value?.allowCaption} />
              </Box>
            </SectionPreview>
          );
        }
        if (section?.type === 'drawing') {
          const value = section.value;
          const image = value.image;
          const size = value.size;
          return (
            <SectionPreview {...generalProps} key={section.id}>
              <Box
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Box
                  component="img"
                  src={image}
                  alt={`drawing-${section.id}`}
                  sx={{
                    my: 2,
                    width: size === 'small' ? 250 : size === 'medium' ? 350 : 500,
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                  lazy="loading"
                />
              </Box>
            </SectionPreview>
          );
        }
        return null;
      })}
    </React.Fragment>
  );
}
