import React, { useMemo } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { v4 } from 'uuid';
import { Box, Typography, useTheme } from '@mui/material';

// Function to generate base64 signature image for a given letter
const generateBase64Signature = (name, mode) => {
  const canvas = document.createElement('canvas');
  canvas.width = 300;
  canvas.height = 100;
  const ctx = canvas.getContext('2d');

  // Set a background color for the signature canvas
  ctx.fillStyle = 'transparent';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Load custom handwriting font
  ctx.fillStyle = mode === 'dark' ? 'white' : 'black';
  ctx.font = 'italic 30px "signature", sans-serif'; // Replace "Pacifico" with your handwriting font
  ctx.textAlign = 'left';

  // Slightly randomize stroke for a natural effect
  const x = 20 + Math.random() * 4;
  const y = 60 + Math.random() * 8;

  ctx.fillText(name, x, y);

  // Return base64 signature
  return canvas.toDataURL('image/png');
};

const PlaceholderTable = ({ type = 'change-history' }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const historyColumns = useMemo(
    () => [
      { field: 'version', headerName: 'Version', display: 'flex', width: 90 },
      { field: 'issuedBy', headerName: 'Issued By', display: 'flex', width: 150 },
      { field: 'reason', headerName: 'Reason for Change', display: 'flex', width: 150 },
      { field: 'publishedAt', headerName: 'Release Date', display: 'flex', width: 150 },
    ],
    [],
  );

  const reviewColumns = useMemo(
    () => [
      { field: 'reviewDate', headerName: 'Review Date', display: 'flex', width: 150 },
      { field: 'reviewer', headerName: 'Reviewer', display: 'flex', width: 150 },
      { field: 'outcome', headerName: 'Review outcome', display: 'flex', width: 150 },
    ],
    [],
  );

  const signOffColumns = useMemo(
    () => [
      { field: 'role', headerName: 'Role', display: 'flex', width: 90 },
      { field: 'name', headerName: 'Name', display: 'flex', width: 150 },
      {
        field: 'signature',
        headerName: 'Signature',
        width: 150,
        display: 'flex',
        renderCell: (params) => {
          const signature = params.value;
          return signature ? (
            <Box component="img" src={signature} alt="Signature" variant="square" sx={{ width: 150, height: 50 }} />
          ) : (
            <span>No Signature</span>
          );
        },
      },
      { field: 'signedAt', headerName: 'Date', display: 'flex', width: 150 },
    ],
    [],
  );

  const columns = useMemo(() => {
    switch (type) {
      case 'change-history':
        return historyColumns;
      case 'review-history':
        return reviewColumns;
      case 'sign-off':
        return signOffColumns;
      default:
        return [];
    }
  }, [type, historyColumns, reviewColumns, signOffColumns]);

  const historyRows = useMemo(
    () => [
      {
        id: v4(),
        version: '1',
        issuedBy: 'John Doe',
        reason: 'Initial Draft',
        publishedAt: '2021-10-10',
      },
      {
        id: v4(),
        version: '2',
        issuedBy: 'Jane Doe',
        reason: 'Fixed typos',
        publishedAt: '2021-10-11',
      },
      {
        id: v4(),
        version: '3',
        issuedBy: 'John Doe',
        reason: 'Added new section',
        publishedAt: '2021-10-12',
      },
      {
        id: v4(),
        version: '4',
        issuedBy: 'Jane Doe',
        reason: 'Fixed typos',
        publishedAt: '2021-10-13',
      },
      {
        id: v4(),
        version: '5',
        issuedBy: 'John Doe',
        reason: 'Added new section',
        publishedAt: '2021-10-14',
      },
    ],
    [],
  );

  const reviewHistoryRows = useMemo(
    () => [
      {
        id: v4(),
        reviewDate: '2021-10-10',
        reviewer: 'John Doe',
        outcome: 'Approved',
      },
      {
        id: v4(),
        reviewDate: '2021-10-11',
        reviewer: 'Jane Doe',
        outcome: 'Approved',
      },
      {
        id: v4(),
        reviewDate: '2021-10-12',
        reviewer: 'John Doe',
        outcome: 'Approved',
      },
      {
        id: v4(),
        reviewDate: '2021-10-13',
        reviewer: 'Jane Doe',
        outcome: 'Approved',
      },
      {
        id: v4(),
        reviewDate: '2021-10-14',
        reviewer: 'John Doe',
        outcome: 'Approved',
      },
    ],
    [],
  );

  const signOffRows = useMemo(
    () => [
      {
        id: v4(),
        role: 'Editor',
        name: 'Clark Kent',
        signature: generateBase64Signature('Clark Kent', mode),
        signedAt: '2024-11-20',
      },
      {
        id: v4(),
        role: 'Editor',
        name: 'Arthur Curry',
        signature: generateBase64Signature('Arther Curry', mode),
        signedAt: '2024-11-25',
      },
      {
        id: v4(),
        role: 'Reviewer',
        name: 'Barry Allen',
        signature: generateBase64Signature('Barry Allen', mode),
        signedAt: '2024-11-30',
      },
      {
        id: v4(),
        role: 'Reviewer',
        name: 'Bruce Wayne',
        signature: generateBase64Signature('Bruce Wayne', mode),
        signedAt: '2024-11-30',
      },
      {
        id: v4(),
        role: 'Approver',
        name: 'Diana Prince',
        signature: generateBase64Signature('Dianna Prince', mode),
        signedAt: '2024-12-01',
      },
    ],
    [mode],
  );

  const rows = useMemo(() => {
    switch (type) {
      case 'change-history':
        return historyRows;
      case 'review-history':
        return reviewHistoryRows;
      case 'sign-off':
        return signOffRows;
      default:
        return [];
    }
  }, [type, historyRows, reviewHistoryRows, signOffRows]);

  const DESCRIPTIONS = {
    'change-history':
      'This table will show the change history of the document in its current state when it is published.',
    'review-history':
      'This table will show the review history of the document in its current state when it is published.',
    'sign-off': 'This table will show the sign off history of the document for its current version.',
  };

  return (
    <Box style={{ width: '100%' }}>
      <Typography variant="body2" gutterBottom>
        {DESCRIPTIONS[type]}
      </Typography>
      <DataGridPremium
        rows={rows}
        columns={columns}
        density="compact"
        initialState={{ density: 'compact' }}
        disableAggregation
        disableRowGrouping
        disableSelectionOnClick
        slots={{
          footerRowCount: () => null,
          footer: () => null,
        }}
        autoHeight
        sx={{ mt: 1 }}
      />
    </Box>
  );
};

export default PlaceholderTable;
