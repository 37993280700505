/**
 * Utility function to format TOC titles with proper nested numbering
 * @param {Array} sections - Array of sections with title, headingStyle, and id
 * @param {boolean} enableNumber - Whether to prepend numbering to titles
 * @returns {Array} - Formatted array of TOC items
 */
export function formatTOCTitles(sections, enableNumber = true) {
  const headingNumbers = []; // Tracks the current numbering at each heading level

  const generateNumbering = (currentLevel) => {
    // Ensure `headingNumbers` has a slot for each level up to `currentLevel`
    while (headingNumbers.length < currentLevel) {
      headingNumbers.push(0); // Initialize new levels with 0
    }

    // Remove deeper levels if moving up the hierarchy
    if (headingNumbers.length > currentLevel) {
      headingNumbers.splice(currentLevel);
    }

    // Increment the current level
    headingNumbers[currentLevel - 1] += 1;

    // Return the numbering as a string (e.g., "1.2.3")
    return headingNumbers.join('.');
  };

  return sections
    .filter((section) => section.type !== 'page-break')
    .map((section) => {
      const headingStyle = section.headingStyle || 'h1';
      const currentLevel = parseInt(headingStyle?.replace('h', ''), 10);
      const sectionTitle = section.title || '';
      // Generate the hierarchical numbering for this heading
      const numbering = generateNumbering(currentLevel);
      let displayText = enableNumber ? `${numbering} ${sectionTitle}` : sectionTitle;
      if (sectionTitle === '') {
        displayText = '';
      }
      return {
        id: section.id,
        index: numbering,
        text: sectionTitle,
        headingStyle,
        key: `section-${section.id}`,
        displayText,
      };
    });
}
