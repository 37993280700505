import React, { useState, useEffect } from 'react';
import { grey, blue, yellow, amber } from '@mui/material/colors';
import { Grid, DialogTitle, DialogContent, TextField, InputAdornment, List, ListItem, useTheme } from '@mui/material';
import { CircularProgress, Typography } from '@mui/material';
import { TextFieldsRounded, InputRounded, TableChartRounded, FileCopyRounded } from '@mui/icons-material';
import { ReportProblemRounded, ListAltRounded, LabelRounded, SearchRounded, InfoRounded } from '@mui/icons-material';
import { GeneralButton, RoundedDialog, StandardDialogActions } from '../Components';
import { FormIcon, TaskIcon, ReadUnderstoodIcon, ProcessIcon } from '../Icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditField from '../Fields/EditField';
import FieldTypes from '../Fields/FieldTypes';
import { isEqual, isArray, isEmpty, uniqBy } from 'lodash';
import { v4 } from 'uuid';
import axios from 'axios';
import StateManager from '../StateManager';
import { DuplicateField } from '../Functions';

export default function FieldBuilder({
  initial,
  onChange,
  text,
  form,
  task,
  info,
  ru,
  table,
  assetIncident,
  resources,
  conditional,
  peopleEmailOptions,
  domain,
  process,
  forPortal,
  disabled,
  withGlobal,
  calculation,
  status,
  allItems,
  showConditions,
  withActionGroup,
  activityType,
  activityId,
  moveFields,
  currentlyOn,
  ...props
}) {
  const [items, setItems] = useState([]);
  const [globalFieldsDialog, setGlobalFieldsDialog] = useState(false);
  const [listDragging, setListDragging] = useState(false);
  const notNested = items.filter((x) => !x.conditionalFieldId);
  const theme = useTheme();
  const iconColor = theme.palette.mode === 'dark' ? theme.palette.common.white : grey[600];

  useEffect(() => {
    if (!isArray(initial) || isEqual(initial, items)) return;
    setItems(initial);
  }, [initial]); // eslint-disable-line

  function addGlobalField(field) {
    items.push(field);
    onChange(items);
    setItems([...items]);
  }

  function addField() {
    items.push({ id: v4(), type: 'field', title: '' });
    onChange(items);
    setItems([...items]);
  }

  function addTextItem() {
    items.push({ id: v4(), type: 'text', title: '' });
    onChange(items);
    setItems([...items]);
  }

  function addInfoItem() {
    items.push({ id: v4(), type: 'info', title: '' });
    onChange(items);
    setItems([...items]);
  }

  function addFormItem() {
    items.push({ id: v4(), type: 'form', formId: '', formName: '' });
    onChange(items);
    setItems([...items]);
  }

  function addTaskItem() {
    items.push({ id: v4(), type: 'task', formId: '', formName: '' });
    onChange(items);
    setItems([...items]);
  }

  function addRUItem() {
    items.push({ id: v4(), type: 'ru', documentId: '', documentTitle: '' });
    onChange(items);
    setItems([...items]);
  }

  function addTableItem() {
    items.push({ id: v4(), type: 'table', title: '' });
    onChange(items);
    setItems([...items]);
  }

  function addProcessItem() {
    items.push({ id: v4(), type: 'process', processId: '', processTitle: '' });
    setItems([...items]);
    onChange(items);
  }

  function addResourceItem() {
    items.push({ id: v4(), type: 'resources', title: '' });
    onChange(items);
    setItems([...items]);
  }

  function addAssetIncidentItem() {
    items.push({ id: v4(), type: 'assetIncident', title: '' });
    onChange(items);
    setItems([...items]);
  }

  function handleDuplicate(itemId) {
    const index = items.findIndex((x) => x.id === itemId);
    if (index === -1) return;
    const cloned = DuplicateField(items[index]);

    // if conditional clone all nested fields
    if (cloned.fieldType === 'conditional' && cloned.conditions) {
      for (let i = 0; i < cloned.conditions.length; ++i) {
        const newId = v4();
        // find all items for the current condition
        const condItems = items.filter((x) => x.conditionalFieldId === cloned.conditions[i].id);
        for (let j = 0; j < condItems.length; ++j) {
          const curr = DuplicateField(condItems[j]);
          curr.conditionalFieldId = newId;
          items.push(curr);
        }
        cloned.conditions[i].id = newId;
      }
    }

    items.push(cloned);
    onChange(items);
    setItems([...items]);
  }

  function onDragEnd(result) {
    setListDragging(false);
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    let list = [];
    if (result.type === 'fields') {
      list = items.filter((x) => !x.conditionalFieldId);
    } else {
      let index = items.findIndex((x) => x.id === result.draggableId);
      if (index === -1) return;
      list = items.filter((x) => x.conditionalFieldId === items[index].conditionalFieldId);
    }

    const srcIndex = items.findIndex((x) => x.id === list[result.source.index].id);
    const destIndex = items.findIndex((x) => x.id === list[result.destination.index].id);
    if (srcIndex > -1 && destIndex > -1) {
      const [removed] = items.splice(srcIndex, 1);
      items.splice(destIndex, 0, removed);
      onChange(items);
      setItems([...items]);
    }
  }

  function addConditionalField(conditionalFieldId, type) {
    items.push({ id: v4(), type, conditionalFieldId });
    onChange(items);
    setItems([...items]);
  }

  function saveItem(itemId, field, value) {
    let index = items.findIndex((x) => x.id === itemId);
    if (index > -1) {
      items[index][field] = value;
    }
    onChange(items);
  }

  function deleteItem(id) {
    const index = items.findIndex((x) => x.id === id);
    let remaining = items;
    if (index > -1 && items[index].fieldType === 'conditional') {
      // delete all conditional fields
      if (items[index].conditions) {
        for (let i = 0; i < items[index].conditions.length; ++i) {
          remaining = remaining.filter((x) => x.conditionalFieldId !== items[index].conditions[i].id);
        }
      }
    }
    const result = remaining.filter((x) => x.id !== id);
    onChange(result);
    setItems(result);
  }

  const allAvailableItems = isArray(allItems) ? uniqBy([...allItems, ...items], 'id') : items;

  return (
    <Grid container {...props}>
      <Grid container item>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setListDragging(true)}>
          <Droppable droppableId="fields" direction="vertical" type="fields">
            {(provided) => (
              <Grid container {...provided.droppableProps} ref={provided.innerRef}>
                {notNested.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Grid
                        container
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <EditField
                          item={item}
                          isGlobal={Boolean(item.globalFieldId)}
                          isDragging={snapshot.isDragging}
                          listDragging={listDragging}
                          allItems={allAvailableItems}
                          onAdd={addConditionalField}
                          onEdit={saveItem}
                          onDelete={deleteItem}
                          onDuplicate={handleDuplicate}
                          nested={!conditional}
                          peopleEmailOptions={peopleEmailOptions}
                          onDeleteConditional={(id) => {
                            const remaining = items.filter((x) => x.conditionalFieldId !== id);
                            setItems(remaining);
                            onChange(remaining);
                          }}
                          domain={domain}
                          forPortal={forPortal}
                          disabled={disabled}
                          calculation={calculation}
                          status={status}
                          showConditions={showConditions}
                          withActionGroup
                          activityType={activityType}
                          activityId={activityId}
                          moveFields={moveFields}
                          currentlyOn={currentlyOn}
                        />
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      {!disabled && (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <GeneralButton onClick={addField} startIcon={<InputRounded style={{ color: iconColor }} />}>
              Field
            </GeneralButton>
          </Grid>
          {withGlobal && (
            <Grid item>
              <GeneralButton
                onClick={() => setGlobalFieldsDialog(true)}
                startIcon={<ListAltRounded style={{ color: iconColor }} />}
              >
                Pick from global
              </GeneralButton>
              <GlobalFieldsDialog
                open={globalFieldsDialog}
                onClose={() => setGlobalFieldsDialog(false)}
                onResult={addGlobalField}
              />
            </Grid>
          )}
          {text && (
            <Grid item>
              <GeneralButton onClick={addTextItem} startIcon={<TextFieldsRounded style={{ color: iconColor }} />}>
                Text
              </GeneralButton>
            </Grid>
          )}
          {info && (
            <Grid item>
              <GeneralButton onClick={addInfoItem} startIcon={<InfoRounded style={{ color: iconColor }} />}>
                Info
              </GeneralButton>
            </Grid>
          )}
          {form && (
            <Grid item>
              <GeneralButton onClick={addFormItem} startIcon={<FormIcon />}>
                Form
              </GeneralButton>
            </Grid>
          )}
          {process && (
            <Grid item>
              <GeneralButton onClick={addProcessItem} startIcon={<ProcessIcon />}>
                Process
              </GeneralButton>
            </Grid>
          )}
          {task && (
            <Grid item>
              <GeneralButton onClick={addTaskItem} startIcon={<TaskIcon />}>
                Task
              </GeneralButton>
            </Grid>
          )}
          {ru && (
            <Grid item>
              <GeneralButton onClick={addRUItem} startIcon={<ReadUnderstoodIcon />}>
                Read and understood
              </GeneralButton>
            </Grid>
          )}
          {table && (
            <Grid item>
              <GeneralButton onClick={addTableItem} startIcon={<TableChartRounded style={{ color: iconColor }} />}>
                Table
              </GeneralButton>
            </Grid>
          )}
          {resources && (
            <Grid item>
              <GeneralButton onClick={addResourceItem} startIcon={<FileCopyRounded style={{ color: iconColor }} />}>
                Resources
              </GeneralButton>
            </Grid>
          )}
          {assetIncident && (
            <Grid item>
              <GeneralButton
                onClick={addAssetIncidentItem}
                startIcon={<ReportProblemRounded style={{ color: yellow[700] }} />}
              >
                Asset incident
              </GeneralButton>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

function GlobalFieldsDialog({ open, onClose, onResult }) {
  const [fields, setFields] = useState([]);
  const [pattern, setPattern] = useState('');
  const [loading, setLoading] = useState(false);
  const loadFields = open && isEmpty(fields);
  const filtered = fields.filter((x) => String(x.title).toLowerCase().includes(pattern.toLowerCase()));

  useEffect(() => {
    if (!loadFields) return;
    setLoading(true);
    axios
      .get('/fields/getAvailableFields')
      .then(({ data }) => {
        setFields(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadFields]);

  function selectField(field) {
    const result = {
      ...field,
      id: v4(),
      globalFieldId: field.id,
      globalFieldVersionId: field.versionId,
      type: 'field',
      versionId: undefined,
    };
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select field</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems="center" justifyContent="center" item style={{ height: '50vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        ) : isEmpty(fields) ? (
          <Grid container alignItems="center" justifyContent="center" item style={{ height: '50vh' }}>
            <Typography variant="h6" color="textSecondary">
              No fields available
            </Typography>
          </Grid>
        ) : (
          <Grid container direction="column">
            <Grid container item>
              <TextField
                value={pattern}
                onChange={(e) => setPattern(e.target.value)}
                fullWidth
                variant="outlined"
                placeholder="Search here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchRounded style={{ color: grey[300] }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid container direction="column" item style={{ height: '50vh', overflow: 'auto', marginTop: 12 }}>
              {isEmpty(filtered) ? (
                <Typography color="textSecondary">Sorry, no fields found for your search</Typography>
              ) : (
                <List style={{ width: '100%' }}>
                  {filtered.map((field) => (
                    <ListItem button style={{ borderRadius: 5 }} onClick={() => selectField(field)}>
                      <Grid container item alignItems="center">
                        <LabelRounded style={{ color: amber[500], marginRight: 12 }} />
                        <Typography variant="h6">{field.title}</Typography>
                        <div style={{ marginLeft: 'auto' }} />
                        {FieldTypes[field.fieldType].icon}
                        <Typography color="textSecondary" style={{ marginLeft: 8 }}>
                          {FieldTypes[field.fieldType].text}
                        </Typography>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
