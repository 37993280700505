import { grey } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ListItemText, ListItemIcon, List, ListItem, ListItemButton } from '@mui/material';
import { PanoramaRounded, DescriptionRounded } from '@mui/icons-material';
import React from 'react';
import { RoundedDialog, StandardDialogActions } from '../Components';
import fieldTypes from './FieldTypes';

export default function PickFieldTypeDialog({
  open,
  onClose,
  onResult,
  excludedTypes,
  withActionGroup,
  withLinkedFeature,
}) {
  function selectType(type) {
    onResult(type);
    onClose();
  }

  const excluded = Array.isArray(excludedTypes) ? excludedTypes : [];

  const includeConditional = !excluded.includes('conditional');
  const includeCalculation = !excluded.includes('calculation');
  const includeAction = false && !excluded.includes('action');
  const includeRns = !excluded.includes('readAndSign');

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="non-draggable">
      <DialogTitle>Pick a type</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item lg={6} md={6} sm={12} direction="column" style={{ padding: 8 }}>
            <Typography gutterBottom variant="h6">
              Input
            </Typography>
            <List style={{ marginBottom: '2rem' }}>
              {[
                'text',
                'textArea',
                'phone',
                'email',
                'number',
                'date',
                'time',
                'datetime',
                'signature',
                'table',
                'stock',
                'people',
              ]
                .filter((x) => !excluded.includes(x))
                .map((type) => (
                  <ListItemButton key={type} style={{ borderRadius: 8 }} onClick={() => selectType(type)}>
                    <ListItemIcon>{fieldTypes[type]?.icon}</ListItemIcon>
                    <ListItemText primary={fieldTypes[type]?.text} />
                  </ListItemButton>
                ))}
            </List>

            {(includeConditional || includeCalculation || includeAction || withActionGroup || includeRns) && (
              <>
                <Typography gutterBottom variant="h6">
                  Logic
                </Typography>

                <List>
                  {includeConditional && (
                    <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('conditional')}>
                      <ListItemIcon>{fieldTypes['conditional']?.icon}</ListItemIcon>
                      <ListItemText primary={fieldTypes['conditional']?.text} />
                    </ListItemButton>
                  )}
                  {includeCalculation && (
                    <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('calculation')}>
                      <ListItemIcon>{fieldTypes['calculation']?.icon}</ListItemIcon>
                      <ListItemText primary={fieldTypes['calculation']?.text} />
                    </ListItemButton>
                  )}
                  {includeAction && (
                    <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('action')}>
                      <ListItemIcon>{fieldTypes['action']?.icon}</ListItemIcon>
                      <ListItemText primary={fieldTypes['action']?.text} />
                    </ListItemButton>
                  )}
                  {withActionGroup && (
                    <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('actionGroup')}>
                      <ListItemIcon>{fieldTypes['actionGroup']?.icon}</ListItemIcon>
                      <ListItemText primary={fieldTypes['actionGroup']?.text} />
                    </ListItemButton>
                  )}
                  {includeRns && (
                    <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('readAndSign')}>
                      <ListItemIcon>{fieldTypes['readAndSign']?.icon}</ListItemIcon>
                      <ListItemText primary={fieldTypes['readAndSign']?.text} />
                    </ListItemButton>
                  )}
                </List>
              </>
            )}
          </Grid>
          <Grid container item lg={6} md={6} sm={12} direction="column" style={{ padding: 8 }}>
            {(!excluded.includes('image') || !excluded.includes('file')) && (
              <Typography gutterBottom variant="h6">
                Media
              </Typography>
            )}
            {(!excluded.includes('image') || !excluded.includes('file')) && (
              <List style={{ marginBottom: '2rem' }}>
                {!excluded.includes('image') && (
                  <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('image')}>
                    <ListItemIcon>
                      <PanoramaRounded style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Image" />
                  </ListItemButton>
                )}
                {!excluded.includes('file') && (
                  <ListItemButton style={{ borderRadius: 8 }} onClick={() => selectType('file')}>
                    <ListItemIcon>
                      <DescriptionRounded style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="File" />
                  </ListItemButton>
                )}
              </List>
            )}

            {['dropbox', 'tickbox', 'status', 'user', 'dataSet'].filter((x) => !excluded.includes(x)).length > 0 && (
              <Typography gutterBottom variant="h6">
                Selector
              </Typography>
            )}
            <List>
              {['dropbox', 'tickbox', 'weightedList', 'status', 'user', 'dataSet', 'humanMap']
                .filter((x) => !excluded.includes(x))
                .map((type) => (
                  <ListItemButton key={type} style={{ borderRadius: 8 }} onClick={() => selectType(type)}>
                    <ListItemIcon>{fieldTypes[type]?.icon}</ListItemIcon>
                    <ListItemText primary={fieldTypes[type]?.text} />
                  </ListItemButton>
                ))}
              {/* {withLinkedFeature && (
                <ListItemButton
                  key={'refgroup'}
                  style={{ borderRadius: 8 }}
                  onClick={() => selectType('linkedFeature')}
                >
                  <ListItemIcon>{fieldTypes['linkedFeature']?.icon}</ListItemIcon>
                  <ListItemText primary={fieldTypes['linkedFeature']?.text} />
                </ListItemButton>
              )} */}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
