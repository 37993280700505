import React from 'react';

import { v4 } from 'uuid';

import { Extensions } from './types';

import InlinePDFViewer from '../PDFViewer/inline';
import PDFViewer from '../PDFViewer';
import ImageViewer from './image';
import QDocViewer from './qdoc';
import NoSupport from './nosupport';
import MSViewer from './msviewer';
import VideoViewer from './video';
import ZipViewer from './zip';
import NCReader from './nc';
import PDFExternal from './pdf-external';
import { useSelector } from 'react-redux';

const isVideoFile = (extension, src) => {
  // check if src has '?user_token='
  if (src?.includes('?user_token=')) {
    const url = src.split('?user_token=')[0];
    return Extensions.Video.includes(extension) || Extensions.Video.includes(url.split('.').pop());
  }
  return Extensions.Video.includes(extension);
};
export default function ({
  document,
  title,
  src,
  extension,
  hasToc,
  handleScroll,
  isTemplate,
  height = '70vh',
  inline,
  searchParams,
}) {
  const { downloadFiles, printFiles } = useSelector(({ settings }) => settings);
  const { user } = useSelector(({ profile }) => profile);
  const pdfViewer = user?.pdfViewer || 'adobe';
  const isPdf = Extensions.PDF.includes(extension);
  const isImage = Extensions.Image.includes(extension);
  const isQDoc = Extensions.QDOC.includes(extension);
  const isMSDoc = Extensions.MSDoc.includes(extension);
  const isVideo = isVideoFile(extension, src);
  const isAudio = Extensions.Audio.includes(extension);
  const isTiff = Extensions.TIFF.includes(extension);
  const isZip = Extensions.ZIP.includes(extension);
  const isNC = Extensions.NC.includes(extension);
  const isPdfExternal = pdfViewer === 'external';

  if (isPdf) {
    if (inline) {
      return (
        <InlinePDFViewer
          file={{ location: src, id: v4(), name: title }}
          enablePrint={printFiles}
          enableDownload={downloadFiles}
        />
      );
    }
    if (isPdfExternal) {
      return <PDFExternal url={src} title={title} />;
    }
    return (
      <PDFViewer
        file={{ location: src, id: v4(), name: title }}
        enablePrint={printFiles}
        enableDownload={downloadFiles}
      />
    );
  }
  if (isImage) {
    return <ImageViewer src={src} title={title} isTiff={isTiff} />;
  }
  if (isQDoc && src) {
    return (
      <QDocViewer
        title={title}
        src={src}
        hasToc={hasToc}
        handleScroll={(e) => handleScroll && handleScroll(e)}
        document={document}
        isTemplate={isTemplate}
        height={height}
        searchParams={searchParams}
      />
    );
  }
  if (isMSDoc && src) {
    return <MSViewer src={src} title={title} />;
  }
  if (isVideo || isAudio) {
    return <VideoViewer src={src} audio={isAudio} />;
  }
  if (isZip) {
    return <ZipViewer src={src} title={title} />;
  }
  if (isNC) {
    return <NCReader src={src} />;
  }
  return <NoSupport src={src} />;
}
