import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PrimaryButton, SecondaryButton } from '../Buttons';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';

import { avatarStyles, setupStyles, liveStyles, getReviewStatus, COLORS, DOCUMENT_STATUS } from '../../constants';
import User from '../../../../Global/Components/User';
import FileIconAvatar from '../FileIconAvatar';

import { blue, grey } from '@mui/material/colors';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { FolderRounded, Lock, MoreHoriz, Settings } from '@mui/icons-material';
import useDocumentName from '../../hooks/useDocumentName';
import DocumentName from '../DocumentName';
import { setTableKey } from '../../redux/actions/draft';
import StateManager from '../../../../Global/StateManager';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RevisionInfoDialog from '../RevisionInfoDialog';

export const BookMarkRenderer = ({
  params,
  handleBookmark,
  bookmarkedDocuments,
  toggleBookmarkFolder,
  bookmarkedGFolders,
}) => {
  const bookmarkStyles = { verticalAlign: 'middle', color: COLORS.BOOKMARK, cursor: 'pointer' };
  let isBookmarked =
    bookmarkedDocuments && bookmarkedDocuments?.length > 0 && bookmarkedDocuments.includes(params?.row?._id);
  if (params?.row?.isViewFolder) {
    isBookmarked =
      bookmarkedGFolders && bookmarkedGFolders?.length > 0 && bookmarkedGFolders.includes(params?.row?._id);
  }
  return isBookmarked ? (
    <BookmarkIcon
      fontSize="small"
      sx={bookmarkStyles}
      onClick={() =>
        params?.row?.isViewFolder ? toggleBookmarkFolder(params?.row?._id) : handleBookmark(params?.row?._id)
      }
    />
  ) : (
    <BookmarkBorderOutlinedIcon
      fontSize="small"
      sx={bookmarkStyles}
      onClick={() =>
        params?.row?.isViewFolder ? toggleBookmarkFolder(params?.row?._id) : handleBookmark(params?.row?._id)
      }
    />
  );
};

export const TitleCellRenderer = ({ params }) => {
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down('sm'));
  const limit = smallDevices ? 20 : 60;
  const { title, conventionSet, conventionSetParams, sequence, prefix, category, file } = params?.row ?? {};
  const name = useDocumentName({ sequence, setId: conventionSet, initial: conventionSetParams, title });

  if (!conventionSet) {
    return (
      <Tooltip title={prefix ? `${prefix?.name}-${sequence} ${title}` : title} arrow sx={{ flexFlow: 'nowrap' }}>
        <Grid container alignItems="center">
          <FileIconAvatar document={{ category, file }} fontSize={24} />
          {prefix && (
            <Typography
              component="div"
              variant="body2"
              sx={{
                marginLeft: '7px',
                color: `#${prefix?.color}`,
                fontWeight: 'bold',
                alignItems: 'center',
                verticalAlign: 'middle',
              }}
              noWrap
            >
              {prefix?.name}-{sequence}
            </Typography>
          )}
          <Typography
            component="div"
            variant="body2"
            sx={{
              marginLeft: '7px',
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
            noWrap
          >
            {title?.substr(0, limit)}
          </Typography>
        </Grid>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={name} arrow>
      <Grid container alignItems="center">
        <FileIconAvatar document={{ category, file }} fontSize={24} />
        <Typography
          component="div"
          variant="body2"
          sx={{
            marginLeft: '7px',
            alignItems: 'center',
            verticalAlign: 'middle',
          }}
          noWrap
        >
          <DocumentName
            sequence={sequence}
            setId={conventionSet}
            initial={conventionSetParams}
            title={title.substr(0, limit)}
          />
        </Typography>
      </Grid>
    </Tooltip>
  );
};

export const UserCellRenderer = ({ params }) => {
  const owner = params?.formattedValue ?? params?.row?.owner_id ?? params?.row?.created_by;
  return <User id={typeof owner === 'string' ? owner : owner?.id} clickable avatarSize={30} />;
};

export const ArchiveUserCellRenderer = ({ params }) => {
  const owner = params?.row?.archivedBy;
  return owner ? <User id={typeof owner === 'string' ? owner : owner?.id} clickable avatarSize={30} /> : '-';
};

export const LocationCellRenderer = ({ params }) => {
  const row = params?.row;
  return `${row?.area ? row.area?.name : 'n/a'}${row?.isFolder && row?.folder ? `> ${row.folder?.name}` : ''}`;
};

export const TagsCellRenderer = ({ params, justifyContent, styleProps, max = 2, onClick }) => {
  const { tags } = params?.row;
  if (Array.isArray(tags) && tags?.length > 0) {
    return (
      <Grid
        container
        item
        alignItems="center"
        justifyContent={justifyContent}
        wrap="nowrap"
        sx={{ ...styleProps }}
        component="section"
      >
        {tags
          ?.filter((_, i) => i < max)
          ?.filter((t) => t)
          ?.map((tag) => (
            <Chip
              key={tag?._id}
              label={tag?.name}
              onClick={(e) => onClick && onClick(e)}
              sx={{
                background: `#${tag?.color}`,
                color: (theme) => (tag?.color ? theme.palette.getContrastText(`#${tag.color}`) : undefined),
                marginRight: '8px',
              }}
            />
          ))}
        {tags?.length > max && (
          <Typography variant="h6" color="textSecondary" sx={{ marginRight: '8px' }}>{`+${
            tags?.length - max
          }`}</Typography>
        )}
      </Grid>
    );
  }
  return null;
};

export const FolderCellRenderer = ({ params }) => {
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down('sm'));
  const limit = smallDevices ? 20 : 60;
  const { title, name } = params?.row;
  return (
    <Tooltip title={title ?? name} arrow sx={{ flexFlow: 'nowrap' }}>
      <Grid container alignItems="center">
        <Avatar sx={{ ...avatarStyles, backgroundColor: 'transparent', color: grey[500] }}>
          <FolderRounded />
        </Avatar>
        <Typography
          component="div"
          variant="body2"
          sx={{
            marginLeft: '7px',
            alignItems: 'center',
            verticalAlign: 'middle',
          }}
          noWrap
        >
          {title?.substr(0, limit) ?? name?.substr(0, limit)}
        </Typography>
      </Grid>
    </Tooltip>
  );
};

export const DateTimeCellRenderer = ({ params, format, toNow = false, daysPast = false }) => {
  if (!params) return 'No Date';
  if (toNow) {
    return moment(params).toNow(true);
  } else if (daysPast) {
    return Math.abs(moment(params).diff(moment(), 'days'));
  } else {
    return moment(params).format(format);
  }
};

export const RevisionCellRenderer = ({ params, field }) => {
  const {
    revisionFormat,
    preApproval,
    manualIssueControl,
    manualIssue,
    issue,
    baseManualIssue,
    baseVersion,
    nextVersion,
  } = params?.row;
  if (field === 'issueUpdate') {
    return manualIssueControl
      ? `${baseManualIssue} > ${manualIssue}`
      : revisionFormat === 'V1,V2,V3,V4'
      ? `V${baseVersion} > V${nextVersion}`
      : revisionFormat === '01,02,03,04'
      ? `${baseVersion} > ${nextVersion}`
      : `${String.fromCharCode(64 + baseVersion)} > ${String.fromCharCode(64 + nextVersion)}`;
  } else {
    return manualIssueControl
      ? manualIssue
      : revisionFormat === 'V1,V2,V3,V4'
      ? `V${preApproval ? '0' : issue}`
      : revisionFormat === '01,02,03,04'
      ? preApproval
        ? '0'
        : issue
      : preApproval
      ? '0'
      : String.fromCharCode(64 + issue);
  }
};

export function StatusCellRenderer({ params, handleSetup, handleControls }) {
  return (
    <NormalButton
      doc={params?.row}
      handleSetup={() => handleSetup(params?.row)}
      handleControls={() => handleControls(params?.row)}
    />
  );
}

export const PrefixCellRenderer = ({ children, prefix, sequence, ext, category, file, ...props }) => {
  return (
    <Grid {...props} padding="none" width="3rem">
      <Tooltip title={prefix ? `${prefix?.name} ${sequence}` : sequence} arrow sx={{ flexFlow: 'nowrap' }}>
        <Grid container alignItems="center">
          <FileIconAvatar document={{ extension: ext, category, file }} fontSize={24} />
          <Typography
            component="div"
            variant="body2"
            sx={{
              marginLeft: '7px',
              color: `#${prefix?.color}`,
              fontWeight: 'bold',
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
            noWrap
          >
            {prefix?.name} {sequence}
          </Typography>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

const ReviewCellRenderer = ({ doc }) => {
  const { user } = useSelector(({ profile }) => profile);
  const handleReview = (e) => {
    e.preventDefault();
    if (
      user?._id === doc.reviewLink.created_by ||
      user?.access === 'admin' ||
      doc.reviewLink?.assigned?.includes(user?._id) ||
      doc?.owner_id?._id === user?._id ||
      doc?.owner_id === user?._id
    ) {
      StateManager.openReviewDialog(doc.reviewLink?._id);
    }
  };
  return (
    <PrimaryButton
      variant="outlined"
      sxProps={{
        ...liveStyles,
        backgroundColor: 'white',
        color: 'green',
        '&:hover': {
          backgroundColor: 'white',
          color: 'green',
        },
      }}
      size="small"
      title={'Under Review'}
      action={handleReview}
    />
  );
};

export const ReviewStatus = ({ doc }) => {
  const reviewColor = getReviewStatus(doc.reviewDate) === 'Review Today' ? 'orange' : 'red';
  const documentState = doc.documentState;
  const title = documentState === 'Review Overdue' ? 'Review Overdue' : getReviewStatus(doc.reviewDate);

  return (
    <PrimaryButton
      variant="outlined"
      sxProps={{
        ...liveStyles,
        backgroundColor: 'white',
        color: reviewColor,
        '&:hover': {
          backgroundColor: 'white',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: 'white',
          boxShadow: 'none',
          color: reviewColor,
        },
      }}
      size="small"
      disabled
      title={title}
    />
  );
};

export const getIssueTooltip = (status) => {
  if (status === 'Up-Issuing') {
    return 'The document is being up-issued';
  }
  if (status === DOCUMENT_STATUS.Editing) {
    return 'The document is being edited';
  }
  if (status === DOCUMENT_STATUS.Reviewing) {
    return 'The document is being up issued and is in a review stage';
  }
  if (status === DOCUMENT_STATUS.Approving) {
    return 'The document is being up-issued and is currently waiting approval';
  }
  return '';
};

export const getIssueColor = (status) => {
  if (status === DOCUMENT_STATUS.UpIssuing) {
    return {
      backgroundColor: blue[400],
      color: 'white',
    };
  }
  if ([DOCUMENT_STATUS.Editing].includes(status)) {
    return {
      backgroundColor: blue[500],
      color: 'white',
    };
  }
  if ([DOCUMENT_STATUS.Reviewing].includes(status)) {
    return {
      backgroundColor: 'orange',
      color: 'white',
    };
  }
  if ([DOCUMENT_STATUS.Approving].includes(status)) {
    return {
      backgroundColor: 'indigo',
      color: 'white',
    };
  }
  return {};
};

export const NormalControlStatus = ({ doc, handleSetup, handleControls }) => {
  const preApprovalStyle = { backgroundColor: COLORS.DARKPURPLE };
  const { user } = useSelector(({ profile }) => profile);
  const { tableKey, tableKeyDialog } = useSelector(({ draft }) => draft);
  const { updatingDocument, selected } = useSelector(({ dochub }) => dochub);
  const history = useHistory();
  const dispatch = useDispatch();
  const isSelected = doc?._id === selected?._id && updatingDocument;

  const handleUpIssue = () => {
    if (tableKey && tableKeyDialog) {
      dispatch(setTableKey(null, false));
    }
    const upIssueLink = doc.upIssueLink;
    if (upIssueLink) {
      const { editors, reviewers, approvers } = upIssueLink;
      if (
        editors.includes(user?._id) ||
        reviewers.map((x) => x.user.toString()).includes(user?._id) ||
        approvers.includes(user?._id) ||
        doc.approvers.includes(user?._id) ||
        doc.reviewers.includes(user?.id)
      ) {
        history.push(`/editor/${doc._id}/upissue/${upIssueLink._id}`);
      }
    }
  };

  if (isSelected) {
    return <CircularProgress size={24} thickness={5} color="primary" />;
  }

  if (
    [DOCUMENT_STATUS.Editing, DOCUMENT_STATUS.Reviewing, DOCUMENT_STATUS.Approving, DOCUMENT_STATUS.UpIssuing].includes(
      doc.documentState,
    )
  ) {
    return (
      <Tooltip title={getIssueTooltip(doc.documentState)}>
        <Button
          startIcon={<Lock />}
          variant="outlined"
          sx={{ ...liveStyles, ...getIssueColor(doc.documentState), ':hover': { ...getIssueColor(doc.documentState) } }}
          size="small"
          onClick={handleUpIssue}
          children={doc.documentState}
        />
      </Tooltip>
    );
  }

  if (doc.documentState === DOCUMENT_STATUS.Draft) {
    return (
      <PrimaryButton
        variant="outlined"
        size="small"
        disabled
        title={DOCUMENT_STATUS.Draft}
        sxProps={{
          ...liveStyles,
          backgroundColor: 'white',
          color: '#000',
          '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            color: '#252525',
          },
        }}
      />
    );
  }

  if (doc.documentState === DOCUMENT_STATUS.PreApprovalSetup) {
    return (
      <SecondaryButton
        disabled={user && user.access === 'view' && user?._id !== doc?.owner_id?._id}
        action={handleSetup}
        startIcon={<Settings />}
        size="small"
        sxProps={{
          ...setupStyles,
          ...preApprovalStyle,
        }}
        title={DOCUMENT_STATUS.PreApprovalSetup}
      />
    );
  }

  if (doc.documentState === DOCUMENT_STATUS.PreApproval) {
    return (
      <SecondaryButton
        disabled={user && user.access === 'view' && user?._id !== doc?.owner_id?._id}
        action={handleUpIssue}
        size="small"
        sxProps={{
          ...setupStyles,
          ...preApprovalStyle,
        }}
        title={DOCUMENT_STATUS.PreApproval}
      />
    );
  }

  if (doc.documentState === DOCUMENT_STATUS.Setup) {
    return (
      <SecondaryButton
        disabled={user && user.access === 'view' && user?._id !== doc?.owner_id?._id}
        startIcon={<Settings />}
        action={handleSetup}
        size="small"
        sxProps={setupStyles}
        title={DOCUMENT_STATUS.Setup}
      />
    );
  }

  if ([DOCUMENT_STATUS.Controlled, DOCUMENT_STATUS.Approved].includes(doc.documentState)) {
    return (
      <PrimaryButton
        startIcon={<Lock />}
        variant="outlined"
        sxProps={liveStyles}
        size="small"
        action={handleControls}
        title={DOCUMENT_STATUS.Approved}
      />
    );
  }

  let normalStyles = {
    ...liveStyles,
    backgroundColor: 'white',
    color: '#000',
    '&:hover': {
      color: '#000',
    },
  };

  if (doc.documentState === DOCUMENT_STATUS.Template) {
    normalStyles = {
      ...normalStyles,
      border: `1px solid ${blue[300]}`,
      '&:hover': {
        color: blue[300],
      },
    };
  }

  return (
    <PrimaryButton
      variant="outlined"
      sxProps={normalStyles}
      size="small"
      action={handleControls}
      title={doc?.documentState}
    />
  );
};

const BadgeButton = ({ doc, handleSetup, handleControls }) => (
  <Badge variant="dot" color="error">
    <NormalButton doc={doc} handleSetup={handleSetup} handleControls={handleControls} />
  </Badge>
);

export const NormalButton = ({ doc, handleSetup, handleControls }) => {
  const reviewLink = doc.reviewLink;
  if (doc.controlled && doc.status && !doc.preApproval && reviewLink && reviewLink.status !== 'Completed') {
    return <ReviewCellRenderer doc={doc} />;
  }
  if (doc.documentState === 'Review Overdue') {
    return <ReviewStatus doc={doc} />;
  }
  return <NormalControlStatus doc={doc} handleSetup={handleSetup} handleControls={handleControls} />;
};

export const AreaCellRenderer = ({ children, limit, ...props }) => {
  return (
    <Tooltip title={children} arrow>
      <Grid {...props}>{children.substr(0, limit)}</Grid>
    </Tooltip>
  );
};

export const RevisionUpdateCellRenderer = ({
  previousIssue,
  currentIssue,
  format,
  manualIssueControl,
  manualIssue,
  previousManualIssue,
  ...props
}) => {
  return (
    <Grid {...props}>
      {manualIssueControl
        ? `${previousManualIssue} > ${manualIssue}`
        : format === 'V1,V2,V3,V4'
        ? `V${previousIssue} > V${currentIssue}`
        : format === '01,02,03,04'
        ? `${previousIssue} > ${currentIssue}`
        : `${String.fromCharCode(64 + previousIssue)} > ${String.fromCharCode(64 + currentIssue)}`}
    </Grid>
  );
};

export const ActionCellRender = ({ handleMenu, params }) => {
  return (
    <IconButton
      aria-label={'more'}
      sx={{ border: '1px solid black' }}
      size="small"
      onClick={(e) => handleMenu(e, params?.row)}
    >
      <MoreHoriz />
    </IconButton>
  );
};

export const SeeInfoCellRender = ({ setRevisionDialog, params, revisionDialog, openFile }) => {
  return (
    <>
      <Button
        onClick={() => setRevisionDialog(params?.row?.revision?._id)}
        variant={'outlined'}
        size="small"
        sx={{ textTransform: 'none' }}
      >
        See Info
      </Button>
      {Boolean(revisionDialog) && revisionDialog === params?.row?.revision?._id && (
        <RevisionInfoDialog
          document={params?.row?.revision}
          open={Boolean(revisionDialog)}
          handleClose={() => setRevisionDialog(null)}
          linked={false}
          preview={() => openFile(params?.row)}
        />
      )}
    </>
  );
};
