import React, { useState, useEffect } from 'react';
import { Typography, Grid, Tooltip, InputBase, IconButton } from '@mui/material';
import { HelpRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import FieldTypes from '../../../../Global/Fields/FieldTypes';

export default function Header({ column, defaultColumnsAlign, onTitleChange, editable, openTypeDialog, disabled }) {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (!column || !editable) return;
    setTitle(column.title || '');
  }, [column, editable]);

  if (!editable) {
    return (
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item sx={{ flexGrow: 1, justifyContent: column.textAlign || defaultColumnsAlign, display: 'flex' }}>
          <Tooltip
            title={
              column.description ? <Typography style={{ whiteSpace: 'pre-line' }}>{column.description}</Typography> : ''
            }
            placement="top"
          >
            <Typography noWrap variant="subtitle2">
              {column.title || 'Not defined'}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center" wrap="nowrap" onClick={(e) => e.stopPropagation()}>
      <Tooltip title={column.fieldType ? FieldTypes[column.fieldType]?.text : 'Select column type'}>
        <Grid item style={{ position: 'relative' }}>
          <IconButton disabled={disabled} onClick={openTypeDialog} style={{ width: 36, height: 36 }}>
            {!column.fieldType && <HelpRounded style={{ color: grey[500] }} />}
            {column.fieldType && FieldTypes[column.fieldType]?.icon}
          </IconButton>
        </Grid>
      </Tooltip>

      <InputBase
        value={title}
        placeholder="Column title"
        inputProps={{ maxLength: 64 }}
        style={{ flexGrow: 1, marginLeft: 16 }}
        onChange={(e) => {
          setTitle(e.target.value);
          onTitleChange(e.target.value);
        }}
        disabled={disabled}
      />
    </Grid>
  );
}
