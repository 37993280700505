import { StandardDialogActions, RoundedDialog } from '../../../../Global/Components';
import TagTable from './table';

import DialogContent from '@mui/material/DialogContent';

const TagsDialog = ({ open, onClose }) => {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <TagTable />
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
};

export default TagsDialog;
