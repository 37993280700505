import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { TooltipTypography } from '../Components';
import SectionsPreview from '../../Hubs/dochub/components/HTMLEditor/components/Sections/preview';
import HeaderFooter from '../../Hubs/dochub/components/HeaderFooterPreview/headerFooter';
import A4Container from '../../Hubs/dochub/components/HTMLEditor/components/A4Container';

export default function QDocViewer({
  title,
  src,
  hasToc,
  handleScroll,
  document,
  isTemplate,
  searchParams,
  height = 'calc(100vh - 150px)',
}) {
  const { defaultHeaderFooter, headerConfig, footerConfig } = useSelector(({ settings }) => settings);

  return (
    <Box>
      <A4Container handleScroll={handleScroll} height={height}>
        <HeaderFooter
          document={document}
          header={defaultHeaderFooter ? headerConfig : document?.headerConfig}
          qtemplate={isTemplate}
        />
        <TooltipTypography sx={{ fontWeight: 'bold', fontSize: 'var(--title-size)' }} noWrap={false}>
          {title}
        </TooltipTypography>
        <SectionsPreview
          initial={src}
          hasToc={hasToc}
          documentId={document?._id}
          isDocument={!isTemplate}
          sectionNumbering={document?.sectionNumbering}
          {...searchParams}
        />
        <HeaderFooter
          document={document}
          footer={defaultHeaderFooter ? footerConfig : document?.footerConfig}
          qtemplate={isTemplate}
        />
      </A4Container>
    </Box>
  );
}
