import React, { useState } from 'react';
import { Hidden, Menu, MenuItem, Typography, Drawer, Divider, IconButton, Fab, Grid } from '@mui/material';
import { useMediaQuery, List, ListItemIcon, ListItemText, Collapse, Tooltip } from '@mui/material';
import { ListItemButton, useTheme } from '@mui/material';
import AppHeader from '../AppHeader';
import MobileBar from '../MobileBar';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { blue, grey } from '@mui/material/colors';
import { ExpandMoreRounded } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import { HUB_COLORS } from '../../../constants';

import { Hubs } from '../AppHeader';
import './index.css';

const drawerWidth = 240;
const selectedColorDark = '#1e293a';
const selectedColorLight = '#f7f7f7';

export default function DrawerLayout({
  children,
  title,
  picture,
  icon,
  hub,
  drawerContent,
  noPadding,
  customMargin,
  fillHeight,
  position,
}) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [drawer, setDrawer] = useState(
    document.documentElement.clientWidth / document.documentElement.clientHeight > 4 / 3,
  );

  const sections = drawerContent?.sections?.filter((x) => !x.hidden) || [];

  const handleResize = (e) => {
    e.preventDefault();
    let toggled = document.documentElement.clientWidth / document.documentElement.clientHeight > 4 / 3;
    setDrawer(toggled);
  };

  window && window.addEventListener('resize', handleResize);

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [anchorElHubs, setAnchorElHubs] = React.useState(null);

  const [plusButton, setPlusButton] = React.useState(<AddCircleOutlineIcon style={{ color: 'lightsteelblue' }} />);

  const icons = [
    {
      label: 'Menu',
      icon: <MenuIcon />,
      action: () => setDrawer(true),
    },
    {
      label: 'Add',
      icon: plusButton,
      action: (event) => setMenuAnchor(event.currentTarget),
    },
    {
      label: 'Hubs',
      icon: <AppsIcon />,
      action: (event) => setAnchorElHubs(event.currentTarget),
    },
  ];

  const drawerStyle = drawer
    ? {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      };

  const contentStyle = {
    [theme.breakpoints.up('sm')]: {
      padding: noPadding ? 0 : theme.spacing(2),
    },

    flexGrow: 1,
    padding: theme.spacing(0.65),
    marginTop: customMargin || '54px',
    overflowX: 'hidden',
    paddingBottom: '70px',
    ...(position && {
      position,
    }),
  };

  return (
    <div className="scroll-bar" style={{ display: 'flex', height: fillHeight ? '100vh' : undefined }}>
      <AppHeader title={title} picture={picture} icon={icon} open={drawer} setOpen={() => setDrawer(!drawer)} />
      {drawerContent && (
        <Drawer
          open={drawer}
          onClose={() => setDrawer(!drawer)}
          variant={largeDevices ? 'permanent' : 'temporary'}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            ...drawerStyle,
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.background.paper,
              ...drawerStyle,
            },
          }}
          style={{ zIndex: 999 }}
        >
          <Grid container direction="column" wrap="nowrap" className="app-sidebar">
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              style={{ marginTop: largeDevices ? 58 : 5 }}
              sx={{ p: 1.5 }}
            >
              {drawer && <div>{picture ? <img alt="img" src={picture} style={{ height: 48 }} /> : icon}</div>}
              {drawer && (
                <Typography variant="h6" noWrap style={{ fontWeight: 500, margin: 'auto' }}>
                  {title}
                </Typography>
              )}
              {drawerContent.menuContent && largeDevices && (
                <IconButton
                  style={{ marginLeft: drawer ? 0 : 'auto' }}
                  className={'addIcon'}
                  onClick={(event) => setMenuAnchor(event.currentTarget)}
                  onMouseEnter={() => setPlusButton(<AddCircleIcon style={{ color: 'lightsteelblue' }} />)}
                  onMouseLeave={() => setPlusButton(<AddCircleOutlineIcon style={{ color: 'lightsteelblue' }} />)}
                >
                  {plusButton}
                </IconButton>
              )}
            </Grid>
            {/* <Divider style={{ height: 2, width: '100%' }} /> */}
            {drawerContent.menuContent && largeDevices && (
              <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
                {drawerContent.menuContent
                  .filter((x) => !x.hidden)
                  .map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setMenuAnchor(null);
                        item.onClick();
                      }}
                    >
                      <ListItemIcon>
                        {item.picture ? (
                          <img alt="img" src={item.picture} style={{ height: 25, width: 25 }} />
                        ) : (
                          item.icon
                        )}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
              </Menu>
            )}
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                <DrawerSection section={section} minimised={!drawer} theme={theme} hub={hub} />
                {/* {index !== sections.length - 1 && <Divider style={{ height: 2, width: '100%' }} />} */}
              </React.Fragment>
            ))}
            {largeDevices && (
              <Grid
                style={{
                  position: 'fixed',
                  display: 'flex',
                  top: 0,
                  bottom: 0,
                  left: drawer ? theme.spacing(28) : theme.spacing(5),
                  justifyContent: 'flex-end',
                  zIndex: 999,
                  margin: 0,
                  padding: 0,
                  alignItems: 'center',
                  opacity: 1,
                }}
              >
                <Fab size="small" style={{ width: 30, height: 30, minHeight: 25 }} onClick={() => setDrawer(!drawer)}>
                  {drawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </Fab>
              </Grid>
            )}
            <Grid container style={{ marginTop: 'auto' }}>
              {Array.isArray(drawerContent.bottomItems) && (
                <List style={{ width: '100%' }}>
                  {drawerContent.bottomItems
                    .filter((x) => !x.hidden)
                    .map((item, index) => (
                      <DrawerItem
                        minimised={!drawer}
                        key={index}
                        item={item}
                        pathname={pathname}
                        theme={theme}
                        hub={hub}
                      />
                    ))}
                </List>
              )}

              {drawer && process.env.REACT_APP_VERSION && (
                <Grid container item style={{ padding: 8, textAlign: 'center' }} justifyContent="center">
                  <Typography color="textPrimary" style={{ fontSize: 12 }}>
                    Q-hub ({process.env.REACT_APP_VERSION})
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Drawer>
      )}
      <Grid container sx={contentStyle} alignContent="flex-start">
        {children}
      </Grid>
      {drawerContent && (
        <Hidden smUp>
          <MobileBar
            menuAction={icons[0].action}
            addAction={icons[1].action}
            moreAction={icons[2].action}
            showAdd={Boolean(drawerContent.menuContent)}
          />
          {drawerContent.menuContent && (
            <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
              {drawerContent.menuContent
                .filter((x) => !x.hidden)
                .map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setMenuAnchor(null);
                      item.onClick();
                    }}
                  >
                    {item.picture ? <img alt="img" src={item.picture} style={{ height: 25, width: 25 }} /> : item.icon}
                    <Typography style={{ marginLeft: '1em' }}>{item.title}</Typography>
                  </MenuItem>
                ))}
            </Menu>
          )}
          <Hubs open={Boolean(anchorElHubs)} anchorEl={anchorElHubs} onClose={() => setAnchorElHubs(null)} />
        </Hidden>
      )}
    </div>
  );
}

function DrawerSection({ section, minimised, theme, hub }) {
  const [expanded, setExpanded] = useState(!section?.defaultCollapsed);
  const isDark = theme?.palette?.mode === 'dark';
  const { pathname } = useLocation();
  const history = useHistory();

  if (!section) return null;

  if (typeof section.component === 'function') {
    const Component = section.component;
    return <Component minimised={minimised} />;
  }

  const withItems = Array.isArray(section.items) && section.items.length > 0;
  const collapsible = section.collapsible && withItems;
  const titleClickable = !!section.link && !!section.icon;
  const showSectionTitle = (!minimised && (section.title || section.icon)) || (titleClickable && minimised);
  const titleSelected = titleClickable && pathname?.startsWith(section.link);

  const iconColor = titleSelected ? (HUB_COLORS[hub] ? HUB_COLORS[hub] : theme.palette.primary.main) : grey[500];

  function onTitleClick() {
    if (!titleClickable) return;
    history.push(section.link);
  }

  return (
    <Grid container>
      {showSectionTitle && (
        <Tooltip title={minimised ? section.title || '' : ''} placement="top">
          <ListItemButton
            disableRipple
            style={{
              width: '100%',
              height: 44,
              background: titleSelected ? (isDark ? selectedColorDark : selectedColorLight) : '',
            }}
            onClick={onTitleClick}
          >
            {section.icon && (
              <>
                {titleSelected && section.selectedIcon ? (
                  <ListItemIcon style={{ color: iconColor }}>{section.selectedIcon}</ListItemIcon>
                ) : (
                  <ListItemIcon style={{ color: iconColor }}>{section.icon}</ListItemIcon>
                )}
              </>
            )}
            {section.title && (
              <ListItemText
                color={titleSelected ? 'textPrimary' : 'textSecondary'}
                disableTypography
                primary={<Typography sx={{ fontWeight: 500 }}>{section.title}</Typography>}
              />
            )}
            {collapsible && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
                aria-expanded={expanded}
                sx={{
                  marginLeft: 'auto',
                  transform: 'rotate(0deg)',
                  marginLeft: 'auto',
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  transform: expanded ? 'rotate(180deg)' : null,
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <ExpandMoreRounded style={{ color: grey[500] }} />
              </IconButton>
            )}
          </ListItemButton>
        </Tooltip>
      )}

      {withItems && (
        <Collapse in={expanded || (minimised && !section.group)} style={{ width: '100%' }}>
          <Grid container>
            <List style={{ width: '100%', padding: 0 }}>
              {section.items
                .filter((x) => !x.hidden)
                .map((item, index) => (
                  <DrawerItem
                    minimised={minimised}
                    key={index}
                    item={item}
                    pathname={pathname}
                    theme={theme}
                    hub={hub}
                  />
                ))}
            </List>
          </Grid>
        </Collapse>
      )}
    </Grid>
  );
}

function DrawerItem({ item, minimised, pathname, theme, hub }) {
  const selected = pathname?.startsWith(item.link);
  const isDark = theme?.palette?.mode === 'dark';

  const iconColor = selected ? (HUB_COLORS[hub] ? HUB_COLORS[hub] : theme.palette.primary.main) : grey[500];

  return (
    <Link
      className={item.class || ''}
      to={item.link || '#'}
      onClick={() => {
        if (typeof item.onClick === 'function') {
          item.onClick();
        }
      }}
    >
      <Tooltip title={minimised ? item.title || '' : ''} placement="top">
        <ListItemButton
          style={{ background: selected ? (isDark ? selectedColorDark : selectedColorLight) : '', height: 36 }}
        >
          {item.picture ? (
            <ListItemIcon style={{ paddingLeft: minimised ? 0 : 16 }}>
              <img alt="img" src={item.picture} style={{ height: 25 }} />
            </ListItemIcon>
          ) : (
            <>
              {selected && item.selectedIcon ? (
                <ListItemIcon
                  style={{
                    paddingLeft: minimised ? 0 : 16,
                    color: iconColor,
                  }}
                >
                  {item.selectedIcon}
                </ListItemIcon>
              ) : (
                <ListItemIcon style={{ paddingLeft: minimised ? 0 : 16, color: iconColor }}>{item.icon}</ListItemIcon>
              )}
            </>
          )}
          <ListItemText
            disableTypography
            primary={
              <Typography
                color={selected ? 'textPrimary' : 'textSecondary'}
                style={{ fontWeight: selected ? 500 : 400, paddingLeft: 8 }}
              >
                {item.title}
              </Typography>
            }
          />
        </ListItemButton>
      </Tooltip>
    </Link>
  );
}
