import React, { useState, useEffect, useMemo } from 'react';
import { DialogContent, DialogTitle, Grid, Typography, TextField, MenuItem } from '@mui/material';
import { Button, Autocomplete, FormControlLabel, Checkbox, Menu, IconButton, Paper } from '@mui/material';
import { AddCircleOutlineRounded, WorkOutlineRounded, TimelineRounded } from '@mui/icons-material';
import { DescriptionRounded, DeleteOutlineRounded, EditRounded, PeopleAltRounded } from '@mui/icons-material';
import { ArrowDropDownRounded, NotificationAddRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, TaskParams, UserGroup, CheckBoxItem } from '../Components';
import { GroupChip, PortalGroupChip } from '../Components';
import { isArray, isEmpty } from 'lodash';
import { grey, red } from '@mui/material/colors';
import axios from 'axios';
import StateManager from '../StateManager';
import { FormIcon, ProcessIcon, RegistersIcon, TaskIcon } from '../Icons';
import { v4 } from 'uuid';

const OPTION_TYPES = ['dropbox', 'tickbox', 'status', 'weightedList', 'conditional'];

export default function AutomationDialog({ open, onClose, initial, field, onResult, allFields }) {
  const [selectedValue, setSelectedValue] = useState('any');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [actions, setActions] = useState([]);
  const [actionDialog, setActionDialog] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [optionsMenu, setOptionsMenu] = useState(null);

  const userFields = useMemo(
    () => (isArray(allFields) ? allFields.filter((x) => x.fieldType === 'user') : []),
    [allFields],
  );

  const dateFields = useMemo(
    () => (isArray(allFields) ? allFields.filter((x) => ['date', 'datetime'].includes(x.fieldType)) : []),
    [allFields],
  );

  useEffect(() => {
    if (isArray(initial) && !isEmpty(initial)) {
      const data = initial[0];
      const { fieldType, trigger } = data;

      setSelectedValue(
        trigger.value === 'any'
          ? 'any'
          : OPTION_TYPES.includes(fieldType) && trigger?.selectedOptionId !== 'any'
          ? null
          : trigger?.selectedOptionId,
      );

      setSelectedOptions(
        trigger.selectedOptions
          ? trigger.selectedOptions
          : trigger?.selectedOptionId && trigger?.selectedOptionId !== 'any' && OPTION_TYPES.includes(fieldType)
          ? [trigger?.selectedOptionId]
          : [],
      );

      setActions(isArray(data.actions) ? data.actions : []);
    } else {
      setSelectedValue('any');
      setActions([]);
    }

    setActionDialog(false);
    setSelectedAction(null);
  }, [initial]);

  if (!field) return null;

  const fieldType = field.fieldType;

  const isOptionType = OPTION_TYPES.includes(fieldType);
  const fieldOptions = isOptionType
    ? fieldType === 'conditional'
      ? isArray(field.conditions)
        ? field.conditions
        : []
      : isArray(field.options)
      ? field.options
      : []
    : [];

  const supportedType = isOptionType;

  function saveAction(action) {
    if (!action) return;
    const index = actions.findIndex((x) => x.id === action.id);
    if (index === -1) {
      actions.push(action);
    } else {
      actions[index] = action;
    }
    setActions([...actions]);
  }

  function editActon(action) {
    setActionDialog(true);
    setSelectedAction(action);
  }

  function deleteActon(actionId) {
    setActions(actions.filter((x) => x.id !== actionId));
  }

  function getTrigger() {
    if (isOptionType) {
      return { value: selectedValue, selectedOptions };
    } else {
      return { value: 'any' };
    }
  }

  const ready = !isEmpty(actions);

  function done() {
    if (!ready) return;
    const trigger = getTrigger();

    const id = isArray(initial) && !isEmpty(initial) ? initial[0].id : v4();

    onResult([
      {
        id,
        actions,
        fieldType,
        trigger,
      },
    ]);
    onClose();
  }

  function deleteAutomation() {
    onResult(null);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="scroll-bar">
      <DialogTitle>{initial ? 'Edit' : 'New'} automation</DialogTitle>
      <DialogContent>
        {fieldType ? (
          supportedType ? (
            <>
              <Grid container item>
                <Typography gutterBottom style={{ fontWeight: 500 }}>
                  This automation will run...
                </Typography>
              </Grid>
              {isOptionType && (
                <Grid container item sx={{ py: 1 }} alignItems={'end'}>
                  <Typography gutterBottom style={{ fontWeight: 500 }}>
                    ...when the value is
                  </Typography>

                  <Button
                    startIcon={<ArrowDropDownRounded />}
                    variant="outlined"
                    onClick={(e) => {
                      setOptionsMenu(e.currentTarget);
                    }}
                    sx={{ ml: 2 }}
                  >
                    {selectedValue === 'any'
                      ? 'changed'
                      : !isEmpty(selectedOptions)
                      ? fieldOptions
                          .filter((x) => selectedOptions.includes(x.id))
                          .map((x) => x.text)
                          .join(', ')
                      : 'select'}
                  </Button>

                  <Menu anchorEl={optionsMenu} open={Boolean(optionsMenu)} onClose={() => setOptionsMenu(null)}>
                    <MenuItem
                      onClick={() => {
                        setSelectedValue('any');
                        setOptionsMenu(null);
                      }}
                      style={{ fontWeight: 500 }}
                    >
                      changed
                    </MenuItem>
                    {fieldOptions.map((option) => (
                      <CheckBoxItem
                        key={option.id}
                        text={option.text}
                        checked={selectedOptions.includes(option.id)}
                        onChange={(checked) => {
                          setSelectedValue(null);
                          setSelectedOptions(
                            checked ? [...selectedOptions, option.id] : selectedOptions.filter((x) => x !== option.id),
                          );
                        }}
                      />
                    ))}
                  </Menu>
                </Grid>
              )}
              <Grid container item sx={{ mt: 2 }}>
                {isEmpty(actions) ? (
                  <Typography color="textSecondary">No actions defined</Typography>
                ) : (
                  actions.map((action) => (
                    <Grid container key={action.id} sx={{ mb: 2 }}>
                      <Grid alignItems={'center'} container item component={Paper} elevation={3} sx={{ py: 1, px: 2 }}>
                        {action.type === 'form' && <FormIcon />}
                        {action.type === 'task' && <TaskIcon />}
                        {action.type === 'process' && <ProcessIcon />}
                        {action.type === 'register' && <RegistersIcon />}
                        {action.type === 'notification' && <NotificationAddRounded />}

                        <Typography sx={{ ml: 1 }}>{action.title}</Typography>

                        <IconButton sx={{ ml: 'auto' }} onClick={() => editActon(action)}>
                          <EditRounded style={{ color: grey[500] }} />
                        </IconButton>

                        <IconButton sx={{ ml: 1 }} onClick={() => deleteActon(action.id)}>
                          <DeleteOutlineRounded style={{ color: red[500] }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))
                )}
              </Grid>
              <Grid container item justifyContent={'flex-end'} sx={{ mt: 1 }}>
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineRounded />}
                  onClick={() => {
                    setActionDialog(true);
                    setSelectedAction(null);
                  }}
                >
                  add action
                </Button>

                <ActionDialog
                  initial={selectedAction}
                  open={actionDialog}
                  onClose={() => setActionDialog(false)}
                  onResult={saveAction}
                  userFields={userFields}
                  dateFields={dateFields}
                />
              </Grid>
            </>
          ) : (
            <Grid container item justifyContent={'center'} alignItems={'center'} sx={{ height: '30vh' }}>
              <Typography align="center">This field is not supported yet</Typography>
            </Grid>
          )
        ) : (
          <Grid container item justifyContent={'center'} alignItems={'center'} sx={{ height: '30vh' }}>
            <Typography align="center">Please select the field type first</Typography>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <Button onClick={deleteAutomation} sx={{ color: red[500], mr: 'auto' }} startIcon={<DeleteOutlineRounded />}>
            delete automation
          </Button>
        }
        onClose={onClose}
        onDone={done}
        hideDone={!ready}
      />
    </RoundedDialog>
  );
}

function ActionDialog({ open, onClose, initial, userFields, dateFields, onResult }) {
  const [addingAction, setAddingAction] = useState(false);
  const [addingActionType, setAddingActionType] = useState(null);
  const [actionTitle, setActionTitle] = useState('');
  const [actionUsers, setActionUsers] = useState([]);
  const [actionGroups, setActionGroups] = useState([]);
  const [actionPortalGroups, setActionPortalGroups] = useState([]);
  const [userFieldId, setUserFieldId] = useState('');
  const [toSender, setToSender] = useState(false);
  const [toEntryOwner, setToEntryOwner] = useState(false);
  const [dateFieldId, setDateFieldId] = useState('');
  const [days, setDays] = useState(7);

  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [taskData, setTaskData] = useState(null);

  const [registers, setRegisters] = useState([]);
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [loadingRegisterInfo, setLoadingRegisterInfo] = useState(false);
  const [registerColumns, setRegisterColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selctedOtherActionsColumns, setSelctedOtherActionsColumns] = useState([]);

  const [notificationText, setNotificationText] = useState('');

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const formDone = addingActionType === 'form' && selectedForm;
  const processDone = addingActionType === 'process' && selectedProcess;
  const taskDone = addingActionType === 'task' && taskData?.title;
  const notificationDone = addingActionType === 'notification' && notificationText;

  const actionDone =
    (!isEmpty(actionUsers) ||
      !isEmpty(actionGroups) ||
      !isEmpty(actionPortalGroups) ||
      userFieldId ||
      toSender ||
      toEntryOwner) &&
    actionTitle &&
    (formDone || processDone || taskDone || notificationDone);

  useEffect(() => {
    if (initial) {
      setActionTitle(initial.title);
      setAddingActionType(initial.type);
      setActionUsers(initial.actionUsers || []);
      setActionGroups(initial.actionGroups || []);
      setActionPortalGroups(initial.actionPortalGroups || []);
      setUserFieldId(initial.userFieldId || '');
      setDays(initial.days || 7);
      setToSender(Boolean(initial.toSender));
      setToEntryOwner(Boolean(initial.toEntryOwner));
      setDateFieldId(initial.dateFieldId || '');
      if (initial.type === 'form') {
        loadAvailableForms(initial.formId);
      }
      if (initial.type === 'process') {
        loadAvailableProcesses(initial.processId);
      }
      if (initial.type === 'task') {
        setTaskData(initial.taskData);
      }
      if (initial.type === 'notification') {
        setNotificationText(initial.notificationText);
      }
    } else {
      setActionTitle('');
      setAddingActionType(null);
      setSelectedProcess(null);
      setSelectedForm(null);
      setActionUsers([]);
      setUserFieldId('');
      setToSender(false);
      setToEntryOwner(false);
      setDays(7);
    }
  }, [initial]); // eslint-disable-line

  function addAction(type) {
    setAddingActionType(type);
    setAddingAction(true);

    if (type === 'process' && isEmpty(processes)) {
      loadAvailableProcesses();
    }
    if (type === 'form' && isEmpty(forms)) {
      loadAvailableForms();
    }
    if (type === 'register' && isEmpty(registers)) {
      loadAvailableRegisters();
    }

    if (type === 'notification' && isEmpty(notificationText)) {
      setNotificationText('');
    }
  }

  function loadAvailableForms(formId) {
    if (!isEmpty(forms)) {
      if (formId) {
        const selected = forms.find((x) => x._id === formId);
        if (selected) {
          setSelectedForm(selected);
        }
      }
      return;
    }
    axios
      .get('/forms/general/getFormsForSearch')
      .then(({ data }) => {
        setForms(data);
        if (formId) {
          const selected = data.find((x) => x._id === formId);
          if (selected) {
            setSelectedForm(selected);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function loadAvailableProcesses(processId) {
    if (!isEmpty(processes)) {
      if (processId) {
        const selected = processes.find((x) => x._id === processId);
        if (selected) {
          setSelectedProcess(selected);
        }
      }
      return;
    }
    axios
      .get('/process/search/getSearchProcesses')
      .then(({ data }) => {
        setProcesses(data);
        if (processId) {
          const selected = data.find((x) => x._id === processId);
          if (selected) {
            setSelectedProcess(selected);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function loadAvailableRegisters(registerId, preSelectedColumns, actionId) {
    if (!isEmpty(registers)) {
      if (registerId) {
        const selected = registers.find((x) => x._id === registerId);
        if (selected) {
          selectRegister(selected, preSelectedColumns, actionId);
        }
      }
      return;
    }
    axios
      .get('/registers/general/getUserRegisters')
      .then(({ data }) => {
        setRegisters(data);
        if (registerId) {
          const selected = data.find((x) => x._id === registerId);
          if (selected) {
            selectRegister(selected, preSelectedColumns, actionId);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function selectRegister(register, preSelectedColumns, actionId) {
    setSelectedRegister(register);
    if (!register) return;
    setLoadingRegisterInfo(true);
    axios
      .get('/registers/general/getFillableRegisterColumns', { params: { registerId: register._id } })
      .then(({ data }) => {
        setRegisterColumns(data);
        setLoadingRegisterInfo(false);
        setSelectedColumns(isArray(preSelectedColumns) ? preSelectedColumns : data.map((x) => x.id));
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingRegisterInfo(false);
      });
  }

  function pickUsers() {
    StateManager.selectMultipleUsers(
      (res) => {
        setActionUsers(res.users);
        setActionGroups(res.groups);
        setActionPortalGroups(res.portalGroups);
      },
      {
        initiallySelected: actionUsers,
        initiallySelectedGroups: actionGroups,
        initiallySelectedPortalGroups: actionPortalGroups,
        showGroups: true,
        showPortalGroups: true,
      },
    );
  }

  function done() {
    if (!actionDone) return;

    const action = {
      id: initial?.id || v4(),
      title: actionTitle,
      type: addingActionType,
      actionUsers,
      actionGroups,
      actionPortalGroups,
      days,
      userFieldId,
      dateFieldId,
      toSender,
      toEntryOwner,
    };

    if (addingActionType === 'form') {
      action.formId = selectedForm._id;
    } else if (addingActionType === 'process') {
      action.processId = selectedProcess._id;
    } else if (addingActionType === 'task') {
      action.taskData = taskData;
    } else if (addingActionType === 'notification') {
      action.notificationText = notificationText;
    }

    onResult(action);
    onClose();
    setAddingActionType(null);
    setAddingAction(false);
    setActionTitle('');
    setSelectedForm(null);
    setTaskData(null);
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth className="scroll-bar">
      <DialogTitle>{initial ? 'Edit' : 'New'} action</DialogTitle>
      <DialogContent>
        <Grid container item sx={{ mb: 2, mt: 1 }}>
          <TextField
            value={actionTitle}
            fullWidth
            label={'Action title'}
            onChange={(e) => setActionTitle(e.target.value)}
            required
            id="action-title"
          />
        </Grid>
        <Grid container item sx={{ my: 1 }}>
          <Grid container item>
            <Typography gutterBottom variant="h6">
              Send action to:
            </Typography>
          </Grid>
          <Grid container item>
            <FormControlLabel
              label="The user who started the entry"
              control={<Checkbox checked={toSender} />}
              onChange={(e) => setToSender(e.target.checked)}
            />
          </Grid>
          <Grid container item>
            <FormControlLabel
              label="The owner of the entry/step"
              control={<Checkbox checked={toEntryOwner} />}
              onChange={(e) => setToEntryOwner(e.target.checked)}
            />
          </Grid>
          <Grid container item alignItems={'center'} sx={{ mt: 1 }}>
            <Button variant="outlined" startIcon={<PeopleAltRounded />} onClick={pickUsers} sx={{ mr: 2 }}>
              Pick users
            </Button>

            {!isEmpty(actionUsers) && <UserGroup ids={actionUsers} avatarSize={32} />}
            {!isEmpty(actionGroups) && isArray(actionGroups)
              ? actionGroups.map((group) => <GroupChip id={group} />)
              : null}
            {!isEmpty(actionPortalGroups) && isArray(actionPortalGroups)
              ? actionPortalGroups.map((portal) => <PortalGroupChip id={portal} />)
              : null}
          </Grid>
          {!isEmpty(userFields) && (
            <Grid container item alignItems={'flex-end'} sx={{ my: 1 }}>
              <Typography>Or pick from user field:</Typography>

              <TextField
                variant="standard"
                select
                value={userFieldId}
                onChange={(e) => setUserFieldId(e.target.value)}
                sx={{ minWidth: 100, ml: 2 }}
              >
                {userFields.map((option) => (
                  <MenuItem key={option.id} value={option.id} style={{ color: option.color, fontWeight: 500 }}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid container item alignItems={'flex-end'} sx={{ my: 1 }}>
            <Typography>How many days until it should be due?</Typography>

            <TextField
              value={days}
              type="number"
              variant="standard"
              sx={{ width: 80, mx: 1 }}
              onChange={(e) => {
                const value = e.target.value === '' ? '' : Number(e.target.value);
                if (value !== '' && value < 0) return;
                setDays(value);
              }}
            />

            <Typography>days</Typography>
          </Grid>

          {!isEmpty(dateFields) && (
            <Grid container item alignItems={'flex-end'} sx={{ my: 1 }}>
              <Typography>Or pick from date field:</Typography>

              <TextField
                variant="standard"
                select
                value={dateFieldId}
                onChange={(e) => setDateFieldId(e.target.value)}
                sx={{ minWidth: 100, ml: 2 }}
              >
                {dateFields.map((option) => (
                  <MenuItem key={option.id} value={option.id} style={{ color: option.color, fontWeight: 500 }}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={{ mt: 2 }}>
          <Typography gutterBottom variant="h6">
            What action would you like to perform?
          </Typography>
        </Grid>
        <Grid container item sx={{ mb: 2 }}>
          <Grid container item xs={6} sx={{ p: 1 }}>
            <Button
              onClick={() => addAction('task')}
              fullWidth
              style={{ color: addingActionType === 'task' ? '' : grey[500] }}
              startIcon={<WorkOutlineRounded />}
            >
              Create a task
            </Button>
          </Grid>
          <Grid container item xs={6} sx={{ p: 1 }}>
            <Button
              onClick={() => addAction('process')}
              fullWidth
              style={{ color: addingActionType === 'process' ? '' : grey[500] }}
              startIcon={<TimelineRounded />}
            >
              Start a process
            </Button>
          </Grid>
          <Grid container item xs={6} sx={{ p: 1 }}>
            <Button
              style={{ color: addingActionType === 'form' ? '' : grey[500] }}
              onClick={() => addAction('form')}
              fullWidth
              startIcon={<DescriptionRounded />}
            >
              Start a form
            </Button>
          </Grid>
          <Grid container item xs={6} sx={{ p: 1 }}>
            <Button
              style={{ color: addingActionType === 'notification' ? '' : grey[500] }}
              onClick={() => addAction('notification')}
              fullWidth
              startIcon={<NotificationAddRounded />}
            >
              Send a notification
            </Button>
          </Grid>
        </Grid>

        <Grid container item sx={{ mb: 2 }}>
          {addingActionType === 'form' && (
            <>
              <Autocomplete
                options={forms}
                value={selectedForm}
                fullWidth
                getOptionLabel={(option) => option?.title || ''}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                openOnFocus
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <Grid container alignItems="center">
                      <FormIcon />
                      <Typography style={{ marginLeft: '1rem' }} noWrap>
                        {option.title}
                      </Typography>
                    </Grid>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select a form" />}
                onChange={(e, value) => setSelectedForm(value)}
              />
            </>
          )}

          {addingActionType === 'process' && (
            <>
              <Autocomplete
                options={processes}
                value={selectedProcess}
                getOptionLabel={(option) => option?.title || ''}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                openOnFocus
                fullWidth
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <Grid container alignItems="center">
                      <ProcessIcon />
                      <Typography style={{ marginLeft: '1rem' }} noWrap>
                        {option.title}
                      </Typography>
                    </Grid>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select a process" />}
                onChange={(e, value) => setSelectedProcess(value)}
              />
            </>
          )}

          {addingActionType === 'task' && (
            <Grid container item>
              <TaskParams initial={taskData} onResult={setTaskData} />
            </Grid>
          )}
          {addingActionType === 'notification' && (
            <>
              <Grid container item alignItems="flex-end" style={{ marginTop: '1rem' }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Notification text*"
                  value={notificationText}
                  onChange={(e) => setNotificationText(e.target.value)}
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} hideDone={!actionDone} onDone={done} />
    </RoundedDialog>
  );
}
