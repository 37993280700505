import React, { useState, useEffect, useRef } from 'react';
import { red, grey, blue, amber, green } from '@mui/material/colors';
import { DialogContent, DialogTitle, Typography, TextField, InputBase, CircularProgress, Menu } from '@mui/material';
import { ListItemText, ListItemIcon, IconButton, Checkbox, Chip, Switch, Collapse, useTheme } from '@mui/material';
import { FormControlLabel, Paper, ListItem, Tooltip, useMediaQuery, FormGroup, Button, Grid } from '@mui/material';
import { MenuItem, Badge, FormControl, InputLabel, Select } from '@mui/material';
import { FiberManualRecord, Add, DeleteOutlined, CloseRounded, DoneRounded, ClearRounded } from '@mui/icons-material';
import { TextFieldsRounded, InputRounded, LabelRounded, TableChartRounded } from '@mui/icons-material';
import { FileCopyRounded, Edit, InsertChartRounded, MailOutlineRounded, HelpOutlineRounded } from '@mui/icons-material';
import { EmailRounded, PanoramaRounded, CloudUploadOutlined, CompareArrowsRounded } from '@mui/icons-material';
import {
  InfoRounded,
  FlashOnRounded,
  PersonAddRounded,
  VisibilityRounded,
  PublishedWithChangesRounded,
} from '@mui/icons-material';
import { MoreVertRounded, ScheduleRounded, GroupRounded } from '@mui/icons-material';
import { RoundedDialog, GeneralButton, HtmlContent, StandardDialogActions, ColorPicker, UserChip } from '../Components';
import { FormIcon, RequiredIcon, ProjectIcon, TaskIcon, RequiredGrey, ProcessIcon, ReadUnderstoodIcon } from '../Icons';
import { FieldMapDialog, RichTextField, PicturesCarousel, DebouncedInput } from '../Components';
import { AssetIcon } from '../Icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import EditProjectDialog from './EditProjectDialog';
import EditTaskDialog from './EditTaskDialog';
import PickFieldTypeDialog from './PickFieldTypeDialog';
import fieldTypes from './FieldTypes';
import FieldTaskDialog from './FieldTaskDialog';
import EditDataSetDialog from './EditDataSetDialog';
import Uploader from '../Uploader';
import TableDialog from './TableDialog';
import StateManager from '../StateManager';
import OptionsDialog from './OptionsDialog';
import PeopleOptionsDialog from './PeopleOptionsDialog';
import FormAutocomplete from './FormAutocomplete';
import ProcessAutocomplete from './ProcessAutocomplete';
import DocumentAutocomplete from './DocumentAutocomplete';
import FieldGridBuilderDialog from './FieldGridBuilderDialog';
import HelpDialog from './HelpDialog';
import { validateEmail } from '../Functions';
import { isArray, isFunction, isEmpty } from 'lodash';
import { v4 } from 'uuid';
import axios from 'axios';
import ExpressionDialog from './ExpressionDialog';
import StatusAutomationDialog from './StatusAutomationDialog';
import ConditionsDialog from './ConditionsDialog';
import ActionGroupDialog from './ActionGroupDialog';
import NumberOptionsDialog from './NumberOptionsDialog';
import DocumentsDialog from './DocumentsDialog';
import AutomationDialog from './AutomationDialog';

const OPTION_TYPES = ['dropbox', 'tickbox', 'status', 'weightedList', 'conditional'];

export default function EditField({
  item,
  isDragging,
  allItems,
  onEdit,
  onDelete,
  onAdd,
  onDuplicate,
  onDeleteConditional,
  nested,
  disabled,
  isGlobal,
  hideTask,
  forPortal,
  calculation,
  status,
  kpis,
  peopleEmailOptions,
  forGrid,
  domain,
  listDragging,
  showConditions,
  withActionGroup,
  activityType,
  activityId,
  moveFields,
  currentlyOn,
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const timer = useRef();
  const SAVING_TIMEOUT = 800;
  const background = isDragging ? (theme.palette.mode === 'dark' ? blue[700] : blue[50]) : undefined;

  const [fieldtypeOpen, setFieldtypeOpen] = useState(false);
  const [required, setRequired] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [conditions, setConditions] = useState([]);
  const [conditionalOption, setConditionalOption] = useState({});
  const [options, setOptions] = useState([]);
  const [newCondActionDialog, setNewCondActionDialog] = useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [selectType, setSelectType] = useState('multiple');
  const [displayType, setDisplayType] = useState('');
  const [task, setTask] = useState();
  const [colourPicker, setColourPicker] = useState();
  const [optionParams, setOptionParams] = useState({});
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);
  const [formId, setFormId] = useState('');
  const [formName, setFormName] = useState('');
  const [process, setProcess] = useState(null);
  const [documentId, setDocumentId] = useState('');
  const [documentTitle, setDocumentTitle] = useState('');
  const [fieldTaskDialog, setFieldTaskDialog] = useState(false);
  const [fieldTask, setFieldTask] = useState();
  const [dataSetDialog, setDataSetDialog] = useState(false);
  const [dataSetParams, setDataSetParams] = useState();
  const [tableDialog, setTableDialog] = useState(false);
  const [gridDialog, setGridDialog] = useState(false);
  const [gridParams, setGridParams] = useState(null);
  const [tableParams, setTableParams] = useState();
  const [peopleOptions, setPeopleOptions] = useState(null);
  const [files, setFiles] = useState([]);
  const [helpDialog, setHelpDialog] = useState(false);
  const [helpText, setHelpText] = useState('');
  const [formEmailDialog, setFormEmailDialog] = useState(false);
  const [formOptions, setFormOptions] = useState(null);
  const [formFieldsMap, setFormFieldsMap] = useState(null);
  const [fieldMapDialog, setFieldMapDialog] = useState(false);
  const [picture, setPicture] = useState(null);
  const [uploadingPicture, setUploadingPicture] = useState(false);
  const [allowDuplicating, setAllowDuplicating] = useState(false);
  const [expression, setExpression] = useState(false);
  const [expressionDialog, setExpressionDialog] = useState(false);
  const [statusParams, setStatusParams] = useState(false);
  const [statusAutomationDialog, setStatusAutomationDialog] = useState(false);
  const [conditionsDialog, setConditionsDialog] = useState(false);
  const [condition, setCondition] = useState(null);
  const [actionGroupDialog, setActionGroupDialog] = useState(false);
  const [documentsDialog, setDocumentsDialog] = useState(false);
  const [preSetActions, setPreSetActions] = useState([]);
  const [numberOptions, setNumberOptions] = useState(false);
  const [numberOptionsDialog, setNumberOptionsDialog] = useState(false);
  const [readAndSignOptions, setReadAndSignOptions] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [automationsDialog, setAutomationsDialog] = useState(false);
  const [automations, setAutomations] = useState([]);
  const [asDueDate, setAsDueDate] = useState(false);
  const [asCompletedDate, setAsCompletedDate] = useState(false);
  const [asAssignedUsers, setAsAssignedUsers] = useState(false);
  const [userMultiSelect, setUserMultiSelect] = useState(false);
  const [switchDialog, setSwitchDialog] = useState(false);

  const editOptionBadgeContent = fieldType === 'actionGroup' ? preSetActions.length : null;

  function handleChange(...args) {
    if (!isFunction(onEdit)) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => onEdit(...args), SAVING_TIMEOUT);
  }

  function saveFormOptions(options) {
    setFormOptions(options);
    onEdit(item.id, 'formOptions', options);
  }

  function saveFormFieldsMap(map) {
    setFormFieldsMap(map);

    onEdit(item.id, 'formFieldsMap', map);
  }

  function saveForm(value) {
    if (value._id === formId) return;

    setFormId(value._id || '');
    setFormName(value.title || '');
    setFormFieldsMap(null);
    onEdit(item.id, 'formId', value._id || '');
    onEdit(item.id, 'formName', value.title || '');
    onEdit(item.id, 'formFieldsMap', null);
  }

  function saveRU(value) {
    setDocumentId(value._id || '');
    setDocumentTitle(value.title || '');
    onEdit(item.id, 'documentId', value._id || '');
    onEdit(item.id, 'documentTitle', value.title || '');
  }

  function saveProcess(value) {
    setProcess(value);
    onEdit(item.id, 'processId', value?._id || null);
    onEdit(item.id, 'processTitle', value?.title || null);
  }

  function saveText(value) {
    handleChange(item.id, 'text', value);
  }

  function saveTitle(value) {
    setTitle(value);
    handleChange(item.id, 'title', value);
  }

  function saveFieldType(value) {
    setFieldType(value);
    onEdit(item.id, 'fieldType', value);
  }

  function saveConditions(value) {
    setConditions(value);
    onEdit(item.id, 'conditions', value);
  }

  function saveOptions(value) {
    setOptions(value);
    onEdit(item.id, 'options', value);
  }

  function saveSelectType(value) {
    setSelectType(value);
    onEdit(item.id, 'selectType', value);
  }

  function saveDisplayType(value) {
    setDisplayType(value);
    onEdit(item.id, 'displayType', value);
  }

  function saveRequired(value) {
    setRequired(value);
    onEdit(item.id, 'required', value);
  }

  function saveFieldTask(value) {
    setFieldTask(value);
    onEdit(item.id, 'task', value);
  }

  function saveDataSetParams(value) {
    setDataSetParams(value);
    onEdit(item.id, 'dataSetParams', value);
  }

  function saveTableParams(value) {
    setTableParams(value);
    onEdit(item.id, 'table', value);
  }

  function saveFiles(value) {
    setFiles(value);
    handleChange(item.id, 'files', value);
  }

  function savePeopleOptions(value) {
    setPeopleOptions(value);
    onEdit(item.id, 'peopleOptions', value);
  }

  function saveHelpText(text) {
    setHelpText(text);
    onEdit(item.id, 'helpText', text);
  }

  function saveFieldGrid(table) {
    setGridParams(table);
    onEdit(item.id, 'table', table);
  }

  function saveAllowDuplicating(value) {
    setAllowDuplicating(value);
    onEdit(item.id, 'allowDuplicating', value);
  }

  function saveExpression(value) {
    setExpression(value);
    onEdit(item.id, 'expression', value);
  }

  function saveStatusParams(value) {
    setStatusParams(value);
    onEdit(item.id, 'statusParams', value);
  }

  function saveCondition(value) {
    setCondition(value);
    onEdit(item.id, 'condition', value);
  }

  function saveActions(value) {
    setPreSetActions(value);
    onEdit(item.id, 'preSetActions', value);
  }

  function saveNumberOptions(value) {
    setNumberOptions(value);
    onEdit(item.id, 'numberOptions', value);
  }

  function saveReadAndSignOptions(value) {
    setReadAndSignOptions(value);
    onEdit(item.id, 'readAndSignOptions', value);
  }

  function saveAutomations(value) {
    setAutomations(value);
    onEdit(item.id, 'automations', value);
  }

  function saveAsDueDate(value) {
    setAsDueDate(value);
    onEdit(item.id, 'asDueDate', value);
  }

  function saveAsCompletedDate(value) {
    setAsCompletedDate(value);
    onEdit(item.id, 'asCompletedDate', value);
  }

  function saveAsAssignedUsers(value) {
    setAsAssignedUsers(value);
    onEdit(item.id, 'asAssignedUsers', value);
  }

  function saveUserMultiSelect(value) {
    setUserMultiSelect(value);
    onEdit(item.id, 'userMultiSelect', value);
  }

  function saveTemplateId(value) {
    let index = conditions.findIndex((x) => x.id === conditionalOption.id);
    if (index > -1) {
      conditions[index].templateId = value;
      conditions[index].task = null;
      setConditionalOption({ ...conditions[index] });
      saveConditions([...conditions]);
    }
  }

  function saveTask(value) {
    let index = conditions.findIndex((x) => x.id === conditionalOption.id);
    if (index > -1) {
      conditions[index].task = value;
      conditions[index].templateId = null;
      setConditionalOption({ ...conditions[index] });
      saveConditions([...conditions]);
    }
  }

  function deleteTask() {
    let index = conditions.findIndex((x) => x.id === conditionalOption.id);
    if (index > -1) {
      conditions[index].task = null;
      setConditionalOption({ ...conditions[index] });
      saveConditions([...conditions]);
    }
  }

  function deleteTemplateId() {
    let index = conditions.findIndex((x) => x.id === conditionalOption.id);
    if (index > -1) {
      conditions[index].templateId = null;
      setConditionalOption({ ...conditions[index] });
      saveConditions([...conditions]);
    }
  }

  function deleteCondition(id) {
    saveConditions(conditions.filter((x) => x.id !== id));
    onDeleteConditional(id);
    setConditionalOption({});
  }

  function addCondition(result) {
    saveConditions([...conditions, result]);
  }

  function handleOptions(result) {
    if (result.options) {
      saveOptions(result.options);
    }
    if (result.selectType) {
      saveSelectType(result.selectType);
    }
    if (result.displayType) {
      saveDisplayType(result.displayType);
    }
  }

  function handleFieldType(type) {
    saveFieldType(type);

    if (['dropbox', 'tickbox', 'weightedList', 'status'].includes(type)) {
      setOptionParams({ options, type, selectType, displayType });
      setOptionsDialogOpen(true);
    } else if (type === 'dataSet') {
      setDataSetDialog(true);
    } else if (type === 'table') {
      setTableDialog(true);
    } else if (type === 'actionGroup') {
      setActionGroupDialog(true);
    } else if (type === 'readAndSign') {
      setDocumentsDialog(true);
    }
  }

  function editOptions() {
    if (fieldType === 'dataSet') {
      setDataSetDialog(true);
    } else if (fieldType === 'table') {
      setTableDialog(true);
    } else if (fieldType === 'calculation') {
      setExpressionDialog(true);
    } else if (fieldType === 'people') {
      setOptionsDialogOpen(true);
    } else if (fieldType === 'actionGroup') {
      setActionGroupDialog(true);
    } else if (fieldType === 'number') {
      setNumberOptionsDialog(true);
    } else if (fieldType === 'readAndSign') {
      setDocumentsDialog(true);
    } else {
      setOptionParams({ options, type: fieldType, selectType, displayType });
      setOptionsDialogOpen(true);
    }
  }

  function confirmDelete() {
    if (!title && !fieldType) {
      onDelete(item.id);
    } else {
      StateManager.setConfirm('You are about to delete this field', () => onDelete(item.id));
    }
  }

  function uploadPicture(event) {
    const [file] = event.target.files;
    if (!file || !file.type?.startsWith('image/') || uploadingPicture) return;

    setUploadingPicture(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        const pic = {
          id: v4(),
          name: res.data.file.originalname,
          originalname: res.data.file.originalname,
          location: res.data.file.location,
          size: res.data.file.size,
          type: res.data.file.mimetype,
          mimetype: res.data.file.mimetype,
          bucket: res.data.file.bucket,
        };
        setPicture(pic);
        handleChange(item.id, 'picture', pic);
        setUploadingPicture(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploadingPicture(false);
      });
  }

  const calculatableFields = isArray(allItems)
    ? allItems.filter(({ fieldType }) => ['number', 'date', 'weightedList', 'time'].includes(fieldType))
    : [];

  const numberFields = isArray(allItems)
    ? allItems.filter(({ fieldType }) => ['number', 'calculation', 'weightedList'].includes(fieldType))
    : [];

  useEffect(() => {
    if (!item) return;
    setRequired(item.required || false);
    setTitle(item.title || '');
    setText(item.text || '');
    setFieldType(item.fieldType || '');
    setConditionalOption({});
    setFormId(item.formId || '');
    setFormName(item.formName || '');
    setFormOptions(item.formOptions || null);
    setFormFieldsMap(item.formFieldsMap || null);
    setDocumentId(item.documentId || '');
    setDocumentTitle(item.documentTitle || '');
    setProcess(item.processId ? { _id: item.processId, title: item.processTitle } : null);
    setFieldTask(item.task || '');
    setHelpText(item.helpText || '');
    setPicture(item.picture || null);
    setExpression(item.expression || null);
    setStatusParams(item.statusParams || null);
    setCondition(item.condition || null);
    setPreSetActions(item.preSetActions || []);
    setNumberOptions(item.numberOptions || null);
    setReadAndSignOptions(item.readAndSignOptions || null);
    setAutomations(item.automations || null);
    setAsDueDate(item.asDueDate || false);
    setAsCompletedDate(item.asCompletedDate || false);
    setAsAssignedUsers(item.asAssignedUsers || false);
    setUserMultiSelect(item.userMultiSelect || false);

    if (item.fieldType === 'conditional') setConditions(item.conditions || []);
    if (['dropbox', 'tickbox', 'weightedList', 'status'].includes(item.fieldType)) setOptions(item.options || []);
    if (item.fieldType === 'tickbox') {
      setSelectType(item.selectType || 'multiple');
      setDisplayType(item.displayType || 'tickbox');
    }
    if (item.fieldType === 'dataSet') setDataSetParams(item.dataSetParams);
    if (item.fieldType === 'table') setTableParams(item.table || item.tableParams);
    if (item.fieldType === 'people') {
      setPeopleOptions(item.peopleOptions);
    }
    if (item.type === 'resources') setFiles(item.files || []);
    if (item.type === 'table') setGridParams(item.table);
    if (item.type === 'info') {
      setText(item.text || '');
      setFiles(item.files || []);
    }
  }, [item]);

  const FieldOptions = disabled ? null : (
    <>
      <Tooltip title="Delete field" placement="top">
        <IconButton onClick={confirmDelete} fontSize="small">
          <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
        </IconButton>
      </Tooltip>

      <Tooltip title="More" placement="top">
        <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)} fontSize="small">
          <Badge
            variant="dot"
            color="primary"
            invisible={
              !helpText &&
              !condition &&
              !statusParams?.setAutomatically &&
              isEmpty(automations) &&
              !asAssignedUsers &&
              !asDueDate &&
              !asCompletedDate &&
              !userMultiSelect
            }
          >
            <MoreVertRounded fontSize="small" style={{ color: grey[500] }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
        {(activityType === 'form' || activityType === 'process') && (
          <MenuItem
            onClick={() => {
              setSwitchDialog(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <PublishedWithChangesRounded />
            </ListItemIcon>
            <ListItemText>{'Move field'}</ListItemText>
          </MenuItem>
        )}

        {(item.type === 'field' || item.type === 'form') && fieldType !== 'actionGroup' && (
          <MenuItem
            onClick={() => {
              saveRequired(!required);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <RequiredGrey />
            </ListItemIcon>
            <ListItemText>{required ? 'Make not required' : 'Make required'}</ListItemText>
          </MenuItem>
        )}

        {OPTION_TYPES.includes(fieldType) && (
          <MenuItem
            onClick={() => {
              setMenuAnchor(null);
              setAutomationsDialog(true);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={isEmpty(automations)}>
                <FlashOnRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>Automations</ListItemText>
          </MenuItem>
        )}

        {item.type === 'field' && (
          <MenuItem
            onClick={() => {
              setHelpDialog(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={!helpText}>
                <HelpOutlineRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>{helpText ? 'Edit help text' : 'Add help text'}</ListItemText>
          </MenuItem>
        )}

        {fieldType === 'status' && item.type === 'field' && (
          <MenuItem
            onClick={() => {
              setStatusAutomationDialog(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={!statusParams?.setAutomatically}>
                <FlashOnRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>Status automations</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            onDuplicate(item.id);
            setMenuAnchor(null);
          }}
        >
          <ListItemIcon>
            <FileCopyRounded style={{ color: grey[500] }} />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setConditionsDialog(true);
            setMenuAnchor(null);
          }}
        >
          <ListItemIcon>
            <Badge variant="dot" color="primary" invisible={!condition}>
              <VisibilityRounded style={{ color: grey[500] }} />
            </Badge>
          </ListItemIcon>
          <ListItemText>Hide/show logic</ListItemText>
        </MenuItem>

        {activityType === 'form' && (fieldType === 'date' || fieldType === 'datetime') && (
          <MenuItem
            onClick={() => {
              saveAsDueDate(!asDueDate);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={!asDueDate}>
                <ScheduleRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>{asDueDate ? 'Do NOT use ' : 'Use '}as due date</ListItemText>
          </MenuItem>
        )}

        {(fieldType === 'date' || fieldType === 'datetime') && (
          <MenuItem
            onClick={() => {
              saveAsCompletedDate(!asCompletedDate);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={!asCompletedDate}>
                <ScheduleRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>{asCompletedDate ? 'Do NOT use ' : 'Use '}as completed date</ListItemText>
          </MenuItem>
        )}

        {fieldType === 'user' && (
          <MenuItem
            onClick={() => {
              saveUserMultiSelect(!userMultiSelect);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={!userMultiSelect}>
                <GroupRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>{userMultiSelect ? 'Do NOT allow ' : 'Allow '} multi select</ListItemText>
          </MenuItem>
        )}

        {activityType === 'form' && fieldType === 'user' && (
          <MenuItem
            onClick={() => {
              saveAsAssignedUsers(!asAssignedUsers);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon>
              <Badge variant="dot" color="primary" invisible={!asAssignedUsers}>
                <GroupRounded style={{ color: grey[500] }} />
              </Badge>
            </ListItemIcon>
            <ListItemText>{asAssignedUsers ? 'Do NOT use ' : 'Use '}as assigned user</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <SwitchParent
        open={switchDialog}
        onClose={() => setSwitchDialog(false)}
        activityId={activityId}
        fieldId={item.id}
        onDone={moveFields}
        activityType={activityType}
        currentlyOn={currentlyOn}
      />
      <AutomationDialog
        open={automationsDialog}
        onClose={() => setAutomationsDialog(false)}
        field={{ fieldType, options, conditions, expressionParams: expression }}
        initial={automations}
        onResult={saveAutomations}
        allFields={allItems}
      />

      <HelpDialog open={helpDialog} onClose={() => setHelpDialog(false)} initial={helpText} onResult={saveHelpText} />

      {showConditions && (
        <>
          <ConditionsDialog
            open={conditionsDialog}
            onClose={() => setConditionsDialog(false)}
            items={
              isArray(allItems) ? allItems.filter((x) => x.type === 'field' && x.fieldType !== 'actionGroup') : allItems
            }
            withPortal={true}
            onResult={saveCondition}
            initial={condition}
          />
        </>
      )}
      {fieldType === 'status' && (
        <StatusAutomationDialog
          initial={statusParams}
          numberFields={numberFields}
          options={options}
          onResult={saveStatusParams}
          open={statusAutomationDialog}
          onClose={() => setStatusAutomationDialog(false)}
        />
      )}
      {fieldType === 'actionGroup' && (
        <ActionGroupDialog
          open={actionGroupDialog}
          onClose={() => setActionGroupDialog(false)}
          initial={preSetActions}
          onResult={saveActions}
          allFields={allItems}
          withSurveys={true}
        />
      )}
      {fieldType === 'readAndSign' && (
        <DocumentsDialog
          open={documentsDialog}
          onClose={() => setDocumentsDialog(false)}
          onResult={saveReadAndSignOptions}
          initial={readAndSignOptions}
        />
      )}
    </>
  );

  if (item.type === 'field') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && fieldType !== 'actionGroup' && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid container sx={{ p: 2 }}>
            <Grid item container alignItems="center">
              <Grid item container lg={7} md={7} sm={12} alignItems="center" wrap="nowrap">
                <LabelRounded style={{ color: amber[500] }} />
                {disabled ? (
                  <Typography variant="h6" style={{ marginLeft: '1em', flexGrow: 1 }}>
                    {title}
                  </Typography>
                ) : (
                  <DebouncedInput
                    value={title}
                    placeholder="Add a field title"
                    variant="outlined"
                    sx={{ flexGrow: 1, ml: 1 }}
                    onValueChange={(value) => saveTitle(value)}
                    multiline
                    minRows={1}
                    maxRows={5}
                    debounceMs={400}
                  />
                )}
              </Grid>
              <Grid
                style={{ marginTop: theme.spacing(largeDevices ? 0 : 2) }}
                item
                container
                lg={5}
                md={5}
                sm={12}
                alignItems="center"
                wrap="nowrap"
                justifyContent="flex-end"
              >
                <Tooltip title={fieldType ? 'Change field type' : ''} placement="top">
                  <Button
                    onClick={() => setFieldtypeOpen(true)}
                    style={{ textTransform: 'none', color: grey[500] }}
                    startIcon={fieldType ? fieldTypes[fieldType]?.icon : <FiberManualRecord />}
                    disabled={disabled || isGlobal}
                  >
                    {fieldType ? fieldTypes[fieldType]?.text : 'Pick a type'}
                  </Button>
                </Tooltip>
                <PickFieldTypeDialog
                  open={fieldtypeOpen}
                  onClose={() => setFieldtypeOpen(false)}
                  onResult={handleFieldType}
                  excludedTypes={
                    forGrid
                      ? ['conditional', 'stock', 'status', 'action', 'calculation', 'dataSet', 'people']
                      : [nested && 'conditional', 'stock', !status && 'status', 'action', !calculation && 'calculation']
                  }
                  withActionGroup={withActionGroup}
                />

                {[
                  'dropbox',
                  'tickbox',
                  'dataSet',
                  'table',
                  'people',
                  'weightedList',
                  'calculation',
                  'status',
                  'actionGroup',
                  'number',
                  'readAndSign',
                ].includes(fieldType) &&
                  !disabled &&
                  !isGlobal && (
                    <Tooltip title="Edit options" placement="top">
                      <IconButton onClick={editOptions} fontSize="small" style={{ marginLeft: theme.spacing(1) }}>
                        <Badge
                          badgeContent={editOptionBadgeContent}
                          color="primary"
                          invisible={editOptionBadgeContent == null}
                        >
                          <Edit fontSize="small" style={{ color: grey[500] }} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  )}
                {fieldType === 'number' && (
                  <NumberOptionsDialog
                    open={numberOptionsDialog}
                    onClose={() => setNumberOptionsDialog(false)}
                    initial={numberOptions}
                    onResult={saveNumberOptions}
                  />
                )}

                {FieldOptions}
              </Grid>
            </Grid>
            {fieldType === 'conditional' && !nested && (
              <Grid item container style={{ marginTop: '1rem' }}>
                <Grid item container alignItems="center" justifyContent="space-between">
                  <Typography variant="h6">Actions:</Typography>
                  <GeneralButton
                    onClick={() => setNewCondActionDialog(true)}
                    startIcon={<Add style={{ color: grey[500] }} />}
                  >
                    Add action
                  </GeneralButton>
                </Grid>

                <Grid item container alignItems="center" style={{ margin: theme.spacing(1, 0) }}>
                  {conditions.map((option) => (
                    <Paper
                      key={option.id}
                      elevation={conditionalOption.id === option.id ? 4 : 2}
                      style={{ borderRadius: 8, margin: theme.spacing(1) }}
                    >
                      <ListItem
                        button
                        style={{
                          borderRadius: 8,
                          padding: theme.spacing(1, 2),
                          border: conditionalOption.id === option.id ? `2px solid ${blue[900]}` : '',
                        }}
                        onClick={() => setConditionalOption({ ...option })}
                      >
                        <ListItemIcon>
                          <FiberManualRecord style={{ color: option.color }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography style={{ fontWeight: conditionalOption.id === option.id ? 700 : 500 }}>
                              {option.text}
                            </Typography>
                          }
                          disableTypography
                        />
                      </ListItem>
                    </Paper>
                  ))}
                </Grid>
                {conditionalOption.id && (
                  <Grid item container>
                    <Grid
                      item
                      container
                      alignItems="center"
                      style={{ paddingTop: theme.spacing(2), borderTop: `2px solid ${grey[300]}` }}
                    >
                      <Tooltip title={<Typography>Select color</Typography>} placement="top" arrow>
                        <IconButton
                          style={{ height: 'fit-content', marginRight: 10 }}
                          onClick={(e) => setColourPicker(e.currentTarget)}
                        >
                          <FiberManualRecord style={{ color: conditionalOption.color }} />
                        </IconButton>
                      </Tooltip>
                      <ColorPicker
                        anchor={colourPicker}
                        open={Boolean(colourPicker)}
                        onClose={(color) => {
                          if (color?.color) {
                            let value = { ...conditionalOption, color: color?.color };
                            setConditionalOption(value);
                            let index = conditions.findIndex((x) => x.id === conditionalOption.id);
                            if (index > -1) {
                              conditions[index] = value;
                              saveConditions([...conditions]);
                            }
                          }
                          setColourPicker(null);
                        }}
                      />
                      <TextField
                        label="Action name"
                        value={conditionalOption.text}
                        onChange={(e) => {
                          let value = { ...conditionalOption, text: e.target.value };
                          setConditionalOption(value);
                          let index = conditions.findIndex((x) => x.id === conditionalOption.id);
                          if (index > -1) {
                            conditions[index] = value;
                            saveConditions([...conditions]);
                          }
                        }}
                        variant="standard"
                        inputProps={{ maxLength: 32 }}
                      />

                      <IconButton
                        onClick={() => deleteCondition(conditionalOption.id)}
                        style={{ height: 'fit-content', marginLeft: 'auto' }}
                      >
                        <DeleteOutlined style={{ color: red[500] }} />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        marginTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                        borderBottom: `2px solid ${grey[300]}`,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={Boolean(conditionalOption.notApplicable)}
                            onChange={(e) => {
                              let value = {
                                id: conditionalOption.id,
                                notApplicable: e.target.checked,
                                text: conditionalOption.text,
                                color: conditionalOption.color,
                              };
                              if (!Boolean(conditionalOption.notApplicable)) {
                                onDeleteConditional(conditionalOption.id);
                              }
                              setConditionalOption(value);
                              let index = conditions.findIndex((x) => x.id === conditionalOption.id);
                              if (index > -1) {
                                conditions[index] = value;
                                saveConditions([...conditions]);
                              }
                            }}
                          />
                        }
                        label="Not applicable"
                      />
                      {!conditionalOption.notApplicable && (
                        <GeneralButton
                          endIcon={<ProjectIcon />}
                          style={{
                            fontWeight: conditionalOption.templateId ? 700 : 400,
                            marginLeft: 'auto',
                            border: conditionalOption.templateId ? `2px solid ${blue[900]}` : '',
                          }}
                          onClick={() => {
                            setTemplateId(conditionalOption.templateId);
                            setProjectDialogOpen(true);
                          }}
                        >
                          Create a project
                        </GeneralButton>
                      )}

                      {!conditionalOption.notApplicable && (
                        <GeneralButton
                          endIcon={<TaskIcon />}
                          style={{
                            fontWeight: conditionalOption.task ? 700 : 400,
                            marginLeft: 15,
                            border: conditionalOption.task ? `2px solid ${blue[900]}` : '',
                          }}
                          onClick={() => {
                            setTask(conditionalOption.task);
                            setTaskDialogOpen(true);
                          }}
                        >
                          Create a task
                        </GeneralButton>
                      )}
                    </Grid>
                    <Grid item container>
                      <Droppable droppableId={item.id} type="nestedFields">
                        {(provided) => (
                          <Grid item container {...provided.droppableProps} ref={provided.innerRef}>
                            {allItems
                              .filter((x) => x.conditionalFieldId === conditionalOption.id)
                              .map((nestedItem, index) => (
                                <Draggable key={nestedItem.id} draggableId={nestedItem.id} index={index}>
                                  {(provided, snapshot) => (
                                    <Grid
                                      container
                                      item
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <EditField
                                        item={nestedItem}
                                        isDragging={snapshot.isDragging}
                                        onEdit={onEdit}
                                        onDelete={onDelete}
                                        onDuplicate={onDuplicate}
                                        showConditions={showConditions}
                                        allItems={allItems}
                                        withActionGroup={withActionGroup}
                                        nested
                                        kpis={kpis}
                                        activityType={activityType}
                                      />
                                    </Grid>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </Grid>
                    {!conditionalOption.notApplicable && (
                      <Grid item container style={{ marginTop: theme.spacing(2) }}>
                        <GeneralButton
                          startIcon={<InputRounded style={{ color: grey[700] }} />}
                          onClick={() => onAdd(conditionalOption.id, 'field')}
                        >
                          Add a field
                        </GeneralButton>
                        <GeneralButton
                          style={{ marginLeft: '1rem' }}
                          startIcon={<TextFieldsRounded style={{ color: grey[700] }} />}
                          onClick={() => onAdd(conditionalOption.id, 'text')}
                        >
                          Add help text
                        </GeneralButton>
                        {!hideTask && (
                          <GeneralButton
                            style={{ marginLeft: '1rem' }}
                            startIcon={<TaskIcon />}
                            onClick={() => onAdd(conditionalOption.id, 'task')}
                          >
                            Add a task
                          </GeneralButton>
                        )}
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          {fieldType === 'dataSet' && (
            <EditDataSetDialog
              open={dataSetDialog}
              onClose={() => setDataSetDialog(false)}
              onResult={saveDataSetParams}
              initial={dataSetParams}
            />
          )}
          {fieldType === 'table' && (
            <TableDialog
              open={tableDialog}
              onClose={() => setTableDialog(false)}
              onResult={saveTableParams}
              initial={tableParams}
            />
          )}

          {['dropbox', 'tickbox', 'weightedList', 'status'].includes(fieldType) && (
            <OptionsDialog
              initialOptions={optionParams.options}
              initialSelectType={optionParams.selectType}
              initialDisplayType={optionParams.displayType}
              open={optionsDialogOpen}
              onClose={() => {
                setOptionsDialogOpen(false);
                setOptionParams({});
              }}
              onResult={handleOptions}
              isTickbox={optionParams.type === 'tickbox'}
              isWeighted={optionParams.type === 'weightedList'}
              withColors={optionParams.type === 'status'}
              withDisplayType
            />
          )}

          {fieldType === 'conditional' && (
            <>
              <ActionDialog
                open={newCondActionDialog}
                onClose={() => setNewCondActionDialog(false)}
                onResult={addCondition}
              />
              <EditProjectDialog
                open={projectDialogOpen}
                onClose={() => setProjectDialogOpen(false)}
                onResult={saveTemplateId}
                selectedId={templateId}
                onDelete={deleteTemplateId}
              />
              <EditTaskDialog
                open={taskDialogOpen}
                onClose={() => setTaskDialogOpen(false)}
                onResult={saveTask}
                task={task}
                onDelete={deleteTask}
              />
            </>
          )}
          {fieldType === 'calculation' && (
            <ExpressionDialog
              open={expressionDialog}
              columns={calculatableFields}
              numberFields={numberFields}
              onClose={() => setExpressionDialog(false)}
              initial={expression}
              onResult={saveExpression}
            />
          )}
          {fieldType === 'people' && (
            <PeopleOptionsDialog
              initial={peopleOptions}
              open={optionsDialogOpen}
              onClose={() => setOptionsDialogOpen(false)}
              onResult={savePeopleOptions}
            />
          )}
        </Paper>
        {kpis && kpis.find((x) => x.fieldId === item.id) && (
          <Tooltip title="This field is used in a KPI">
            <InsertChartRounded style={{ color: grey[400], position: 'absolute', right: -10 }} />
          </Tooltip>
        )}
      </Grid>
    );
  }

  if (item.type === 'info') {
    return (
      <Grid container item component={Paper} sx={{ my: 1.5, p: 2 }} style={{ background }} elevation={3}>
        <Grid item container alignItems="center" style={{ marginBottom: theme.spacing(1) }}>
          <InfoRounded style={{ color: grey[700] }} />
          <DebouncedInput
            onValueChange={(value) => saveTitle(value)}
            value={title}
            placeholder="Add a field title"
            sx={{ flexGrow: 1, mx: 1 }}
          />
          {FieldOptions}
        </Grid>
        <Grid item container alignContent="flex-start">
          <Grid container item xs={8}>
            <RichTextField
              editable={!isDragging && !listDragging}
              placeholder="Click to add text"
              onChange={saveText}
              initial={text}
            />
          </Grid>
          <Grid container item xs={4} style={{ paddingLeft: 4 }}>
            <PicturesCarousel editable pictures={files} onChange={saveFiles} />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <FormControlLabel
            label="Allow the person filling out the form to duplicate this field"
            control={<Checkbox color="primary" checked={allowDuplicating} />}
            onChange={(e) => saveAllowDuplicating(e.target.checked)}
          />
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'text') {
    return (
      <Grid container item component={Paper} sx={{ my: 1.5, p: 2 }} style={{ background }} elevation={3}>
        <Grid item container alignItems="center" style={{ marginBottom: theme.spacing(2) }}>
          <TextFieldsRounded style={{ color: grey[700] }} />
          <DebouncedInput
            onValueChange={(value) => saveTitle(value)}
            value={title}
            placeholder="Add a field title"
            variant="outlined"
            sx={{ flexGrow: 1, mx: 1 }}
            inputProps={{ maxLength: 128 }}
          />
          {FieldOptions}
        </Grid>
        <RichTextField
          editable={!isDragging && !listDragging}
          initial={text}
          onChange={saveText}
          placeholder="Click to add text"
        />
      </Grid>
    );
  }

  if ((item.type === 'text' && forGrid) || item.type === 'styledText') {
    return (
      <Grid container item>
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="baseline" sx={{ p: 2 }}>
            <TextField
              size="small"
              placeholder="Add a text"
              multiline
              inputProps={{
                style: {
                  fontSize: item.titleStyle?.fontSize || 16,
                  textAlign: item.titleStyle?.textAlign || undefined,
                  fontStyle: item.titleStyle?.italic ? 'italic' : undefined,
                  color: item.titleStyle?.color || undefined,
                  textDecoration: item.titleStyle?.underline ? 'underline' : undefined,
                  fontWeight: item.titleStyle?.bold ? 600 : undefined,
                },
              }}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                saveText(e.target.value);
              }}
              style={{ flexGrow: 1 }}
            />
            <IconButton onClick={confirmDelete} style={{ marginLeft: theme.spacing(1) }}>
              <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
            </IconButton>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'form') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <Grid item container alignItems="center">
              <FormIcon />
              <FormAutocomplete
                formId={formId}
                formName={formName}
                saveForm={saveForm}
                disabled={disabled || forPortal}
              />
              {!disabled && (
                <>
                  <Tooltip title="Email form when completed" placement="top">
                    <IconButton onClick={() => setFormEmailDialog(true)}>
                      {formOptions ? (
                        <EmailRounded style={{ color: blue[900] }} />
                      ) : (
                        <MailOutlineRounded style={{ color: grey[500] }} />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Link fields together" placement="top">
                    <IconButton onClick={() => setFieldMapDialog(true)}>
                      <Badge variant="dot" color="error" invisible={!formFieldsMap}>
                        <CompareArrowsRounded style={{ color: formFieldsMap ? blue[900] : grey[500] }} />
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  <FieldMapDialog
                    open={fieldMapDialog}
                    onClose={() => setFieldMapDialog(false)}
                    onResult={saveFormFieldsMap}
                    initialMap={formFieldsMap}
                    domain={domain}
                    codomainFormId={formId}
                  />

                  <EmailOptionsDialog
                    open={formEmailDialog}
                    onClose={() => setFormEmailDialog(false)}
                    onResult={saveFormOptions}
                    peopleEmailOptions={peopleEmailOptions}
                    initial={formOptions}
                  />

                  {FieldOptions}
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'ru') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <Grid item container alignItems="center">
              <ReadUnderstoodIcon />
              <DocumentAutocomplete
                documentId={documentId}
                documentTitle={documentTitle}
                saveRU={saveRU}
                disabled={disabled || forPortal}
              />
              {!disabled && (
                <>
                  <Tooltip title={required ? 'Make not required' : 'Make required'} placement="top">
                    <IconButton onClick={() => saveRequired(!required)}>
                      <RequiredGrey />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Duplicate" placement="top">
                    <IconButton onClick={() => onDuplicate(item.id)}>
                      <FileCopyRounded style={{ color: grey[500] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete field" placement="top">
                    <IconButton onClick={() => onDelete(item.id)}>
                      <DeleteOutlined style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'table') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <TableChartRounded style={{ color: grey[700], marginRight: 16 }} />
            <DebouncedInput
              value={title}
              placeholder="Add a table title"
              variant="outlined"
              sx={{ flexGrow: 1, ml: 1 }}
              onValueChange={(value) => saveTitle(value)}
              inputProps={{ maxLength: 64 }}
            />
            <Tooltip title="Edit table" placement="top">
              <IconButton onClick={() => setGridDialog(true)} fontSize="small" style={{ marginLeft: 'auto' }}>
                <Edit fontSize="small" style={{ color: grey[500] }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Duplicate" placement="top">
              <IconButton onClick={() => onDuplicate(item.id)} fontSize="small" sx={{ ml: 1 }}>
                <FileCopyRounded fontSize="small" style={{ color: grey[500] }} />
              </IconButton>
            </Tooltip>
            {showConditions && (
              <>
                <Tooltip title="Hide/show logic" placement="top">
                  <IconButton size="small" onClick={() => setConditionsDialog(true)}>
                    <VisibilityRounded fontSize="small" style={{ color: condition ? blue[900] : grey[500] }} />
                  </IconButton>
                </Tooltip>
                <ConditionsDialog
                  open={conditionsDialog}
                  onClose={() => setConditionsDialog(false)}
                  items={allItems}
                  onResult={saveCondition}
                  initial={condition}
                />
              </>
            )}
            <IconButton onClick={confirmDelete} fontSize="small" sx={{ ml: 1 }}>
              <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
            </IconButton>
          </Grid>
        </Paper>
        <FieldGridBuilderDialog
          open={gridDialog}
          onClose={() => setGridDialog(false)}
          onResult={saveFieldGrid}
          initial={gridParams}
          itemId={item.id}
          allItems={allItems}
        />
      </Grid>
    );
  }

  if (item.type === 'process') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <Grid item container alignItems="center">
              <ProcessIcon />
              <ProcessAutocomplete
                processId={process?._id}
                processName={process?.title}
                saveProcess={saveProcess}
                disabled={disabled || forPortal}
              />
              {!disabled && (
                <>
                  <Tooltip title={required ? 'Make not required' : 'Make required'} placement="top">
                    <IconButton onClick={() => saveRequired(!required)}>
                      <RequiredGrey />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Duplicate" placement="top">
                    <IconButton onClick={() => onDuplicate(item.id)}>
                      <FileCopyRounded style={{ color: grey[500] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete field" placement="top">
                    <IconButton onClick={() => onDelete(item.id)}>
                      <DeleteOutlined style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'task') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <Grid item container alignItems="center">
              <TaskIcon />
              {disabled ? (
                <Typography variant="h6" style={{ marginLeft: '1em', flexGrow: 1 }}>
                  {title}
                </Typography>
              ) : (
                <DebouncedInput
                  value={title}
                  placeholder="Add a task title"
                  variant="outlined"
                  sx={{ flexGrow: 1, ml: 1 }}
                  onValueChange={(value) => saveTitle(value)}
                  inputProps={{ maxLength: 64 }}
                />
              )}

              <Tooltip title="More details" placement="top">
                <IconButton
                  onClick={(e) => {
                    setFieldTaskDialog(true);
                  }}
                  fontSize="small"
                  style={{ marginLeft: theme.spacing(2) }}
                >
                  <Add style={{ color: grey[500] }} />
                </IconButton>
              </Tooltip>
              {!disabled && (
                <>
                  <Tooltip title={required ? 'Make not required' : 'Make required'} placement="top">
                    <IconButton onClick={() => saveRequired(!required)} fontSize="small">
                      <RequiredGrey />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Duplicate" placement="top">
                    <IconButton onClick={() => onDuplicate(item.id)} fontSize="small">
                      <FileCopyRounded fontSize="small" style={{ color: grey[500] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete field" placement="top">
                    <IconButton onClick={() => onDelete(item.id)} fontSize="small">
                      <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
        <FieldTaskDialog
          open={fieldTaskDialog}
          onClose={() => setFieldTaskDialog(false)}
          onResult={saveFieldTask}
          task={fieldTask}
        />
      </Grid>
    );
  }

  if (item.type === 'assetIncident') {
    return (
      <Grid container item>
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" wrap="nowrap" sx={{ p: 2 }}>
            <AssetIcon style={{ marginRight: 16 }} />
            <DebouncedInput
              onValueChange={(value) => saveTitle(value)}
              value={title}
              placeholder="Add a field title"
              variant="outlined"
              sx={{ flexGrow: 1, ml: 1 }}
              inputProps={{ maxLength: 128 }}
            />
            <IconButton onClick={confirmDelete} style={{ marginLeft: 'auto' }}>
              <DeleteOutlined style={{ color: red[500] }} />
            </IconButton>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'resources') {
    return (
      <Grid container item>
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <Grid item container alignItems="center" style={{ marginBottom: theme.spacing(2) }}>
              <FileCopyRounded style={{ color: blue[900] }} />
              <DebouncedInput
                onValueChange={(value) => saveTitle(value)}
                value={title}
                placeholder="Add a field title"
                variant="outlined"
                sx={{ flexGrow: 1, ml: 1 }}
                inputProps={{ maxLength: 128 }}
              />
              {FieldOptions}
            </Grid>
            <Uploader onChange={saveFiles} uploaded={files} withLinkedFeatures={true} />
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'image') {
    return (
      <Grid container item>
        <Paper id={item.id} elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2 }}>
            <Grid item container alignItems="center" style={{ marginBottom: theme.spacing(2) }}>
              <PanoramaRounded style={{ color: grey[500] }} />
              <DebouncedInput
                onValueChange={(value) => saveTitle(value)}
                value={title}
                placeholder="Add a field title"
                variant="outlined"
                sx={{ flexGrow: 1, ml: 1 }}
                inputProps={{ maxLength: 128 }}
              />
              <IconButton style={{ marginLeft: theme.spacing(2) }} component="label">
                {uploadingPicture ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <CloudUploadOutlined fontSize="small" style={{ color: grey[500] }} />
                )}
                <input
                  accept="image/*"
                  name="img"
                  id="cover-photo"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={uploadPicture}
                />
              </IconButton>
              <IconButton onClick={confirmDelete}>
                <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
              </IconButton>
            </Grid>
            {picture && <img alt={picture.name} src={picture.location} style={{ maxWidth: '100%' }} />}
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid container item>
      <Paper elevation={3} style={{ background }} sx={{ my: 1.5, width: '100%' }}>
        <Grid item container alignItems="center" sx={{ p: 2 }}>
          Item {item.type} not supported yet
        </Grid>
      </Paper>
    </Grid>
  );
}

function ActionDialog({ open, onClose, onResult }) {
  const [text, setText] = useState('');
  const [color, setColor] = useState(blue[500]);
  const [colourPicker, setColourPicker] = useState();

  useEffect(() => {
    if (!open) return;
    setText('');
    setColor(blue[500]);
  }, [open]);

  function selectColor(color) {
    if (color?.color) {
      setColor(color.color);
    }
    setColourPicker(null);
  }

  function save() {
    onResult({ id: v4(), text, color });
    onClose();
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)}>
      <DialogTitle>New action</DialogTitle>

      <DialogContent>
        <Grid container alignItems="center">
          <TextField
            variant="outlined"
            label="Action title"
            value={text}
            style={{ flexGrow: 1, marginRight: 5, height: 'fit-content' }}
            onChange={(event) => setText(event.target.value)}
            inputProps={{ maxLength: 32 }}
          />
          <Tooltip title={<Typography>Select color</Typography>} placement="top" arrow>
            <IconButton onClick={(e) => setColourPicker(e.currentTarget)}>
              <FiberManualRecord style={{ color }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={save} hideDone={!text} />
      <ColorPicker anchor={colourPicker} open={Boolean(colourPicker)} onClose={selectColor} />
    </RoundedDialog>
  );
}

function EmailOptionsDialog({ open, onClose, onResult, initial, peopleEmailOptions }) {
  // data
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [peopleOptionIds, setPeopleOptionIds] = useState([]);
  const [emails, setEmails] = useState([]);
  const [users, setUsers] = useState([]);

  // UI
  const [withPeopleField, setWithPeopleField] = useState(false);
  const [currEmail, setCurrEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const peopleFieldsAvailable =
    isArray(peopleEmailOptions) && !isEmpty(peopleEmailOptions) && peopleEmailOptions.every((x) => x.id);

  useEffect(() => {
    if (!open) return;
    setPeopleOptionIds(isArray(initial?.peopleOptionIds) ? initial.peopleOptionIds : []);
    setSubject(initial?.subject || 'Form has been completed');
    setBody(initial?.body || 'Please find attached');
    setEmails(initial?.emails || []);
    setCurrEmail('');
    setUsers(isArray(initial?.users) ? initial.users : []);
    setWithPeopleField(isArray(initial?.peopleOptionIds) && !isEmpty(initial?.peopleOptionIds));
  }, [open, initial]);

  function addEmail() {
    let email = String(currEmail).trim().toLowerCase();
    if (!email) return;
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    if (emails.includes(email)) {
      setCurrEmail('');
      return;
    }
    emails.push(email);
    setEmails([...emails]);
    setCurrEmail('');
  }

  function done() {
    if (!subject) {
      StateManager.setErrorAlert('Provide email subject');
      return;
    }
    if (!body) {
      StateManager.setErrorAlert('Provide email body');
      return;
    }
    if (peopleOptionIds.length + emails.length + users.length === 0) {
      StateManager.setErrorAlert('Provide recipients');
      return;
    }
    const peopleOptionValues = peopleFieldsAvailable
      ? peopleEmailOptions.filter((x) => peopleOptionIds.includes(x.id)).map((x) => x.value)
      : [];
    onResult({ peopleOptionIds, peopleOptionValues, subject, body, emails, users });
    onClose();
  }

  function clear() {
    onResult(undefined);
    onClose();
  }

  function selectEmailUsers() {
    StateManager.selectMultipleUsers(saveEmailUsers, { initiallySelected: users });
  }

  function saveEmailUsers(res) {
    if (!res.users) return;
    setUsers(res.users);
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Email options:</DialogTitle>
      <DialogContent>
        <Grid container>
          <TextField
            variant="outlined"
            fullWidth
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            inputProps={{ maxLength: 128 }}
            style={{ marginTop: 16 }}
          />
          <HtmlContent content={body} editable onEdit={setBody} title="Email text" />
        </Grid>
        <Grid container style={{ marginTop: 16 }}>
          <Grid container item>
            <Typography variant="h6" gutterBottom>
              Send to:
            </Typography>
          </Grid>
          <Grid container item alignItems="center">
            <Button
              style={{ color: grey[600], whiteSpace: 'nowrap' }}
              onClick={selectEmailUsers}
              startIcon={<PersonAddRounded />}
            >
              Add users
            </Button>
            {emails.map((email) => (
              <Chip
                key={email}
                style={{ margin: 5 }}
                color="primary"
                label={email}
                onDelete={() => setEmails(emails.filter((x) => x !== email))}
              />
            ))}
            {users.map((user) => (
              <UserChip key={user} id={user} margin={5} onDelete={() => setUsers(users.filter((x) => x !== user))} />
            ))}
          </Grid>
          <Grid container item style={{ margin: '0.5rem 0' }} alignItems="center">
            <TextField
              label="Add email address"
              helperText={emailError ? 'Enter valid email' : ''}
              error={emailError}
              style={{ flexGrow: 1, marginRight: 8 }}
              value={currEmail}
              onChange={(e) => {
                setCurrEmail(e.target.value);
                if (emailError) {
                  setEmailError(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addEmail();
                }
              }}
              variant="standard"
            />

            {currEmail && (
              <>
                <Tooltip title="Add" placement="top">
                  <IconButton onClick={addEmail}>
                    <DoneRounded style={{ color: green[500] }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Clear" placement="top">
                  <IconButton
                    onClick={() => {
                      setCurrEmail('');
                      setEmailError(false);
                    }}
                  >
                    <CloseRounded style={{ color: red[500] }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
          {peopleFieldsAvailable && (
            <>
              <Grid container item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={withPeopleField}
                      color="primary"
                      onChange={(e) => setWithPeopleField(e.target.checked)}
                    />
                  }
                  label="Pick from people field"
                />
              </Grid>
              <Collapse in={withPeopleField}>
                <FormGroup>
                  {peopleEmailOptions.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      label={option.text}
                      control={<Checkbox color="primary" checked={peopleOptionIds.includes(option.id)} />}
                      onChange={(e) => {
                        setPeopleOptionIds(
                          e.target.checked
                            ? [...peopleOptionIds, option.id]
                            : peopleOptionIds.filter((x) => x !== option.id),
                        );
                      }}
                    />
                  ))}
                </FormGroup>
              </Collapse>
            </>
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <Button onClick={clear} style={{ color: red[500], marginRight: 'auto' }} startIcon={<ClearRounded />}>
            do not email
          </Button>
        }
        onClose={onClose}
        onDone={done}
      />
    </RoundedDialog>
  );
}

function SwitchParent({ open, onDone, onClose, activityId, fieldId, activityType, currentlyOn }) {
  const [destinationId, setDestinationId] = useState();
  const [parentForms, setParentForms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      if (activityType === 'form') {
        axios
          .post('/forms/management/getFormSectionsNames', { formDraftId: activityId, currentSection: currentlyOn })
          .then((response) => {
            setParentForms(response.data || []);
            setLoading(false);
          });
      }
      if (activityType === 'process') {
        axios
          .post('/process/management/getOngoingStepsNames', { processDraftId: activityId, currentStep: currentlyOn })
          .then((response) => {
            setParentForms(response.data || []);
            setLoading(false);
          });
      }
    }
    //eslint-disable-next-line
  }, [open]);

  function switchParentDone(done) {
    if (!destinationId) {
      StateManager.setErrorAlert('Select a new parent form');
    }

    if (activityType === 'form') {
      const body = {
        formDraftId: activityId,
        fieldId: fieldId,
        destinationSectionId: destinationId,
      };
      axios
        .post('/forms/management/moveFieldBetweenSections', body)
        .then((res) => {
          onDone(res);
          onClose();
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
        });
    }

    if (activityType === 'process') {
      const body = {
        processDraftId: activityId,
        stepId: fieldId,
        destinationStepId: destinationId,
      };
      axios
        .post('/process/management/moveFieldBetweenSteps', body)
        .then((res) => {
          onDone(res);
          onClose();
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
        });
    }
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Move Field</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container item justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : !parentForms.length ? (
          <Typography>No space to move</Typography>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {`Select new ${activityType === 'process' ? 'step' : 'section'}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Parent</InputLabel>
                <Select
                  value={destinationId || ''}
                  onChange={(e) => setDestinationId(e.target.value)}
                  label={`Select new ${activityType === 'process' ? 'Step' : 'Section'}`}
                  sx={{ maxWidth: '600px' }}
                >
                  {parentForms.map((form) => (
                    <MenuItem key={form._id} value={form._id}>
                      {form?.title?.length > 70 ? `${form.title.slice(0, 67)}...` : form.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={switchParentDone}
        hideDone={!parentForms.length || !destinationId}
      />
    </RoundedDialog>
  );
}
