import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import DynamicComponent from '../HTMLEditor/components/DynamicComponent';
import DynamicTemplateComponent from '../HTMLEditor/components/DynamicTemplateComponent';

const HeaderFooter = ({ document, header, footer, issue = null, qtemplate = false }) => {
  const { defaultHeaderFooter } = useSelector(({ settings }) => settings);

  const getLayout = (value, config) => {
    if (config?.length === 1) {
      return true;
    }
    // check if file name and other fields are together, give priority to file name
    const filter = config?.filter((x) => x.value === 'file-name');
    if (filter?.length > 0) {
      if (value === 'file-name') {
        return true;
      }
      if (value === 'last-review-date' || value === 'last-up-issue-date' || value === 'published-date') {
        return 'auto';
      }
      return 'auto';
    }
    if (
      value === 'file-name' ||
      value === 'last-review-date' ||
      value === 'last-up-issue-date' ||
      value === 'published-date' ||
      value === 'doc-prefix-number' ||
      value === 'image'
    ) {
      return 'auto';
    }
    return true;
  };

  return (
    <>
      {header && (!document?.defaultHeaderFooter || defaultHeaderFooter) ? (
        <Grid item xs={12} sm={12} container direction={'row'} sx={{ mt: 2, display: 'table' }}>
          {header
            ?.filter((x) => x.filled)
            ?.map((x, i) => (
              <Grid
                item
                sm={getLayout(
                  x.value,
                  header?.filter((x) => x.filled),
                )}
                key={i}
                sx={{
                  border: '0.5px solid #e0e0e0',
                  verticalAlign: 'middle',
                  display: 'table-cell',
                  padding: '0.15em 0.50em',
                }}
              >
                {!qtemplate && <DynamicComponent {...x} document={document} issue={issue} />}
                {qtemplate && <DynamicTemplateComponent {...x} document={document} />}
              </Grid>
            ))}
        </Grid>
      ) : (
        <>
          {footer && (!document?.defaultHeaderFooter || defaultHeaderFooter) && (
            <Grid
              item
              sm={12}
              xs={1}
              container
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {footer
                ?.filter((x) => x.filled)
                ?.map((x, i) => (
                  <Grid
                    item
                    sm={getLayout(
                      x.value,
                      footer?.filter((x) => x.filled),
                    )}
                    key={i}
                    sx={{
                      border: '0.5px solid #e0e0e0',
                      verticalAlign: 'middle',
                      display: 'table-cell',
                      padding: '0.15em 0.50em',
                      mb: 0,
                      mt: 2,
                    }}
                  >
                    {!qtemplate && <DynamicComponent {...x} document={document} issue={issue} />}
                    {qtemplate && <DynamicTemplateComponent {...x} document={document} />}
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default HeaderFooter;
