import React, { useMemo, useState, useEffect } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Box, Typography, IconButton } from '@mui/material';
import StateManager from '../../../../../../Global/StateManager';
import { User, ImageDialog } from '../../../../../../Global/Components';
import axios from 'axios';
import { DateTimeCellRenderer } from '../../../AdvanceTable/cell';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { v4 } from 'uuid';

const SignoffHistoryTable = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageDialog, setImageDialog] = useState(null);

  const noSignatures =
    rows?.filter((row) => !row.signature?.location && !row.signature?.base64Data)?.length === rows.length;

  const columns = useMemo(
    () => [
      {
        field: 'role',
        headerName: 'Role',
        width: 150,
        display: 'flex',
      },
      {
        field: 'user',
        headerName: 'Name',
        width: 150,
        display: 'flex',
        renderCell: (params) => <User id={params?.value} avatarSize={16} />,
      },
      {
        field: 'signature',
        headerName: 'Signature',
        width: 150,
        display: 'flex',
        renderCell: (params) => {
          return params.row.signature?.location || params.row.signature?.base64Data ? (
            <IconButton
              onClick={(e) => setImageDialog(params.row.signature?.location || params.row.signature?.base64Data)}
            >
              <VisibilityIcon sx={{ verticalAlign: 'middle' }} fontSize="small" />
            </IconButton>
          ) : (
            ''
          );
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        width: 150,
        display: 'flex',
        valueFormatter: (value) => {
          if (!value) return null;
          return new Date(value);
        },
        type: 'date',
        renderCell: (params) => {
          let value = params?.formattedValue ?? params?.value;
          return <DateTimeCellRenderer params={value} format={'DD/MM/YYYY'} />;
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axios
      .post(`/dochub/documents/${id}/getSignOffHistory`)
      .then((response) => {
        setRows(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [id]);

  return (
    <Box style={{ width: '100%' }}>
      <DataGridPremium
        rows={rows}
        columns={columns?.filter((column) => !noSignatures || column.field !== 'signature')}
        autoHeight
        density="compact"
        disableRowSelectionOnClick
        disableAggregation
        disableRowGrouping
        initialState={{ density: 'compact' }}
        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                No sign off history available
              </Typography>
            </Box>
          ),
          footerRowCount: () => null,
          footer: () => null,
        }}
        getRowId={(row) => row.id || v4()}
        loading={loading}
        sx={{ mt: 1 }}
      />
      <ImageDialog open={!!imageDialog} onClose={() => setImageDialog(null)} src={imageDialog} />
    </Box>
  );
};

export default SignoffHistoryTable;
