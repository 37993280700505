import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { isArray } from 'lodash';

import { grey, green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import {
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { ListItemIcon, Avatar } from '@mui/material';
import { Search, CheckCircleRounded, CheckCircleOutlineRounded, PersonRounded } from '@mui/icons-material';
import { NormalButton } from '../IssueComponents';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import StateManager from '../../../../Global/StateManager';

export default ({
  open,
  handleClose,
  handleConfirm,
  excludeCurrent,
  initiallySelected,
  excludeUsers,
  link = '/user/getUsers',
  changing,
  confirmButtonSuffix = 'Editor',
}) => {
  const [users, setUsers] = useState([]);
  const [pattern, setPattern] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('_id');

  useEffect(() => {
    if (!link || !open) return;
    setLoading(true);
    axios
      .get(link)
      .then((res) => {
        if (isArray(res.data)) {
          let allUsers = excludeCurrent ? res.data.filter((x) => x._id !== userId) : res.data;
          allUsers = excludeUsers ? allUsers?.filter((x) => !excludeUsers.includes(x._id)) : allUsers;
          setUsers(allUsers);
        } else {
          let allUsers = excludeCurrent ? res.data.users.filter((x) => x._id !== userId) : res.data.users;
          allUsers = excludeUsers ? allUsers?.filter((x) => !excludeUsers.includes(x._id)) : allUsers;
          setUsers(allUsers);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [link, open, userId, excludeCurrent, excludeUsers]);

  useEffect(() => {
    if (!open) return;
    if (!initiallySelected) setSelectedUser(null);
    else setSelectedUser(initiallySelected);
  }, [open, initiallySelected]);

  function addDeleteUser(id) {
    setSelectedUser(id);
  }

  return (
    <RoundedDialog open={open} onClose={handleClose} titleId="editor-change-dialog" fullWidth maxWidth="md">
      <DialogTitle id="alert-dialog-title" sx={{ textTransform: 'capitalize' }}>
        Please pick an {confirmButtonSuffix} from the list
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid container sx={{ padding: (theme) => theme.spacing(1), margin: (theme) => theme.spacing(2, 0) }}>
            <TextField
              placeholder="Search people"
              fullWidth
              value={pattern}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search sx={{ color: 'lightgray' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setPattern(event.target.value.toLowerCase())}
              variant="standard"
            />
          </Grid>
          {loading && (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress color="primary" size={30} />
            </Grid>
          )}
          <Grid container>
            <List sx={{ minHeight: '30vh', overflow: 'auto', width: '100%', marginTop: (theme) => theme.spacing(1) }}>
              {users
                .filter((user) => user.fullName?.toLowerCase().includes(pattern))
                .map((user) => (
                  <ListItemButton
                    key={user._id}
                    sx={{ borderRadius: '5px' }}
                    onClick={() => {
                      addDeleteUser(user._id);
                      setPattern('');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={user.picture} sx={{ height: 42, width: 42 }}>
                        {user.avatarLetters}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={user.fullName} />
                    <ListItemIcon sx={{ marginLeft: 'auto' }}>
                      {selectedUser === user._id ? (
                        <CheckCircleRounded sx={{ color: green[500] }} />
                      ) : (
                        <CheckCircleOutlineRounded sx={{ color: grey[300] }} />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        handleClose={handleClose}
        hideDone={!selectedUser}
        onDone={() => handleConfirm(selectedUser)}
        doneButtonText={changing ? `Updating ${confirmButtonSuffix}` : `Change ${confirmButtonSuffix}`}
        saving={changing}
        doneButtonIcon={<PersonRounded />}
        disableDone={selectedUser === initiallySelected || changing}
      />
    </RoundedDialog>
  );
};
