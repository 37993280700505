import { createTheme, darken, lighten } from '@mui/material/styles';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  black,
  white,
  grey,
} from '@mui/material/colors';
import { lightGreen, lime, yellow, amber, orange, deepOrange } from '@mui/material/colors';

export const COLORS = {
  primary: blue[500],
};

const darkDefaultBackground = '#1b2635';
const darkPaperBackground = '#233044';
const lightDefaultBackground = '#fafafa';
const lightPaperBackground = '#fff';
const defaultPrimaryColor = 'blue';

const colours = {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
};

/**
 * Color with alpha channel
 */
export function varAlpha(color, opacity = 1) {
  const unsupported =
    color.startsWith('#') ||
    color.startsWith('rgb') ||
    color.startsWith('rgba') ||
    (!color.includes('var') && color.includes('Channel'));

  if (unsupported) {
    throw new Error(`[Alpha]: Unsupported color format "${color}".
     Supported formats are:
     - RGB channels: "0 184 217".
     - CSS variables with "Channel" prefix: "var(--palette-common-blackChannel, #000000)".
     Unsupported formats are:
     - Hex: "#00B8D9".
     - RGB: "rgb(0, 184, 217)".
     - RGBA: "rgba(0, 184, 217, 1)".
     `);
  }

  return `rgba(${color} / ${opacity})`;
}

export const makeTheme = (userTheme) => {
  let mode = userTheme ? userTheme.mode : localStorage.getItem('themeMode');
  if (!mode) {
    // detect system theme
    mode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
  let color = userTheme ? userTheme.color : localStorage.getItem('themeColor');

  const column = 500;
  const primaryStr = color || defaultPrimaryColor;
  const primary = colours[primaryStr][column];
  const secondary = red[500];

  const result = createTheme({
    typography: {
      fontFamily: 'Inter',
      h3: {
        fontSize: '2rem',
      },
      h4: {
        fontSize: '1.5rem',
      },
      body1: {
        fontSize: '14px',
      },
    },
    palette: {
      mode,
      primary: { main: primary, light: lighten(primary, 0.2), dark: darken(primary, 0.2) },
      secondary: { main: secondary, light: lighten(secondary, 0.2), dark: darken(secondary, 0.2) },
      background: {
        default: mode === 'light' ? lightDefaultBackground : darkDefaultBackground,
        paper: mode === 'light' ? lightPaperBackground : darkPaperBackground,
      },
      error: { main: red[500], light: lighten(red[500], 0.2), dark: darken(red[500], 0.2) },
      text: {
        secondary: mode === 'light' ? grey[600] : grey[400],
      },
    },
    shape: {
      borderRadius: 8,
    },
    MuiFab: {
      styleOverrides: {
        height: 20,
        width: 20,
      },
    },
  });

  return result;
};
