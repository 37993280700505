import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { HtmlContainer } from '../../../../../../Global/Components';
import { getDecryptedFile } from '../../../../redux/actions/file';

const ImagePreview = ({ images = [], mode = 'gallery', isCaption }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFiles = useCallback(async () => {
    if (!images || images?.length === 0) return;
    const decryptedFiles = await Promise.all(
      images.map((file) => {
        if (file.location) {
          const { fileId, fileKey, fileName, location } = file;
          const decrypted = getDecryptedFile({
            encrypted: true,
            file: {
              id: fileId,
              location,
              originalname: fileName,
              key: fileKey,
            },
          });
          return decrypted;
        }
        return null;
      }),
    );
    const updatedFiles = images
      ?.filter((x) => x)
      ?.map((file, index) => ({
        ...file,
        preview: decryptedFiles[index],
      }));
    setFiles(updatedFiles);
  }, [images]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  if (!files || files.length === 0) {
    return null;
  }

  if (mode !== 'gallery') {
    const [image] = files; // In single mode, only the first image is considered
    return (
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        {/* Image Section */}
        <Grid item xs={6} order={mode === 'image-left' ? 1 : 2}>
          <Box
            sx={{
              width: '100%',
              height: '300px',
              backgroundImage: `url(${image?.preview})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />
          {image?.caption && isCaption && (
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                marginTop: 1,
                textAlign: 'center',
                color: '#555',
              }}
            >
              {image.caption}
            </Typography>
          )}
        </Grid>
        {/* Text Section */}
        <Grid item xs={6} order={mode === 'image-left' ? 2 : 1}>
          <HtmlContainer html={image?.text} fontSize={'10pt'} />
        </Grid>
      </Grid>
    );
  }

  // Gallery mode
  return (
    <Grid container spacing={2}>
      {files?.map((image, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <Box
            sx={{
              position: 'relative',
              border: '1px solid #ddd',
              borderRadius: 1,
              padding: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '150px',
                backgroundImage: `url(${image?.preview})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            />
            {image.caption && isCaption && (
              <Typography
                variant="caption"
                sx={{
                  display: 'block',
                  marginTop: 1,
                  textAlign: 'center',
                  color: '#555',
                }}
              >
                {image.caption}
              </Typography>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ImagePreview;
