import { useEffect, useState, useMemo, useRef } from 'react';
import StateManager from '../StateManager';
import { useSelector } from 'react-redux';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridPagination } from '@mui/x-data-grid-premium';
import axios from 'axios';
import { sortDataGridColumns } from '../Functions';
import { isArray, isEmpty, isNumber, uniq, isFunction } from 'lodash';
import MuiPagination from '@mui/material/Pagination';
import { useQuery } from '../../../constants';
import { arrayMoveImmutable } from 'array-move';
import { Grid, Button, Box, CircularProgress } from '@mui/material';
import { DownloadRounded } from '@mui/icons-material';

const DEFAULT_PAGE_SIZE = 20;

function CustomFooter({ downloading, onDownload, Pagination }) {
  return (
    <Grid container alignItems={'center'}>
      {isFunction(onDownload) && (
        <Box sx={{ p: 0.5 }}>
          <Button
            id="download-excel-button"
            onClick={() => onDownload()}
            startIcon={downloading ? <CircularProgress size={24} /> : <DownloadRounded />}
          >
            Download as excel
          </Button>
        </Box>
      )}
      <Box sx={{ ml: 'auto' }}>
        <Pagination />
      </Box>
    </Grid>
  );
}

/**
 * Hook for handling server-side pagination, sorting and filters
 * @param {String} dataGridClass table identifier
 * @param {Array} initialColumns table columns
 * @param {String} link POST API endpoint to fetch the table rows
 * @param {String} rowsField Field in the api response containig rows
 * @param {String} totalField Field in the api response containing total row count
 * @param {String} layoutType 'personal' | 'company'
 * @param {Object} params
 * {
 *  defaultPageSize,
 *  pageSizeOptions,
 *  checkboxPosition,
 *  requestData,
 *  pinnedRight,
 *  pinnedLeft,
 *  slots,
 *  rowMapFunction,
 * }
 * @returns
 */
const useDataGridPagination = (dataGridClass, initialColumns, link, rowsField, totalField, layoutType, params) => {
  const viewId = useQuery().get('viewId');
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [groupingColDef, setGroupingColDef] = useState({});
  const [pinnedColumns, setPinnedColumns] = useState({});
  const [columnsModel, setColumnsModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [rowCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [configId, setConfigId] = useState(null);
  const [views, setViews] = useState([]);
  const { user } = useSelector(({ profile }) => profile);
  const [defaultView, setDefaultView] = useState(null);
  const [rowGroupingModel, setRowGroupingModel] = useState([]);
  const [userDefaultConfig, setUserDefaultConfig] = useState(null);
  const paginationModel = { page, pageSize };

  const pageToLoad = useRef(0);

  const rowsRef = useRef([]);
  rowsRef.current = rows;

  const paginationRef = useRef({ page: 0, pageSize: 20 });
  paginationRef.current = paginationModel;

  const filtersRef = useRef([]);
  filtersRef.current = filterModel;

  const sortingRef = useRef([]);
  sortingRef.current = sortModel;

  const columns = useMemo(() => {
    if (!isArray(initialColumns)) return [];

    const result = sortDataGridColumns(initialColumns, columnsModel);
    return result;
  }, [initialColumns, columnsModel]);

  const gridRows = useMemo(() => rows.map((x) => ({ ...x, __reorder__: 'Drag to reorder' })), [rows]);

  useEffect(() => {
    if (!dataGridClass || !link || !rowsField || !totalField) return;

    setLoading(true);
    setLoadingConfig(true);

    axios
      .get('/general/datagrid/getDataGridConfiguration', { params: { dataGridClass, type: layoutType, viewId } })
      .then(({ data }) => {
        setConfigId(data?._id);
        setDefaultView(data?.viewId || null);
        setColumnVisibilityModel(data?.columnVisibilityModel || {});
        setPinnedColumns(
          data?.pinnedColumns || {
            left: isArray(params?.pinnedLeft) && !isEmpty(params.pinnedLeft) ? params.pinnedLeft : [],
            right: isArray(params?.pinnedRight) && !isEmpty(params.pinnedRight) ? params.pinnedRight : [],
          },
        );

        setColumnsModel(data?.columnsModel || []);
        setFilterModel(data?.filterModel || { items: [] });
        setRowGroupingModel(data?.rowGroupingModel || []);
        setGroupingColDef(data.groupingColDef || {});
        setSortModel(data?.sortModel || []);
        setViews(data?.views || []);
        setUserDefaultConfig(data?.userDefaultConfig);

        const size = data?.pageSize || params?.defaultPageSize || DEFAULT_PAGE_SIZE;
        setPageSize(size);

        // load specific page if requested, otherwise - the first one
        const toLoad = pageToLoad.current || 0;

        setPage(toLoad);
        loadGridPage(toLoad, size, data?.filterModel, data?.sortModel);
        rowsRef.current = 0;
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      })
      .finally(() => {
        setLoadingConfig(false);
      });
  }, [dataGridClass, link, rowsField, totalField]); // eslint-disable-line

  function loadGridPage(currPage, currPageSize, currFilters, currSorting, quietly, callback, requestData) {
    !quietly && setLoading(true);

    const body = {
      page: currPage,
      pageSize: currPageSize,
      filterModel: currFilters,
      sortModel: currSorting,
      ...(params?.requestData || {}),
      ...(requestData || {}),
    };

    axios
      .post(link, body)
      .then(({ data }) => {
        setTotalCount(data?.[totalField]);
        const rowsToSet =
          isFunction(params?.rowMapFunction) && isArray(data?.[rowsField])
            ? data[rowsField].map((row) => params.rowMapFunction(row))
            : isArray(data?.[rowsField])
            ? data[rowsField]
            : [];

        setRows(rowsToSet);
        setFilterModel(currFilters);

        isFunction(callback) && callback();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveConfig(data) {
    if (layoutType !== 'personal' && user?.access !== 'admin') {
      return;
    }

    axios
      .post('/general/datagrid/saveDataGridConfiguration', { dataGridClass, data, type: layoutType })
      .then(({ data }) => {
        // memorise default config data in case view is selected
        if (data?.userDefaultConfig) {
          setUserDefaultConfig(data.userDefaultConfig);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function saveColumnVisibilityModel(model) {
    setColumnVisibilityModel(model);
    saveConfig({ columnVisibilityModel: model });
  }

  function savePinnedColumns(model) {
    if (params?.checkboxPosition === 'left' && model.left && model.left[0] !== GRID_CHECKBOX_SELECTION_COL_DEF.field) {
      model.left.unshift(GRID_CHECKBOX_SELECTION_COL_DEF.field);
    }

    if (isArray(params?.pinnedRight) && !isEmpty(params?.pinnedRight)) {
      model.right.unshift(...params.pinnedRight);
    }

    if (isArray(params?.pinnedLeft) && !isEmpty(params?.pinnedLeft)) {
      model.left.unshift(...params.pinnedLeft);
    }

    model.left = uniq(model.left);
    model.right = uniq(model.right);
    setPinnedColumns(model);
    saveConfig({ pinnedColumns: model });
  }

  function saveColumnsModel(event, changeType) {
    const colDef = changeType === 'order' ? event.column : event.colDef;

    // build layout based on columns
    let layout = columns.map((col) => ({
      // save only the necessary data
      field: col.field,
      width:
        colDef?.field === col.field
          ? colDef?.width
          : colDef?.headerName && colDef?.headerName === col.headerName
          ? colDef?.width
          : col.width,
    }));

    // columns order changed - reorder layout
    if (changeType === 'order') {
      // IMPORTANT: real column index may differ from the one provided in the event
      // because in the event system columns (like __reorder__) are counted as well
      // so need to find the actual columns indexes
      const diff = event.targetIndex - event.oldIndex;
      const fromIndex = columns.findIndex((x) => x.field === colDef.field);
      const toIndex = fromIndex + diff;

      layout = arrayMoveImmutable(layout, fromIndex, toIndex);
    }

    setColumnsModel(layout);

    let update = {
      columnsModel: layout,
    };
    // group column width changed
    if (colDef.type === 'rowGroupByColumnsGroup') {
      update = {
        ...update,
        groupingColDef: {
          ...groupingColDef,
          width: colDef.width,
        },
      };
      setGroupingColDef({
        ...groupingColDef,
        width: colDef.width,
      });
    }
    saveConfig(update);
  }

  function onFilterModelChange(filterModel, dontSave) {
    setFilterModel(filterModel);

    // means filters are empty
    if (
      !isEmpty(filterModel.items) &&
      filterModel.items.every(
        (x) =>
          x.value == null &&
          !['isEmpty', 'isNotEmpty'].includes(x.operator) &&
          !['dueAt', 'completedDate'].includes(x.field),
      )
    ) {
      return;
    }

    dontSave !== true && saveConfig({ filterModel });
    loadGridPage(page, pageSize, filterModel, sortModel);
  }

  function onRowGroupingModelChange(rowGroupingModel) {
    setRowGroupingModel(rowGroupingModel);
    let disableObj = {};
    rowGroupingModel.map((data, index) => {
      disableObj[data] = index === 0 ? false : true;
    });
    setColumnVisibilityModel(disableObj);
    setGroupingColDef({
      leafField: rowGroupingModel?.[0],
      headerName: 'Group',
      hide: false,
    });
    saveConfig({
      rowGroupingModel,
      columnVisibilityModel: disableObj,
      groupingColDef: {
        leafField: rowGroupingModel?.[0],
        headerName: 'Group',
        hide: false,
      },
    });
  }

  function onConfigurationChange(data) {
    if (!data) return;

    setConfigId(data.configId);
    setColumnVisibilityModel(data.columnVisibilityModel || {});
    const defaultPinnedColumns = {
      left: isArray(params?.pinnedLeft) && !isEmpty(params?.pinnedLeft) ? params.pinnedLeft : [],
      right: isArray(params?.pinnedRight) && !isEmpty(params?.pinnedRight) ? params.pinnedRight : [],
    };

    const updatedPinnedColumns = !isEmpty(data.pinnedColumns) ? data.pinnedColumns : defaultPinnedColumns;
    setPinnedColumns(updatedPinnedColumns);
    setColumnsModel(data.columnsModel || []);
    setFilterModel(data.filterModel || { items: [] });
    setRowGroupingModel(data?.rowGroupingModel || []);
    setGroupingColDef(data?.groupingColDef || {});
    setSortModel(data.sortModel || []);
    setColumnsModel(data.columnsModel || []);
    const size = data.pageSize || params?.defaultPageSize || DEFAULT_PAGE_SIZE;
    setPageSize(size);
    loadGridPage(0, size, data.filterModel, data.sortModel);

    // save view
    saveConfig({
      viewId: data._id || null,
      filterModel: data.filterModel,
      rowGroupingModel: data?.rowGroupingModel,
      groupingColDef: data?.groupingColDef,
      sortModel: data.sortModel,
      columnsModel: data.columnsModel,
      pageSize: size,
      pinnedColumns: updatedPinnedColumns,
    });
  }

  function onSortModelChange(sortModel) {
    setSortModel(sortModel);
    saveConfig({ sortModel });
    loadGridPage(page, pageSize, filterModel, sortModel);
  }

  function onPaginationModelChange(model) {
    setPageSize(model.pageSize);
    setPage(model.page);
    if (model.pageSize !== pageSize) {
      saveConfig({ pageSize: model.pageSize });
    }
    loadGridPage(model.page, model.pageSize, filterModel, sortModel);
  }

  function reloadCurrentPage(quietly, requestData) {
    loadGridPage(
      paginationRef.current.page,
      paginationRef.current.pageSize,
      filtersRef.current,
      sortingRef.current,
      quietly,
      null,
      requestData,
    );
  }

  function emptyTable() {
    setTotalCount(0);
    setRows([]);
  }

  function loadLastPage() {
    const totalPages = Math.floor(rowCount / pageSize);

    if (page === totalPages) {
      reloadCurrentPage();
    } else {
      setPage(totalPages);
      loadGridPage(totalPages, pageSize, filterModel, sortModel);
    }
  }

  function loadFirstPage() {
    if (page === 0) {
      reloadCurrentPage();
    } else {
      setPage(0);
      loadGridPage(0, pageSize, filterModel, sortModel);
    }
  }

  function onDownload() {
    if (!isFunction(params?.onDownload)) return;
    // pass all the data grids config params
    const config = {
      sortModel,
      filterModel,
      columnVisibilityModel,
      columnsModel,
    };

    params.onDownload(config);
  }

  function goToRowWithIndex(index, onComplete) {
    if (!isNumber(index)) return;

    const rowPage = Math.floor(index / paginationRef.current.pageSize);
    const rowRelativeIndex = index % paginationRef.current.pageSize;

    // still loading config, too early
    if (loading && !configId) {
      pageToLoad.current = rowPage;
    } else {
      if (rowPage !== paginationRef.current.page) {
        setPage(rowPage);
        loadGridPage(
          rowPage,
          paginationRef.current.pageSize,
          filtersRef.current,
          sortingRef.current,
          false,
          () => isFunction(onComplete) && onComplete({ rowRelativeIndex, rowPage }),
        );
      }
    }
  }

  function dropFiltersAndSorting(callback) {
    if (!isEmpty(sortModel) || !isEmpty(filterModel?.items)) {
      // has filters
      const updatedSortModel = [];
      const updatedFilterModel = { items: [] };
      setPage(0);
      setSortModel(updatedSortModel);
      setFilterModel(updatedFilterModel);
      loadGridPage(0, pageSize, updatedFilterModel, updatedSortModel, null, callback);
    } else {
      // does not have filters
      isFunction(callback) && callback();
    }
  }

  const Pagination = useMemo(() => {
    function Pagination({ page, onPageChange, className }) {
      const pagesCount = Math.ceil(rowCount / pageSize);
      return (
        <MuiPagination
          color="primary"
          className={className}
          count={pagesCount}
          page={page + 1}
          onChange={(event, newPage) => {
            onPageChange(event, newPage - 1);
          }}
        />
      );
    }

    function CustomPagination(props) {
      return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    return CustomPagination;
  }, [rowCount, pageSize]);

  return {
    gridProps: {
      sortModel,
      filterModel,
      paginationModel,
      rowGroupingModel,
      columnVisibilityModel,
      groupingColDef,
      columnsModel,
      pinnedColumns,
      onColumnVisibilityModelChange: saveColumnVisibilityModel,
      onPinnedColumnsChange: savePinnedColumns,
      onColumnOrderChange: (event) => saveColumnsModel(event, 'order'),
      onColumnWidthChange: (event) => saveColumnsModel(event, 'width'),
      onFilterModelChange,
      onRowGroupingModelChange,
      onSortModelChange,
      onPaginationModelChange,
      loading,
      rowCount,
      rows: gridRows,
      columns,
      getRowId: (row) => row._id,
      pagination: true,
      disableRowSelectionOnClick: true,
      pageSizeOptions: isArray(params?.pageSizeOptions) ? params?.pageSizeOptions : [10, 20, 50, 100, 200, 500, 1000],
      paginationMode: 'server',
      filterMode: 'server',
      sortingMode: 'server',
      filterDebounceMs: 800,
      slots: {
        footer: CustomFooter,
        ...(params?.slots || {}),
      },
      slotProps: {
        footer: {
          downloading: params?.downloading,
          onDownload: isFunction(params?.onDownload) ? onDownload : null,
          Pagination,
        },
        ...(params?.slotProps || {}),
      },
    },
    configId,
    defaultView,
    onConfigurationChange,
    reloadCurrentPage,
    emptyTable,
    loadLastPage,
    loadFirstPage,
    goToRowWithIndex,
    updateRows: (data) => setRows(data),
    getRows: () => [...rowsRef.current],
    views,
    dropFiltersAndSorting,
    loadingConfig,
    userDefaultConfig,
  };
};

export default useDataGridPagination;
