import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { varAlpha } from '../../../../../../../theme';

// import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
// import { TooltipIconButton } from '../../../../../../Global/Components';

// const width = {
//   xs: '100%',
//   sm: '793.7px',
// }

// const height = {
//   xs: 'auto',
//   sm: '1122.5px',
// }

const A4Container = ({ children, height, handleScroll }) => {
  // const [stretched, setStretched] = React.useState(false);
  return (
    <Box
      sx={{
        minWidth: '793.7px',
        width: '75%', // Stretch fully or fit A4 size
        height: '100vh', // Full viewport height or A4
        margin: '0 auto', // Center align
        backgroundColor: (t) => (t.palette.mode === 'dark' ? grey[900] : t.palette.common.white), // Dark/Light mode background
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        overflow: 'auto', // Stretch mode allows scrolling for the outer container
        pt: { xs: 4, sm: 2 }, // Top margin (standard: 96px / 25mm)
        pb: { xs: 4, sm: 8 }, // Bottom margin (standard: 96px / 25mm)
        px: { xs: 4, sm: 7 }, // Left/Right margin (standard: 96px / 25mm)
        '@media (max-width: 600px)': {
          padding: '16px', // Reduced padding for smaller screens
        },
        my: 1, // Add margin for better layout
        boxShadow: (theme) =>
          `0 0 2px 0 ${varAlpha(
            theme.palette.mode === 'light' ? '158 158 158' : '0 0 0',
            0.2,
          )}, 0 12px 24px -4px ${varAlpha(theme.palette.mode === 'light' ? '158 158 158' : '0 0 0', 0.12)}`,
        position: 'relative',
      }}
      component={Paper}
    >
      <Box
        sx={{
          width: '100%',
          height, // Deduct toolbar height from full viewport
          overflowY: 'auto', // Enable scrolling for inner content
          overflowX: 'hidden',
          boxSizing: 'border-box',
          // add spacing between the scrollbar and the content
          paddingRight: 1,
          paddingBottom: 1,
        }}
        onScroll={handleScroll}
      >
        {children || (
          <Typography variant="body1" textAlign="center">
            No content to display
          </Typography>
        )}
      </Box>
      {/* <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <TooltipIconButton
          onClick={() => setStretched(!stretched)}
          text={stretched ? 'Fit to page' : 'Stretch to full'}
        >
          {stretched ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </TooltipIconButton>
      </Box> */}
    </Box>
  );
};

export default A4Container;
