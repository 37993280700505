/**
 * * Actions for Document Hub
 */
import axios from '../../../../../redux/apiCaller';
import { downloadFile, removeWhitespaces } from '../../constants';
import {
  successAction,
  errorAction,
  attemptAction,
  UPDATEAPPROVAL_SUCCESS,
  SETAPPROVALALERT,
} from '../../../../../redux/constants';
import { FILE_PROCESSING_URL } from '../../../../../constants';
import {
  SET_UPLOAD_DIALOG,
  GETMYFILES_ATTEMPT,
  GETMYFILES_SUCCESS,
  GETMYFILES_FAIL,
  GETDOCUMENT_ATTEMPT,
  GETDOCUMENT_SUCCESS,
  GETDOCUMENT_FAIL,
  GETAREAS_ATTEMPT,
  GETAREAS_SUCCESS,
  GETAREAS_FAIL,
  GETAREA_ATTEMPT,
  GETAREA_FAIL,
  GETAREA_SUCCESS,
  GETMYAREA_ATTEMPT,
  GETMYAREA_FAIL,
  GETMYAREA_SUCCESS,
  UPLOADFILES_ATTEMPT,
  UPLOADFILES_SUCCESS,
  UPLOADFILES_FAIL,
  UPLOADFILE_ATTEMPT,
  UPLOADFILE_SUCCESS,
  UPLOADFILE_FAIL,
  REMOVEFILE,
  REMOVEFILES,
  ADDDOCUMENTS_ATTEMPT,
  ADDDOCUMENTS_SUCCESS,
  ADDDOCUMENTS_FAIL,
  SETCURRENTAREA,
  SETCURRENTFOLDER,
  SETISFOLDER,
  SETCURRENTPATH,
  GETFOLDERS_ATTEMPT,
  GETFOLDERS_SUCCESS,
  GETFOLDERS_FAIL,
  GETFOLDER_ATTEMPT,
  GETFOLDER_SUCCESS,
  GETFOLDER_FAIL,
  ADDFOLDER_ATTEMPT,
  ADDFOLDER_SUCCESS,
  ADDFOLDER_FAIL,
  UPDATEFOLDER_ATTEMPT,
  UPDATEFOLDER_SUCCESS,
  UPDATEFOLDER_FAIL,
  DELETEFOLDER_ATTEMPT,
  DELETEFOLDER_SUCCESS,
  DELETEFOLDER_FAIL,
  UPDATEDOCUMENT_ATTEMPT,
  UPDATEDOCUMENT_SUCCESS,
  UPDATEDOCUMENT_FAIL,
  DELETEDOCUMENT_ATTEMPT,
  DELETEDOCUMENT_SUCCESS,
  DELETEDOCUMENT_FAIL,
  GOBACKPATH,
  SETCONTROLLEDINDEX,
  ADDAREA_ATTEMPT,
  ADDAREA_SUCCESS,
  ADDAREA_FAIL,
  UPDATEAREA_ATTEMPT,
  UPDATEAREA_SUCCESS,
  UPDATEAREA_FAIL,
  DELETEAREA_ATTEMPT,
  DELETEAREA_SUCCESS,
  DELETEAREA_FAIL,
  GETAREASETTINGS_ATTEMPT,
  GETAREASETTINGS_SUCCESS,
  GETAREASETTINGS_FAIL,
  UPDATEAREASETTINGS_ATTEMPT,
  UPDATEAREASETTINGS_SUCCESS,
  UPDATEAREASETTINGS_FAIL,
  DELETEAREASETTINGS_ATTEMPT,
  DELETEAREASETTINGS_SUCCESS,
  DELETEAREASETTINGS_FAIL,
  REQUESTAREA_ATTEMPT,
  REQUESTAREA_SUCCESS,
  REQUESTAREA_FAIL,
  GETREVISIONFILES_ATTEMPT,
  GETREVISIONFILES_SUCCESS,
  GETREVISIONFILES_FAIL,
  GETTAGS_ATTEMPT,
  GETTAGS_SUCCESS,
  GETTAGS_FAIL,
  ADDTAG_ATTEMPT,
  ADDTAG_SUCCESS,
  ADDTAG_FAIL,
  UPDATETAG_ATTEMPT,
  UPDATETAG_SUCCESS,
  UPDATETAG_FAIL,
  REMOVETAG_ATTEMPT,
  REMOVETAG_SUCCESS,
  REMOVETAG_FAIL,
  GETPREFIXES_ATTEMPT,
  GETPREFIXES_SUCCESS,
  GETPREFIXES_FAIL,
  ADDPREFIX_ATTEMPT,
  ADDPREFIX_SUCCESS,
  ADDPREFIX_FAIL,
  UPDATEPREFIX_ATTEMPT,
  UPDATEPREFIX_SUCCESS,
  UPDATEPREFIX_FAIL,
  REMOVEPREFIX_ATTEMPT,
  REMOVEPREFIX_SUCCESS,
  REMOVEPREFIX_FAIL,
  GETALLDOCS_ATTEMPT,
  GETALLDOCS_SUCCESS,
  GETALLDOCS_FAIL,
  GETDOCUSERS_ATTEMPT,
  GETDOCUSERS_SUCCESS,
  GETDOCUSERS_FAIL,
  SETSELECTEDDOC,
  GETLINKEDFILES_ATTEMPT,
  GETLINKEDFILES_SUCCESS,
  GETLINKEDFILES_FAIL,
  ADDLINK_ATTEMPT,
  ADDLINK_SUCCESS,
  ADDLINK_FAIL,
  UPDATELINKEDFILES_ATTEMPT,
  UPDATELINKEDFILES_SUCCESS,
  UPDATELINKEDFILES_FAIL,
  SEARCH_ATTEMPT,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  QSEARCH_ATTEMPT,
  QSEARCH_SUCCESS,
  QSEARCH_FAIL,
  CLEARQRESULTS,
  SETREQUESTALERT,
  ADDQDOC_ATTEMPT,
  ADDQDOC_SUCCESS,
  ADDQDOC_FAIL,
  ADDDRAFTQDOC_ATTEMPT,
  ADDDRAFTQDOC_SUCCESS,
  ADDDRAFTQDOC_FAIL,
  GETPATHAREAS_ATTEMPT,
  GETPATHAREAS_SUCCESS,
  GETPATHAREAS_FAIL,
  GETPATHAREA_ATTEMPT,
  GETPATHAREA_SUCCESS,
  GETPATHAREA_FAIL,
  GETPATHFOLDERS_ATTEMPT,
  GETPATHFOLDERS_SUCCESS,
  GETPATHFOLDERS_FAIL,
  GETPATHFOLDER_ATTEMPT,
  GETPATHFOLDER_SUCCESS,
  GETPATHFOLDER_FAIL,
  SETPATHCURRENTFOLDER,
  SETSETUPDIALOG,
  GETSETTINGS_ATTEMPT,
  GETSETTINGS_SUCCESS,
  GETSETTINGS_FAIL,
  UPDATESETTINGS_ATTEMPT,
  UPDATESETTINGS_SUCCESS,
  UPDATESETTINGS_FAIL,
  SETUPDATE,
  GETQDOC_ATTEMPT,
  GETQDOC_SUCCESS,
  GETQDOC_FAIL,
  UPDATEQDOC_ATTEMPT,
  UPDATEQDOC_SUCCESS,
  UPDATEQDOC_FAIL,
  SETQDOC,
  SETQSTATUS,
  SETDOCALERT,
  BOOKMARKDOC_ATTEMPT,
  BOOKMARKDOC_SUCCESS,
  BOOKMARKDOC_FAIL,
  UNBOOKMARKDOC_ATTEMPT,
  UNBOOKMARKDOC_SUCCESS,
  UNBOOKMARKDOC_FAIL,
  FETCHBOOKMARKS_ATTEMPT,
  FETCHBOOKMARKS_SUCCESS,
  FETCHBOOKMARKS_FAIL,
  BOOKMARKFOLDER_ATTEMPT,
  BOOKMARKFOLDER_SUCCESS,
  BOOKMARKFOLDER_FAIL,
  UNBOOKMARKFOLDER_ATTEMPT,
  UNBOOKMARKFOLDER_SUCCESS,
  UNBOOKMARKFOLDER_FAIL,
  GETBOOKMARKEDFILES_ATTEMPT,
  GETBOOKMARKEDFILES_SUCCESS,
  GETBOOKMARKEDFILES_FAIL,
  GETBOOKMARKEDFOLDERS_ATTEMPT,
  GETBOOKMARKEDFOLDERS_SUCCESS,
  GETBOOKMARKEDFOLDERS_FAIL,
  ADDAREASETTINGS_ATTEMPT,
  ADDAREASETTINGS_SUCCESS,
  ADDAREASETTINGS_FAIL,
  FETCHPENDINGUPISSUE_ATTEMPT,
  FETCHPENDINGUPISSUE_SUCCESS,
  FETCHPENDINGUPISSUE_FAIL,
  FINISHPENDINGUPISSUE_ATTEMPT,
  FINISHPENDINGUPISSUE_SUCCESS,
  FINISHPENDINGUPISSUE_FAIL,
  SETISSUE,
  GETQISSUE_FAIL,
  GETQISSUE_SUCCESS,
  GETQISSUE_ATTEMPT,
  SETSEARCHDIALOG,
  SETCONTROLDIALOG,
  HIGHLIGHTDOC,
  SETMOVEDIALOG,
  CANCELUPISSUE_ATTEMPT,
  CANCELUPISSUE_SUCCESS,
  CANCELUPISSUE_FAIL,
  DOCLIBRARY_ATTEMPT,
  DOCLIBRARY_ERROR,
  DOCVIEWED_SUCCESS,
  GETFOLDERSETTINGS_SUCCESS,
  ADDFOLDERSETTINGS_SUCCESS,
  UPDATEFOLDERSETTINGS_SUCCESS,
  DELETEFOLDERSETTINGS_SUCCESS,
  REQUESTFOLDER_SUCCESS,
  SHOWALERT,
  SETGLOBALDOCALERT,
  SETINITIALUPLOADCONFIG,
  BULKDOCDELETE,
  BULKFOLDERDELETE,
  SETDOCDRAWER,
  FETCHDOCCOMMENTS,
  FETCHDOCCOMMENTS_ATTEMPT,
  FETCHDOCCOMMENTS_ERROR,
  ADDDOCCOMMENT,
  DELETEDOCCOMMENT,
  UPDATEDOCCOMMENT,
  SETEDITORSTATE,
  INITQDOC,
  VALIDATESEQ,
  VALIDATESEQLOADING,
  SETNEWFILE,
  DELETELINKEDFILE_ATTEMPT,
  DELETELINKEDFILE_SUCCESS,
  GENERATEPDF_LOADING,
  GENERATEPDF_FINISHED,
  SETQDOCTITLE,
  FETCHREVCOMMENT,
  FETCHREVCOMMENT_ATTEMPT,
  SETREFETCHTABLE,
  SETREFETCHTABLETYPE,
} from '../constants';
import StateManager from '../../../../Global/StateManager';
import { getDecryptedFile } from './file';
import { isFunction } from 'lodash';

export const setRefetchTable = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETREFETCHTABLE, data));
  };
  return thunk;
};

export const setRefetchTableType = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETREFETCHTABLETYPE, data));
  };
  return thunk;
};

export const setDocDrawer = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETDOCDRAWER, data));
  };
  return thunk;
};
export const openUploadDialog = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SET_UPLOAD_DIALOG, data));
  };
  return thunk;
};

export const openSearchDialog = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETSEARCHDIALOG, data));
  };
  return thunk;
};

export const openSetupDialog = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETSETUPDIALOG, data));
  };
  return thunk;
};

export const openControlDialog = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETCONTROLDIALOG, data));
  };
  return thunk;
};

export const openMoveDialog = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETMOVEDIALOG, data));
  };
  return thunk;
};

export const removeFile = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(REMOVEFILE, data));
  };
  return thunk;
};

export const removeFiles = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(REMOVEFILES, data));
  };
  return thunk;
};

export const setCurrentFolder = (data, path = false) => {
  const thunk = async function thunk(dispatch) {
    if (path) {
      dispatch(successAction(SETPATHCURRENTFOLDER, data));
    } else {
      dispatch(successAction(SETCURRENTFOLDER, data));
    }
  };
  return thunk;
};

export const setCurrentArea = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETCURRENTAREA, data));
  };
  return thunk;
};

export const setCurrentPath = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETCURRENTPATH, data));
  };
  return thunk;
};

export const goBackPath = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(GOBACKPATH, {}));
  };
  return thunk;
};

export const setIsFolder = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETISFOLDER, data));
  };
  return thunk;
};

export const setControlled = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETCONTROLLEDINDEX, data));
  };
  return thunk;
};

export const setSelectedDoc = (data, full = false) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETSELECTEDDOC, { document: data, full }));
  };
  return thunk;
};

export const setInitialUploadConfig = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETINITIALUPLOADCONFIG, data));
  };
  return thunk;
};

export const uploadFiles = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPLOADFILES_ATTEMPT));
      const body = await axios.post(`/uploader/upload-multiple`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      dispatch(successAction(UPLOADFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPLOADFILES_FAIL, err));
    }
  };
  return thunk;
};

export const uploadFile = (file) => {
  const data = new FormData();
  data.append('file', file);
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPLOADFILE_ATTEMPT));
      axios.CancelToken.source();
      const body = await axios.post(`/uploader/upload-single`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      dispatch(successAction(UPLOADFILE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPLOADFILE_FAIL, err));
    }
  };
  return thunk;
};

function hex24() {
  return [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

export const uploadImage = async (file, onProgress, encrypted) => {
  const data = new FormData();
  const blob = file.slice(0, file.size, file.type);
  const fileName = removeWhitespaces(file.name.trim());
  const newFile = new File([blob], fileName, { type: file.type }); // important !!!!!!!
  data.append('file', newFile);
  data.append('id', hex24());
  data.append('name', fileName);
  try {
    axios.CancelToken.source();
    let config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        if (onProgress) {
          const totalLength = progressEvent?.event?.lengthComputable
            ? progressEvent.total
            : progressEvent?.event?.target.getResponseHeader('content-length') ||
              progressEvent?.event?.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);
            if (onProgress) onProgress(progress);
          }
        }
      },
    };
    if (encrypted) {
      config.customBase = 'file-processing';
    }
    const body = await axios.post(`/uploader/${encrypted ? 'encryptedUpload' : 'upload'}`, data, config);
    return body.data;
  } catch (err) {
    return err;
  }
};

export const uploadPdf = async (buffer, name, encrypted) => {
  var uint8Array = new Uint8Array(buffer);
  var blob = new Blob([uint8Array], { type: 'application/pdf' });
  const data = new FormData();
  data.append('file', blob, name);
  data.append('id', hex24());
  data.append('name', name);
  try {
    axios.CancelToken.source();
    let config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    if (encrypted) {
      config.customBase = 'file-processing';
    }
    const body = await axios.post(`/uploader/${encrypted ? 'encryptedUpload' : 'upload'}`, data, config);
    return body.data;
  } catch (err) {
    return err;
  }
};

const getFullUrl = (link) => {
  return `${FILE_PROCESSING_URL}${link}`;
};

export const generatePdf = (id, download = false, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GENERATEPDF_LOADING));
      const body = await axios.post(`pdf/dochub/documents/${id}`, null, { customBase: 'file-processing' });
      let data = body.data;
      dispatch(successAction(GENERATEPDF_FINISHED, data));
      const link = getFullUrl(data.link);
      const fileName = data.fileName;
      const title = data.title || fileName;
      if (!download) {
        if (onClose) {
          onClose({
            name: fileName,
            id: id,
            location: link,
          });
        }
      } else {
        downloadFile(link, title);
        if (onClose) {
          onClose(null);
        }
        StateManager.setSuccessAlert(download ? 'PDF ready to download' : 'PDF Generated Successfully');
      }
    } catch (err) {
      dispatch(errorAction(GENERATEPDF_FINISHED, err));
      if (onClose) {
        onClose(null);
      }
      StateManager.setErrorAlert('We were unable to generate pdf for this document right now');
    }
  };
  return thunk;
};

export const clearUpload = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(UPLOADFILE_SUCCESS, { file: null }));
  };
  return thunk;
};

export const highlightDocument = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(HIGHLIGHTDOC, data));
  };
  return thunk;
};

export const getAllUsers = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETDOCUSERS_ATTEMPT));
      const body = await axios.post(`user/getAllCompanyUsers`);
      dispatch(successAction(GETDOCUSERS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETDOCUSERS_FAIL, err));
    }
  };
  return thunk;
};

export const getDocument = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETDOCUMENT_ATTEMPT));
      const body = await axios.post(`dochub/documents/getDocument`, data);
      dispatch(successAction(GETDOCUMENT_SUCCESS, body.data));
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
      dispatch(errorAction(GETDOCUMENT_FAIL, err));
    }
  };
  return thunk;
};

export const validateSequence = (sequence, prefix = null, set = null, setParams = null, documentId) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(VALIDATESEQLOADING));
      const body = await axios.post(`dochub/documents/validateSequence`, {
        sequence,
        prefix,
        set,
        setParams,
        documentId,
      });
      dispatch(successAction(VALIDATESEQ, { ...body.data }));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const getQDocument = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETQDOC_ATTEMPT));
      const body = await axios.post(`dochub/documents/getDocument`, data);
      dispatch(successAction(GETQDOC_SUCCESS, body.data));
      if (body.data?.document?.area) {
        dispatch(successAction(SETCURRENTAREA, body.data.document.area));
      }
      dispatch(setSelectedDoc(body.data.document));
    } catch (err) {
      dispatch(errorAction(GETQDOC_FAIL, err));
    }
  };
  return thunk;
};

export const getQIssue = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETQISSUE_ATTEMPT));
      const body = await axios.post(`dochub/documents/getDocument`, data);
      dispatch(successAction(GETQISSUE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETQISSUE_FAIL, err));
    }
  };
  return thunk;
};

export const setFile = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETNEWFILE, data));
  };
  return thunk;
};

export const setQDoc = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETQDOC, data));
  };
  return thunk;
};

export const initQDoc = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(INITQDOC, null));
  };
  return thunk;
};

export const setEditorState = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETEDITORSTATE, data));
  };
  return thunk;
};

export const setQStatus = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETQSTATUS, data));
  };
  return thunk;
};

export const getAllDocuments = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETALLDOCS_ATTEMPT));
      const body = await axios.post(`dochub/documents/getAllDocuments`, data);
      dispatch(successAction(GETALLDOCS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETALLDOCS_FAIL, err));
    }
  };
  return thunk;
};

export const getDocuments = (data, params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMYFILES_ATTEMPT));
      const body = await axios.post(`dochub/documents/getDocuments`, data, { params });
      dispatch(successAction(GETMYFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMYFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getCompanyDocuments = (data, params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMYFILES_ATTEMPT));
      const body = await axios.post(`dochub/documents/getCompanyDocuments`, data, { params });
      dispatch(successAction(GETMYFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMYFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getControlledDocuments = (data, params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMYFILES_ATTEMPT));
      const body = await axios.post(`dochub/documents/getControlledDocuments`, data, { params });
      dispatch(successAction(GETMYFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMYFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getBookmarkedDocuments = (bodyData) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETBOOKMARKEDFILES_ATTEMPT));
      const body = await axios.post(`dochub/documents/getBookmarkedDocuments`, bodyData);
      dispatch(successAction(GETBOOKMARKEDFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETBOOKMARKEDFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getArchivedDocuments = (bodyData, params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMYFILES_ATTEMPT));
      const body = await axios.post(`dochub/documents/getArchivedDocuments`, bodyData ?? null, { params });
      dispatch(successAction(GETMYFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMYFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getUploadedDocuments = (data, params) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMYFILES_ATTEMPT));
      const body = await axios.post(`dochub/documents/getUploadedDocuments`, data, { params });
      dispatch(successAction(GETMYFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMYFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getRevisions = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETREVISIONFILES_ATTEMPT));
      const body = await axios.get(`dochub/documents/${id}/history`);
      dispatch(successAction(GETREVISIONFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETREVISIONFILES_FAIL, err));
    }
  };
  return thunk;
};

export const finishIssue = (data, history) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FINISHPENDINGUPISSUE_ATTEMPT));
      const body = await axios.post(`dochub/revisions/finishIssue`, data);
      if (body.data && body.data.Documents) {
        history.push(`/DocLibrary/preview/${body.data.Documents._id}`);
      }
      dispatch(successAction(FINISHPENDINGUPISSUE_SUCCESS, body.data));
      if (body.data.approvals && body.data.approvals.length > 0) {
        dispatch(successAction(UPDATEAPPROVAL_SUCCESS, { approvals: body.data.approvals }));
      }
    } catch (err) {
      dispatch(errorAction(FINISHPENDINGUPISSUE_FAIL, err));
    }
  };
  return thunk;
};

export const cancelIssue = (id, history) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANCELUPISSUE_ATTEMPT));
      const body = await axios.post(`dochub/revisions/cancelIssue`, { id });
      if (body.status === 200 && body.data?.link) {
        history.push(`/DocLibrary/${body.data?.link}`);
      }
      dispatch(successAction(CANCELUPISSUE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANCELUPISSUE_FAIL, err));
    }
  };
  return thunk;
};

export const setIssue = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETISSUE, data));
  };
  return thunk;
};

export const fetchIssue = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHPENDINGUPISSUE_ATTEMPT));
      const body = await axios.post(`dochub/documents/getDocument`, data);
      dispatch(successAction(FETCHPENDINGUPISSUE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHPENDINGUPISSUE_FAIL, err));
    }
  };
  return thunk;
};

export const addDocuments = (data, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDDOCUMENTS_ATTEMPT));
      const body = await axios.post(`dochub/documents/addDocuments`, data);
      dispatch(successAction(ADDDOCUMENTS_SUCCESS, body.data));
      if (body.status === 200) {
        StateManager.setSuccessAlert('Documents Uploaded Successfully');
      }
      if (onClose) {
        onClose();
      }
    } catch (err) {
      dispatch(errorAction(ADDDOCUMENTS_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
      if (onClose) {
        onClose();
      }
    }
  };
  return thunk;
};

export const updateDocumentAndDownload = (id, data, onClose, download = true) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEDOCUMENT_ATTEMPT));
      const body = await axios.post(`dochub/documents/updateConventionSetParams`, { id, data });
      dispatch(successAction(UPDATEDOCUMENT_SUCCESS, { Document: body.data.document }));
      if (body.data?.title && body.data?.document && download) {
        // check if the document is encrypted
        const url = await getDecryptedFile(body.data?.document);
        downloadFile(url, body.data?.title);
      }
      if (body.status === 200) {
        StateManager.setSuccessAlert('Document Updated Successfully');
      }
      onClose(false);
    } catch (err) {
      dispatch(errorAction(UPDATEDOCUMENT_FAIL, err));
    }
  };
  return thunk;
};

export const updateDocument = (id, data, category = 'normal', history = null, onConfirm = null) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/documents/updateDocument`, { document_id: id, update: data });
    if (category === 'normal') {
      try {
        dispatch(attemptAction(UPDATEDOCUMENT_ATTEMPT));
        dispatch(successAction(UPDATEDOCUMENT_SUCCESS, body.data));
        if (isFunction(onConfirm)) onConfirm();
      } catch (err) {
        dispatch(errorAction(UPDATEDOCUMENT_FAIL, err));
      }
    } else {
      try {
        dispatch(attemptAction(UPDATEQDOC_ATTEMPT));
        dispatch(successAction(UPDATEQDOC_SUCCESS, body.data));
        if (body.status === 200 && body.data.Document) {
          localStorage.setItem('current', id);
          dispatch(successAction(SETCURRENTAREA, body.data.Document.area));
          if (data.documentState === 'Pre-approval' && data.controlled && data.status) {
            dispatch(successAction(SETSELECTEDDOC, data));
          }
        }
      } catch (err) {
        dispatch(errorAction(UPDATEQDOC_FAIL, err));
      }
    }
    if (body.data?.link && history) {
      history.push(body.data?.link);
    }
  };
  return thunk;
};

export const deleteDocument = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETEDOCUMENT_ATTEMPT));
      const body = await axios.delete(`dochub/documents/deleteDocument/${id}`);
      dispatch(successAction(DELETEDOCUMENT_SUCCESS, body.data));
      if (body.status === 200) {
        StateManager.setSuccessAlert('Document Deleted Successfully');
      }
    } catch (err) {
      dispatch(errorAction(DELETEDOCUMENT_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const getMyArea = (path = false) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/areas/getMyArea`);
    if (path) {
      try {
        dispatch(attemptAction(GETPATHAREA_ATTEMPT));
        dispatch(successAction(GETPATHAREA_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETPATHAREA_FAIL, err));
      }
    } else {
      try {
        dispatch(attemptAction(GETMYAREA_ATTEMPT));
        dispatch(successAction(GETMYAREA_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETMYAREA_FAIL, err));
      }
    }
  };
  return thunk;
};

export const getCompanyAreas = () => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/areas/getAreas`);
    try {
      dispatch(attemptAction(GETAREAS_ATTEMPT));
      dispatch(successAction(GETAREAS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETAREAS_FAIL, err));
    }
  };
  return thunk;
};

export const updateAreaOrder = (data) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/areas/updateOrders`, { orders: data });
    try {
      dispatch(attemptAction(GETAREAS_ATTEMPT));
      dispatch(
        successAction(GETAREAS_SUCCESS, {
          areas: body.data,
        }),
      );
    } catch (err) {
      dispatch(errorAction(GETAREAS_FAIL, err));
    }
  };
  return thunk;
};

export const getUploaderAreas = () => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.get(`dochub/areas/getUploaderAreas`);
    try {
      dispatch(attemptAction(GETPATHAREAS_ATTEMPT));
      dispatch(successAction(GETPATHAREAS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETPATHAREAS_FAIL, err?.response?.data?.message || 'Something went wrong'));
    }
  };
  return thunk;
};

export const getArea = (id, path = false) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/areas/getArea`, { area_id: id });
    if (path) {
      try {
        dispatch(attemptAction(GETPATHAREA_ATTEMPT));
        dispatch(successAction(GETPATHAREA_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETPATHAREA_FAIL, err));
      }
    } else {
      try {
        dispatch(attemptAction(GETAREA_ATTEMPT));
        dispatch(successAction(GETAREA_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETAREA_FAIL, err));
      }
    }
  };
  return thunk;
};

export const addArea = (data, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDAREA_ATTEMPT));
      const body = await axios.post(`dochub/areas/addArea`, data);
      dispatch(successAction(ADDAREA_SUCCESS, body.data));
      StateManager.setSuccessAlert('Area Added Successfully');
      if (onClose) {
        onClose();
      }
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
      dispatch(errorAction(ADDAREA_FAIL, err));
      if (onClose) {
        onClose();
      }
    }
  };
  return thunk;
};

export const updateArea = (id, data, onClose) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEAREA_ATTEMPT));
      const body = await axios.post(`dochub/areas/updateArea`, { area_id: id, update: data });
      dispatch(successAction(UPDATEAREA_SUCCESS, body.data));
      StateManager.setSuccessAlert('Area Updated Successfully');
      if (onClose) {
        onClose();
      }
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
      dispatch(errorAction(UPDATEAREA_FAIL, err));
      if (onClose) {
        onClose();
      }
    }
  };
  return thunk;
};

export const deleteArea = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETEAREA_ATTEMPT));
      const body = await axios.post(`dochub/areas/deleteArea`, { area_id: id });
      dispatch(successAction(DELETEAREA_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DELETEAREA_FAIL, err));
    }
  };
  return thunk;
};

export const getAreaSettings = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETAREASETTINGS_ATTEMPT));
      const body = await axios.get(`dochub/areas/getAreaSettings/${id}`);
      dispatch(successAction(GETAREASETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETAREASETTINGS_FAIL, err));
    }
  };
  return thunk;
};

export const addAreaSettings = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDAREASETTINGS_ATTEMPT));
      const body = await axios.post(`dochub/areas/addAreaSettings`, data);
      dispatch(successAction(ADDAREASETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDAREASETTINGS_FAIL, err));
    }
  };
  return thunk;
};

export const updateAreaSettings = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEAREASETTINGS_ATTEMPT));
      const body = await axios.post(`dochub/areas/updateAreaSettings/${id}`, data);
      dispatch(successAction(UPDATEAREASETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEAREASETTINGS_FAIL, err));
    }
  };
  return thunk;
};

export const deleteAreaSettings = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETEAREASETTINGS_ATTEMPT));
      const body = await axios.delete(`dochub/areas/deleteAreaSettings/${id}`);
      dispatch(successAction(DELETEAREASETTINGS_SUCCESS, body.data));
      if (body.data.message) {
        dispatch(successAction(SETAPPROVALALERT, { message: body.data.message, alert: true }));
      }
    } catch (err) {
      dispatch(errorAction(DELETEAREASETTINGS_FAIL, err));
    }
  };
  return thunk;
};

export const requestAccess = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REQUESTAREA_ATTEMPT));
      const body = await axios.post(`dochub/areas/requestArea`, { area: id });
      dispatch(successAction(REQUESTAREA_SUCCESS, body.data));
      if (body.data.message) {
        StateManager.setSuccessAlert(body.data.message);
      }
    } catch (err) {
      dispatch(errorAction(REQUESTAREA_FAIL, err));
    }
  };
  return thunk;
};

export const hideRequest = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETREQUESTALERT, {}));
  };
  return thunk;
};

export const getBookmarkedFolders = () => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.get(`dochub/folders/bookmarked`);
    try {
      dispatch(attemptAction(GETBOOKMARKEDFOLDERS_ATTEMPT));
      dispatch(successAction(GETBOOKMARKEDFOLDERS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETBOOKMARKEDFOLDERS_FAIL, err));
    }
  };
  return thunk;
};

export const getFolders = (data, path = false) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/folders/all`, data);
    if (path) {
      try {
        dispatch(attemptAction(GETPATHFOLDERS_ATTEMPT));
        dispatch(successAction(GETPATHFOLDERS_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETPATHFOLDERS_FAIL, err));
      }
    } else {
      try {
        dispatch(attemptAction(GETFOLDERS_ATTEMPT));
        dispatch(successAction(GETFOLDERS_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETFOLDERS_FAIL, err));
      }
    }
  };
  return thunk;
};

export const getPortalFolders = (area, parent) => {
  const thunk = async function thunk(dispatch) {
    let query = { area, personal: false, root: true };
    if (parent) {
      query = { ...query, parent, root: false, child: true };
    }
    const body = await axios.post(`dochub/folders/all`, query);
    try {
      dispatch(attemptAction(GETFOLDERS_ATTEMPT));
      dispatch(successAction(GETFOLDERS_SUCCESS, body.data));
      const portalAreas = body.data?.portalAreas;
      const portalFolders = body.data?.portalFolders;
      const currentFolder = body.data?.currentFolder;
      if (area && !parent && !portalAreas?.includes(area)) {
        StateManager.setErrorAlert('Permission denied');
      }
      if (
        (area &&
          parent &&
          !portalAreas?.includes(area) &&
          !portalFolders?.includes(parent) &&
          !portalFolders?.includes(currentFolder?.parent?._id)) ||
        (area &&
          parent &&
          portalAreas.includes(area) &&
          !currentFolder?.openAccess &&
          !portalFolders.includes(parent) &&
          !portalFolders.includes(currentFolder?.parent?._id))
      ) {
        StateManager.setErrorAlert('Permission denied');
      }
    } catch (err) {
      dispatch(errorAction(GETFOLDERS_FAIL, err));
    }
  };
  return thunk;
};

export const getFolder = (id, path = false) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.get(`dochub/folders/all/${id}`);
    if (path) {
      try {
        dispatch(attemptAction(GETPATHFOLDER_ATTEMPT));
        dispatch(successAction(GETPATHFOLDER_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETPATHFOLDER_FAIL, err));
      }
    } else {
      try {
        dispatch(attemptAction(GETFOLDER_ATTEMPT));
        dispatch(successAction(GETFOLDER_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(GETFOLDER_FAIL, err));
      }
    }
  };
  return thunk;
};

export const addFolder = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDFOLDER_ATTEMPT));
      const body = await axios.post(`dochub/folders/single`, data);
      dispatch(successAction(ADDFOLDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDFOLDER_FAIL, err));
    }
  };
  return thunk;
};

export const updateFolder = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEFOLDER_ATTEMPT));
      const body = await axios.post(`dochub/folders/single/${id}`, data);
      dispatch(successAction(UPDATEFOLDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEFOLDER_FAIL, err));
    }
  };
  return thunk;
};

export const deleteFolder = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETEFOLDER_ATTEMPT));
      const body = await axios.delete(`dochub/folders/single/${id}`);
      dispatch(successAction(DELETEFOLDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DELETEFOLDER_FAIL, err));
    }
  };
  return thunk;
};

export const getTags = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETTAGS_ATTEMPT));
      const body = await axios.get(`dochub/settings/tags`);
      dispatch(successAction(GETTAGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETTAGS_FAIL, err));
    }
  };
  return thunk;
};

export const addTag = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDTAG_ATTEMPT));
      const body = await axios.post(`dochub/settings/tags`, data);
      dispatch(successAction(ADDTAG_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDTAG_FAIL, err));
    }
  };
  return thunk;
};

export const updateTag = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATETAG_ATTEMPT));
      const body = await axios.post(`dochub/settings/tags/${id}`, data);
      dispatch(successAction(UPDATETAG_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATETAG_FAIL, err));
    }
  };
  return thunk;
};

export const deleteTag = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REMOVETAG_ATTEMPT));
      const body = await axios.delete(`dochub/settings/tags/${id}`);
      dispatch(successAction(REMOVETAG_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(REMOVETAG_FAIL, err));
    }
  };
  return thunk;
};

export const getPrefixes = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETPREFIXES_ATTEMPT));
      const body = await axios.get(`dochub/settings/prefixes`);
      dispatch(successAction(GETPREFIXES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETPREFIXES_FAIL, err));
    }
  };
  return thunk;
};

export const addPrefix = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDPREFIX_ATTEMPT));
      const body = await axios.post(`dochub/settings/prefixes`, data);
      dispatch(successAction(ADDPREFIX_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDPREFIX_FAIL, err));
    }
  };
  return thunk;
};

export const updatePrefix = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEPREFIX_ATTEMPT));
      const body = await axios.post(`dochub/settings/prefixes/${id}`, data);
      dispatch(successAction(UPDATEPREFIX_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEPREFIX_FAIL, err));
    }
  };
  return thunk;
};

export const deletePrefix = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REMOVEPREFIX_ATTEMPT));
      const body = await axios.delete(`dochub/settings/prefixes/${id}`);
      dispatch(successAction(REMOVEPREFIX_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(REMOVEPREFIX_FAIL, err));
    }
  };
  return thunk;
};

export const getSetting = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETSETTINGS_ATTEMPT));
      const body = await axios.get(`dochub/settings`);
      dispatch(successAction(GETSETTINGS_SUCCESS, { ...body.data.setting, ruPageAccess: body.data.ruPageAccess }));
    } catch (err) {
      dispatch(errorAction(GETSETTINGS_FAIL, err));
    }
  };
  return thunk;
};

export const updateSetting = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATESETTINGS_ATTEMPT));
      const body = await axios.post(`dochub/settings`, data);
      dispatch(successAction(UPDATESETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATESETTINGS_FAIL, err));
    }
  };
  return thunk;
};

// update Settings
export const setUpdate = (value) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETUPDATE, value));
  };
  return thunk;
};

export const addLink = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDLINK_ATTEMPT));
      const body = await axios.post(`dochub/linked/addLinkedFile`, { data });
      dispatch(successAction(ADDLINK_SUCCESS, body.data));
      if (data?.deleted?.length > 0) {
        data?.deleted?.forEach((id) => {
          dispatch(successAction(DELETELINKEDFILE_SUCCESS, { id, status: 200 }));
        });
      }
    } catch (err) {
      dispatch(errorAction(ADDLINK_FAIL, err));
    }
  };
  return thunk;
};

export const getLinkedFiles = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETLINKEDFILES_ATTEMPT));
      const body = await axios.post(`dochub/linked/getLinkedFiles`, data);
      dispatch(successAction(GETLINKEDFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETLINKEDFILES_FAIL, err));
    }
  };
  return thunk;
};

export const deleteLinkedFile = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETELINKEDFILE_ATTEMPT));
      const body = await axios.delete(`dochub/linked/${id}`);
      dispatch(successAction(DELETELINKEDFILE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const updateLinkedFile = (id, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATELINKEDFILES_ATTEMPT));
      const body = await axios.post(`dochub/linked/updateLinkedFiles`, { id, update });
      dispatch(successAction(UPDATELINKEDFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATELINKEDFILES_FAIL, err));
    }
  };
  return thunk;
};

export const search = (term, limit = 4, params) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SEARCH_ATTEMPT));
      const body = await axios.get(`dochub/search?query=${term}&limit=${limit}`, { params });
      dispatch(successAction(SEARCH_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SEARCH_FAIL, err));
    }
  };
  return thunk;
};

export const searchDL = (term, limit = 4, params) => {
  const thunk = async function thunk(dispatch) {
    try {
      if (term === '') {
        dispatch(
          successAction(SEARCH_SUCCESS, {
            documents: [],
            areas: [],
            folders: [],
            taggedDocuments: [],
          }),
        );
        return;
      }
      dispatch(attemptAction(SEARCH_ATTEMPT));
      const body = await axios.get(`dochub/search/all?query=${term}&limit=${limit}`, { params });
      dispatch(successAction(SEARCH_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SEARCH_FAIL, err));
    }
  };
  return thunk;
};

export const searchQDocs = (term, limit = 4) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(QSEARCH_ATTEMPT));
      const body = await axios.get(`dochub/search/qdocs?query=${term}&limit=${limit}`);
      dispatch(successAction(QSEARCH_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(QSEARCH_FAIL, err));
    }
  };
  return thunk;
};

export const clearSearch = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(CLEARQRESULTS));
  };
  return thunk;
};

export const createQDoc = (data, history) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/documents/addQDoc`, data);
    if (data.documentStatus === 'draft') {
      try {
        dispatch(attemptAction(ADDDRAFTQDOC_ATTEMPT));
        dispatch(successAction(ADDDRAFTQDOC_SUCCESS, body.data));
        if (body.status === 200 && body.data && body.data.Documents && history) {
          history.push(`/editor/${body.data.Documents?.id}`);
        }
      } catch (err) {
        dispatch(errorAction(ADDDRAFTQDOC_FAIL, err));
      }
    } else {
      try {
        dispatch(attemptAction(ADDQDOC_ATTEMPT));
        dispatch(successAction(ADDQDOC_SUCCESS, body.data));
      } catch (err) {
        dispatch(errorAction(ADDQDOC_FAIL, err));
      }
    }
  };
  return thunk;
};

export const setAlert = (state) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETDOCALERT, state));
  };
  return thunk;
};

export const setDocAlert = (message, type) => {
  const thunk = async function thunk(dispatch) {
    dispatch(
      successAction(SHOWALERT, {
        message,
        type,
        state: true,
      }),
    );
  };
  return thunk;
};
export const fetchBookmarks = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHBOOKMARKS_ATTEMPT));
      const body = await axios.get(`dochub/bookmarks`);
      dispatch(successAction(FETCHBOOKMARKS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHBOOKMARKS_FAIL, err));
    }
  };
  return thunk;
};

export const bookmarkDoc = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(BOOKMARKDOC_ATTEMPT));
      const body = await axios.post(`dochub/bookmarks/documents`, { id });
      dispatch(successAction(BOOKMARKDOC_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(BOOKMARKDOC_FAIL, err));
    }
  };
  return thunk;
};

export const removeBookmarkDoc = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UNBOOKMARKDOC_ATTEMPT));
      const body = await axios.delete(`dochub/bookmarks/documents/${id}`);
      dispatch(successAction(UNBOOKMARKDOC_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UNBOOKMARKDOC_FAIL, err));
    }
  };
  return thunk;
};

export const bookmarkFolder = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(BOOKMARKFOLDER_ATTEMPT));
      const body = await axios.post(`dochub/bookmarks/folders`, { id });
      dispatch(successAction(BOOKMARKFOLDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(BOOKMARKFOLDER_FAIL, err));
    }
  };
  return thunk;
};

export const removeBookmarkFolder = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UNBOOKMARKFOLDER_ATTEMPT));
      const body = await axios.delete(`dochub/bookmarks/folders/${id}`);
      dispatch(successAction(UNBOOKMARKFOLDER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UNBOOKMARKFOLDER_FAIL, err));
    }
  };
  return thunk;
};

export const docViewed = (document) => {
  const thunk = async function thunk(dispatch) {
    try {
      const body = await axios.post('dochub/documents/viewed', { document });
      dispatch(successAction(DOCVIEWED_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const getFolderSettings = (id, fid) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.get(`dochub/folders/getFolderSettings/${id}/${fid}`);
      dispatch(successAction(GETFOLDERSETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const addFolderSettings = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/folders/addFolderSettings`, data);
      dispatch(successAction(ADDFOLDERSETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const updateFolderSettings = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/folders/updateFolderSettings/${id}`, data);
      dispatch(successAction(UPDATEFOLDERSETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const deleteFolderSettings = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.delete(`dochub/folders/deleteFolderSettings/${id}`);
      dispatch(successAction(DELETEFOLDERSETTINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const requestFolderAccess = (area, folder) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/folders/requestFolder`, { folder, area });
      dispatch(successAction(REQUESTFOLDER_SUCCESS, body.data));
      if (body.data.message) {
        dispatch(
          successAction(SHOWALERT, {
            message: body.data.message,
            type: 'success',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const shareDocLinkQUser = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const { to, text, document } = data;
      let postData = {
        to,
        text,
      };
      if (document.category === 'qhub') {
        postData.attachments = [{ hub: 'doc', link: document?.location, title: document?.title }];
      } else {
        const fileUrl = await getDecryptedFile(document);
        postData.attachments = [{ ...document?.file, location: fileUrl }];
      }
      const body = await axios.post(`/chat/sendMessageToMultipleUsers`, postData);
      if (body.data) {
        // StateManager.setSuccessAlert('Your document was shared successfully');
        dispatch(
          successAction(SHOWALERT, { message: 'Your document was shared successfully', type: 'success', state: true }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const shareDocPublic = (data, to) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      let link = data?.file?.location;
      let postBody = {
        to,
        title: data.title,
        link,
        id: data._id,
        category: data.category,
      };
      const body = await axios.post(`dochub/documents/share`, postBody);
      if (body.data.status === 200) {
        StateManager.setSuccessAlert('Your document was shared successfully');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const setGlobalAlert = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETGLOBALDOCALERT, data));
  };
  return thunk;
};

export const bulkDocumentArchive = (ids, area) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/documents/bulkArchive`, { ids, area });
      if (body.data.status === 200) {
        dispatch(
          successAction(SHOWALERT, {
            message: body.data.message,
            type: 'success',
            state: true,
          }),
        );
        dispatch(successAction(BULKDOCDELETE, body.data));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: body.data.message,
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDocumentDelete = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/documents/bulkDelete`, { ids });
      if (body.data.status === 200) {
        dispatch(successAction(BULKDOCDELETE, body.data));
        StateManager.setSuccessAlert('Documents Deleted Successfully');
      } else {
        StateManager.setErrorAlert('We were unable to delete these documents');
        dispatch(errorAction(DOCLIBRARY_ERROR, null));
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const bulkDocumentMove = (ids, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/documents/bulkMove`, { ids, update });
      if (body.data.status === 200) {
        dispatch(successAction(BULKDOCDELETE, body.data));
        StateManager.setSuccessAlert('Documents Moved Successfully');
      } else {
        StateManager.setErrorAlert('We were unable to move these documents');
        dispatch(errorAction(DOCLIBRARY_ERROR, null));
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const bulkFolderDelete = (ids) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/folders/bulkDelete`, { ids });
      if (body.data.status === 200) {
        dispatch(successAction(BULKFOLDERDELETE, body.data));
        dispatch(successAction(SHOWALERT, { message: 'Folders Deleted Successfully', type: 'success', state: true }));
      } else {
        dispatch(
          successAction(SHOWALERT, { message: 'We were unable to delete these folders', type: 'error', state: true }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const bulkFolderMove = (ids, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/folders/bulkMove`, { ids, update });
      if (body.data.status === 200) {
        dispatch(successAction(BULKFOLDERDELETE, body.data));
        dispatch(successAction(SHOWALERT, { message: 'Folders Moved Successfully', type: 'success', state: true }));
      } else {
        dispatch(
          successAction(SHOWALERT, { message: 'We were unable to move these folders', type: 'error', state: true }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const fetchComments = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHDOCCOMMENTS_ATTEMPT));
      const body = await axios.get(`dochub/documents/${id}/comments`);
      if (body.data.status === 200) {
        dispatch(successAction(FETCHDOCCOMMENTS, body.data));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: 'We were unable to fetch comment for this document',
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(FETCHDOCCOMMENTS_ERROR, err));
    }
  };
  return thunk;
};

export const fetchDocumentComment = (id, cid) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHREVCOMMENT_ATTEMPT));
      const body = await axios.get(`dochub/documents/${id}/comments/${cid}`);
      if (body.data.status === 200) {
        dispatch(successAction(FETCHREVCOMMENT, body.data));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: 'We were unable to fetch comment for this document',
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const addComment = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/documents/${id}/comments`, data);
      if (body.data.status === 200) {
        dispatch(successAction(ADDDOCCOMMENT, body.data));
        dispatch(successAction(SHOWALERT, { message: 'Comment Added Successfully', type: 'success', state: true }));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: 'We were unable to add comment for this document',
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const updateComment = (id, cid, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/documents/${id}/comments/${cid}`, data);
      if (body.data.status === 200) {
        dispatch(successAction(UPDATEDOCCOMMENT, body.data));
        dispatch(successAction(SHOWALERT, { message: 'Comment Updated Successfully', type: 'success', state: true }));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: 'We were unable to add comment for this document',
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const likeComment = (id, cid, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/documents/${id}/comments/${cid}/like`, data);
      if (body.data.status === 200) {
        dispatch(successAction(UPDATEDOCCOMMENT, body.data));
        dispatch(successAction(SHOWALERT, { message: 'Comment Liked Successfully', type: 'success', state: true }));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: 'We were unable to add comment for this document',
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const deleteComment = (id, cid) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.delete(`dochub/documents/${id}/comments/${cid}`);
      if (body.data.status === 200) {
        dispatch(successAction(DELETEDOCCOMMENT, body.data));
        dispatch(successAction(SHOWALERT, { message: 'Comment Deleted Successfully', type: 'success', state: true }));
      } else {
        dispatch(
          successAction(SHOWALERT, {
            message: 'We were unable to delete comment for this document',
            type: 'error',
            state: true,
          }),
        );
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const setQdocTitle = (id, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEQDOC_ATTEMPT));
      const body = await axios.post(`dochub/documents/updateDocument`, { document_id: id, update });
      if (body.status === 200 && body.data.Document) {
        dispatch(successAction(SETQDOCTITLE, update.title));
      }
    } catch (err) {
      dispatch(errorAction(UPDATEQDOC_FAIL, err));
    }
  };

  return thunk;
};

export const resetQdocTitle = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETQDOCTITLE, ''));
  };
  return thunk;
};
