import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import LabelIcon from '@mui/icons-material/Label';
import TagDialog from './dialog';

const Tags = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ color: 'primary.main' }} size="small">
        <LabelIcon />
      </IconButton>
      <TagDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Tags;
